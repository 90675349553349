.startup-item-wrapper {
  position: relative;
  width: 21.875rem;
  height: 20.313rem;
  background-color: #fff;
  border-radius: 0.875rem;
  padding: 1.625rem 1.875rem;

  &:hover {
    filter: drop-shadow(0 10px 40px rgba(70, 96, 138, 0.2));
  }

  a {
    cursor: url(../image/hand_cursor.png) 25 25, auto;
  }

  .startup-item-body {
    .name {
      color: #282828;
      font-size: 1.5rem;
      font-weight: bold;
    }

    .mission {
      color: #282828;
      font-size: 1rem;
    }
  }

  .startup-item-description {
    color: #959aa1;
    font-size: 1rem;
  }

  .toggle-more {
    position: absolute;
    right: 0;
  }

  .startup-sample-tag {
    color: #fff;
    font-size: 0.75rem;
    font-weight: 600;
    line-height: 0.75rem;
    padding: 0.25rem 0.5rem;
    border-top-right-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem;
    background-color: $color-orange;
    display: inline-block;
  }
}
