.dashboard-personal-crm-task {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .task-name {
    font-size: 0.875rem;
    color: rgba(40, 40, 40, 0.6);
  }

  .checkbox-btn {
    margin-top: -0.3rem;
  }
}
