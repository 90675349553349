.dashboard-personal-crm-widget {
  background-color: $color-light-gray;
  border-radius: 0.75rem !important;
  border: none !important;
  min-height: 480px;
  height: 100%;
  position: relative;

  .card-description {
    color: #6d6d6f;
    font-size: 0.875rem;
    margin-bottom: 1rem;
    padding: 0 1.875rem;
  }

  .card-title {
    a {
      font-family: 'Kazimir Text', sans-serif;
      font-weight: 900;
      font-size: 1.125rem;
      margin: 1.875rem;
      margin-bottom: 0;
      color: $color-black;
      display: inline-block;
    }
  }

  .decor {
    position: absolute;
    right: -5.5rem;
    bottom: 5.5rem;
    width: 10rem;
    z-index: -1;
  }

  .card-body {
    padding: 0 1.875rem;
    display: flex;
    flex-direction: column;
    // justify-content: center;
    position: relative;

    .section-title {
      font-weight: bold;
      font-size: 0.75rem;
      color: #959aa1;
    }

    .view-all {
      color: $color-light-blue !important;
    }

    .image-desc {
      margin-top: 0.5rem;
      font-weight: bold;
      font-size: 0.75rem;
      color: #333;
      text-align: center;
    }

    .crm-new-button {
      padding-top: 1.125rem;
      padding-bottom: 1.125rem;
      font-weight: bold;
      font-size: 1.125rem;
      width: 100%;
      background-color: #f5e5df;
      border: none;
      border-radius: 0.75rem;

      &.reminder-new-button {
        background-color: #f5e5df;
        color: #f36328;
      }

      &.task-new-button {
        background-color: #f5ecdb;
        color: #f09b00;
      }

      &.contact-new-button {
        background-color: #e7e4e6;
        color: #7a5c58;
      }

      img {
        margin-bottom: 0.25rem;
      }
    }
  }
}
