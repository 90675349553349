.crm-new-contact-wrapper {
  .react-datepicker__current-month {
    display: none;
  }

  .react-datepicker__month-read-view--selected-month {
    margin-top: 0;
    color: #000;
    font-weight: bold;
    font-size: 0.944rem;
  }

  .react-datepicker__month-read-view--down-arrow {
    display: none;
  }

  .modal-body {
    padding: 0 2.5rem 2.5rem 2.5rem;
  }

  .modal-header {
    padding-bottom: 0;
    border-bottom: unset;
  }

  .description-text {
    font-style: italic;
    font-size: 0.625rem;
    color: #6d6d6f;
    text-transform: lowercase;
    font-family: 'Proxima Nova', sans-serif;
    letter-spacing: 0.02rem;
  }

  .react-datepicker-wrapper {
    width: 100%;
  }

  .select-box {
    position: relative;
    cursor: pointer;

    .type-dropdown {
      appearance: none;
      -moz-appearance: none;
      -webkit-appearance: none;
      cursor: pointer;
    }

    img {
      position: absolute;
      right: 1rem;
      top: 1.25rem;
    }
  }

  .input-group {
    .input-group-prepend {
      position: absolute;
      z-index: 1;
      top: 0.75rem;
      left: 1rem;
    }

    input {
      padding-left: 2.75rem !important;
    }
  }

  .button-group {
    display: flex;
    flex-direction: row-reverse;

    button {
      border-radius: 1.1875rem;
      border: none;
      font-size: 1rem;
      height: 2.75rem;
      color: $color-gray2 !important;
      font-weight: bold;

      &.submit-button {
        background-color: #f36328 !important;
        color: $color-white !important;
        width: 100%;
      }
    }
  }

  .contact-name {
    align-items: flex-start;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: flex-end;
  }

  .pronoun {
    align-items: flex-start;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: flex-end;
  }

  .profile-picture {
    position: relative;
    width: 6.375rem;
    height: 6.375rem;
  }

  .add-btn {
    position: absolute;
    right: 0;
    top: 0;
    color: $color-black !important;
    background: none !important;
    border: none !important;
    padding: 0;
  }

  .form-group {
    label {
      color: $color-gray3;
      font-size: 0.875rem;
      line-height: 1.063rem;
      font-weight: bold;
      text-transform: uppercase;
      letter-spacing: 0.125rem;
    }

    .form-control {
      height: 2.875rem;
      border: 0.125rem solid #e2e2e2;
      box-sizing: border-box;
      border-radius: 1.5625rem;
      padding: 0 1.75rem;
      font-size: 1rem;
      color: $color-mine-shaft;

      &.text-area {
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
        height: 4rem;
      }

      &.tag {
        color: $color-red;
        font-weight: 600;
      }
    }
  }

  .accordion {
    button.btn {
      background-color: $color-light-gray2;
      border-radius: 0.5rem;
      padding: 0.5rem 1.25rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      margin-bottom: 1rem;
      text-decoration: none !important;

      span {
        color: $color-blue;
        font-weight: bold;
        font-size: 1.125rem;
      }

      &.btn-link:hover {
        text-decoration: none !important;
      }
    }
  }

  .tooltip-wrapper .tooltip-content {
    text-transform: initial;
    font-size: 12px;
    letter-spacing: 0;
  }
}
