.company-profile {
  .startup-detail {
    height: 612px;
    overflow: auto;
  }

  .founder {
    background-color: rgba(240, 155, 0, 0.12);
    border-radius: 0.5rem !important;
    border: none;
  }

  .startup-name {
    font-weight: 700;
    color: #333;
    font-size: 1.375rem;
  }

  .bold-title {
    font-family: 'Kazimir Text', sans-serif;
    font-size: 1.125rem;
    font-weight: 900;
    color: #333;
  }

  .normal-invester-text {
    font-size: 0.75rem;
    color: #6d6d6f;
  }

  .invester-text {
    color: #6d6d6f;
    font-size: 0.75rem;
    text-transform: uppercase;
    font-weight: 600;
  }

  .invester-value {
    color: #535353;
    font-weight: bold;
    font-size: 0.75rem;
  }

  .tags-item {
    align-items: center;
    background-color: #e9ecf1 !important;
    border-radius: 1rem !important;
    color: #46608a !important;
    font-weight: 600 !important;
    margin-right: 0.5rem !important;
    padding: 0.2rem 0.5rem;
    font-size: 0.75rem;
  }

  .section-title {
    color: #282828;
  }

  .contact-infor {
    color: #f36328;
    font-weight: 600;
    font-size: 0.875rem;
    text-decoration: none !important;
  }

  .address-infor {
    font-size: 0.875rem;
    color: #6d6d6f;
  }

  .contact-link {
    text-decoration: none !important;
  }

  .letter-content {
    font-size: 0.875rem;
    color: #333;
    font-weight: 400;
  }

  .letter-wrapper {
    background-color: rgba(70, 96, 138, 0.04);
    border-radius: 0.5rem !important;
    border: none;
    height: 274px;
    overflow: auto;
  }

  .attachment-wrapper {
    background-color: rgba(243, 99, 40, 0.08);
    border-radius: 0.5rem !important;
    border: none;
    height: 251px;
    overflow: auto;
  }

  .list-attachments {
    .attachment {
      padding: 0.7rem;
      background-color: #fff;
      border-radius: 0.5rem;
      width: 47%;

      .title-attachment {
        color: #333;
        font-size: 0.875rem;
        font-weight: bold;
      }
    }
  }

  .startup-note {
    background-color: rgba(70, 96, 138, 0.04) !important;
    height: 459px;
    overflow: auto;
    border-radius: 0.5rem !important;
  }
}
