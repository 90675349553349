.payment-plans-modal {
  max-width: 67.5rem;

  .decor-left {
    position: absolute;
    left: 0;
    top: 0;
  }

  .decor-right {
    position: absolute;
    bottom: 1rem;
    right: -4.5rem;
  }

  .modal-header {
    border-bottom: none;
  }

  .modal-body {
    padding: 0 5.3125rem 5rem 5.3125rem;

    .title {
      font-family: 'Kazimir Text', sans-serif;
      font-size: 1.875rem;
      font-weight: 900;
      text-align: center;
    }

    .plan-wrapper {
      width: 17.3125rem;
      margin-left: auto;
      margin-right: auto;
      border: 1px solid rgba(196, 196, 196, 0.5);
      border-radius: 2.125rem;
      text-align: center;
      padding: 2.8125rem 1.25rem 1.875rem 1.25rem;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .plan-content {
        padding: 0 1.25rem;

        .plan-type {
          font-size: 1.5rem;
          font-weight: bold;
        }

        .plan-desc {
          font-size: 0.75rem;
          color: $color-gray2;
          height: 1.5rem;
          line-height: 0.75rem;
        }

        .plan-price {
          font-size: 0.875rem;

          span {
            color: $color-red;
            font-size: 2.25rem;
            font-weight: bold;
          }
        }

        .feature {
          font-size: 0.875rem;
          font-weight: 600;
          text-align: left;
          line-height: 0.875rem;
        }
      }

      .switch-plan-btn,
      .switch-plan-btn:active {
        width: 100%;
        height: 3rem;
        border-radius: 9999px;
        background-color: $color-blue !important;
        color: white !important;
        font-size: 1.125rem;
        font-weight: bold;
        border: none;
      }

      .current-plan-btn,
      .current-plan-btn:active {
        width: 100%;
        height: 3rem;
        border-radius: 9999px;
        background-color: transparent !important;
        color: $color-red !important;
        font-size: 1.125rem;
        font-weight: bold;
        border: 1px solid $color-red;
        line-height: 0;
      }

      .spinner-plan {
        color: white;
        position: absolute;
        margin-top: 4px;
        left: 75px;
      }
    }
  }
}
