.app-header-wrapper {
  margin: 0;
  padding: 1rem 6.25rem;
  height: 3.75rem;

  .nav-link {
    font-family: 'Proxima Nova', sans-serif;
    font-weight: 700;
    font-size: 1rem;
    color: $color-black !important;
    opacity: 0.6;
  }

  .navbar-nav {
    .active {
      border-bottom: 3px solid #f09b00;
    }
  }

  &.wrapper-light {
    .nav-link {
      color: $color-white !important;
    }
  }

  &.app-header-large-wrapper {
    position: relative;
    margin: 0;
    padding: 1rem 6.25rem;
    background-size: cover;
    padding-bottom: 15rem;
    height: 18.75rem;
  }

  .beta-tag {
    width: 33px;
    height: 14px;
    margin-left: 8px;
    background: #f09b00;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 3px 5px;
    border-radius: 30px;
    font-size: 8px;
    border: none;
    font-weight: 800;
  }

  &.blog-detail-header-wrapper {
    background-image: url('../image/blog_detail_banner_bg.png');
  }

  &.podcast-detail-header-wrapper {
    background-image: url('../image/podcast_detail_banner_bg.png');
  }

  &.video-detail-header-wrapper {
    background-image: url('../image/video_detail_banner_bg.png');
  }

  .founders-library-item {
    .dropdown-toggle::after {
      content: none;
      border: none;
    }

    &.active {
      .nav-link {
        opacity: 1;
      }
    }
  }

  .account-dropdown {
    a {
      opacity: 1 !important;
      font-style: normal;
      font-weight: 600;
      font-size: 1rem;
      color: #46608a;
    }

    .rounded-circle {
      object-fit: cover;
    }
  }

  .image-default {
    width: 40px;
    height: 40px;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    span {
      color: #fff;
      font-size: 13px;
      font-weight: 400;
    }
  }
}

.navbar-shadow {
  box-shadow: 0 0.25rem 1.25rem rgba(0, 0, 0, 0.12);
}
