.founders-library-podcast-widget {
  background-color: rgba(255, 233, 195, 0.5) !important;
  border: none !important;
  border-radius: 1.25rem !important;
  padding: 2.5rem;

  .card-title {
    color: #333;
    font-family: 'Kazimir Text', sans-serif;
    font-size: 1.75rem;
    font-weight: 900;
    margin: 0;
  }

  .card-text {
    font-size: 0.875rem;
    color: rgba(40, 40, 40, 0.6);
  }

  .card-body {
    padding: 0;
    margin-top: 0.625rem;

    .visit-podcasts {
      color: $color-blue;
      font-size: 0.875rem;
      font-weight: bold;
    }

    .podcast-img {
      object-fit: cover;
    }

    .podcast-title {
      color: rgba(40, 40, 40, 0.8);
      font-family: 'Kazimir Text', sans-serif;
      font-weight: 900;
      font-size: 1.125rem;
    }

    .podcast-date {
      color: rgba(40, 40, 40, 0.6);
      font-size: 0.875rem;
    }
  }
}
