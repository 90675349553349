.modal-business-model {
  .modal-header {
    color: #333;
    font-size: 25px;
    font-weight: 900;
    font-family: 'Kazimir Text', sans-serif;
  }

  .modal-body {
    padding: 30px;
  }

  .title {
    color: #535353;
    font-weight: bold;
    font-size: 1rem;
  }

  .form-control {
    border: 2px solid #edeff3;
    box-sizing: border-box;
    border-radius: 44px;
    padding: 10px 16px;
    color: #535353;
    font-size: 1rem;
  }

  .submit-button {
    width: 182px;
    height: 45px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f36328 !important;
    border: none !important;
    color: #fff !important;
    font-size: 1rem;
    font-weight: bold;
    border-radius: 31px;
  }
}
