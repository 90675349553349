.dashboard-personal-crm-contact {
  background-color: $color-gray !important;
  border-radius: 0.75rem !important;
  border: none !important;

  .card-body {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1.25rem;

    .contact-name {
      font-family: 'Kazimir Text', sans-serif;
      font-weight: 900;
      font-size: 0.9375rem;
    }

    .contact-position {
      font-size: 0.875rem;
      color: rgba(51, 51, 51, 0.6);
    }
  }
}
