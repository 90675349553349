.crm-view-mini-popup-wrapper {
  .heading {
    background-color: rgba(237, 239, 243, 0.5);
    padding: 0.5rem 1.5rem;
    margin-top: -1.2rem;
    width: 106%;
    margin-left: -1.3rem;
  }

  .tag {
    border-radius: 1.9rem;
    padding: 0.5rem 1.5rem;
    color: #fff;
    font-weight: 600;
    font-size: 0.875rem;

    &.light {
      color: #7a5c58;
    }
  }

  .reminder-time {
    border-bottom: 1px solid #e2e2e2;
  }

  .time-ago {
    color: #f36328;
    font-weight: 600;
    font-size: 0.75rem;
  }

  .content {
    color: #333;
    font-size: 1.125rem;
    padding: 0.5rem 0;

    .image {
      display: none;
    }
  }

  .attachment-title {
    color: #333;
    font-size: 1.125rem;
    font-weight: 600;
  }

  .file-size {
    color: #959aa2;
    font-size: 0.625rem;
    font-weight: 600;
  }

  .attachment-top {
    margin-bottom: -0.5rem;
  }

  .attachment {
    border: 1px solid #edeff3;
    box-sizing: border-box;
    padding: 0.3rem 0.5rem;
  }

  .edit-button {
    background: rgba(243, 99, 40, 0.12);
    border-radius: 1.9rem;
    color: #f36328;
    font-size: 1rem;
    font-weight: bold;
    border: none;
    padding: 0.1rem 1rem;
    height: 2.8rem;
  }

  .filename {
    color: #46608a;
    font-weight: 600;
    font-size: 0.875rem;
  }
}
