.founders-library-page-wrapper {
  .content {
    padding: 1.375rem 6.25rem 5.625rem 6.25rem;

    .banner {
      position: relative;
      width: 100%;
      margin-top: 1.875rem;
      height: 200px;
      background-color: #46608a;
      border-radius: 14px;
      overflow: hidden;

      img {
        height: 200px;
        margin-right: 0;
        float: right;
      }

      .banner-content {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 2.5rem;
        width: 50%;

        .label {
          color: $color-orange;
          font-size: 0.875rem;
          font-weight: bold;
        }

        .title {
          color: white;
          font-family: 'Kazimir Text', sans-serif;
          font-weight: 900;
          font-size: 2.5rem;
          line-height: 3rem;
        }

        .desc {
          color: white;
          opacity: 0.6;
          font-size: 0.875rem;
        }
      }
    }

    .downloadables-widget-decor {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
      margin-right: -6.25rem;
      pointer-events: none;
    }

    .blog-widget-decor-left {
      position: absolute;
      left: 0;
      bottom: 0;
      margin-left: -5.6rem;
      margin-bottom: -5.1rem;
      pointer-events: none;
    }

    .blog-widget-decor-right {
      position: absolute;
      right: 0;
      bottom: 0;
      pointer-events: none;
    }

    .podcast-widget-decor-bottom {
      position: absolute;
      left: 0;
      bottom: 0;
      margin-left: -5.2rem;
      margin-bottom: -3.5rem;
      pointer-events: none;
    }

    .podcast-widget-decor-top-left {
      position: absolute;
      left: 21.5625rem;
      top: 0;
      margin-top: -1rem;
      pointer-events: none;
    }

    .podcast-widget-decor-top-right {
      position: absolute;
      right: 0;
      top: 0;
      margin-top: -3.125rem;
      pointer-events: none;
    }
  }
}

@media (min-width: 992px) {
  .founders-library-wrapper {
    .content {
      padding: 1.25rem 6.25rem 5.625rem 6.25rem;
    }
  }
}
