.upload-document {
  width: 100%;
  height: 12rem;
  border-radius: 0.6rem;
  border: 2px dashed rgba(70, 96, 138, 0.6);

  &__title {
    color: #46608a;
    font-weight: bold;
    font-size: 1.125rem;
    line-height: 1.375;
    opacity: 0.8;
  }

  &__des {
    color: #46608a;
    font-weight: normal;
    font-size: 1rem;
    line-height: 1.375;
    opacity: 0.8;
  }

  &__des-hightlight {
    font-weight: 900;
    cursor: pointer;
    color: #f36229;
  }
}
