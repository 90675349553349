.personal-crm-contact-widget {
  height: 100%;
  background-color: rgba(70, 96, 138, 0.05);
  border-radius: 0.75rem;
  border: none;

  .filter-btn {
    background: none;
    border: none;

    &:hover {
      background-color: none;
    }
  }

  .attachment-text {
    font-size: 0.75rem;
    font-weight: bold;
    color: #46608a;
  }

  .filter-wrapper {
    width: 16rem;
    height: 18.5rem;
    overflow: auto;
    box-shadow: 0 0.625rem 1.25rem rgba(0, 0, 0, 0.12);
    background: #fff;
    position: absolute;
    right: 1.5rem;
    top: 7rem;
    border-radius: 0.5rem;
    padding: 1rem;
    z-index: 1;

    .title {
      color: #333;
      font-size: 0.75rem;
      font-weight: bold;
      text-transform: uppercase;
    }

    .select-all {
      text-transform: uppercase;
      font-size: 0.75rem;
      color: #46608a;
      font-weight: bold;

      &:hover {
        cursor: pointer;
      }
    }

    .tag-item {
      font-size: 0.865rem;
      color: #959aa2;
      font-weight: 600;
    }

    .active {
      color: #535353;
    }
  }

  .round {
    position: relative;
    padding-bottom: 0.5rem;
    border-bottom: 1px solid #edeff3;

    label {
      background-color: #fff;
      border: 1px solid #ccc;
      border-radius: 50%;
      cursor: pointer;
      height: 1.25rem;
      left: 0;
      position: absolute;
      top: 0.2rem;
      width: 1.25rem;

      &::after {
        border: 2px solid #fff;
        border-top: none;
        border-right: none;
        content: "";
        height: 6px;
        left: 4px;
        opacity: 0;
        position: absolute;
        top: 5px;
        transform: rotate(-45deg);
        width: 12px;
      }
    }

    input[type="checkbox"] {
      visibility: hidden;

      &:checked {
        + {
          label {
            background-color: #f36328;
            border-color: #f36328;

            &::after {
              opacity: 1;
            }
          }
        }
      }
    }
  }

  .search-wrapper {
    background-color: white;
    border: 2px solid #e2e2e2;
    box-sizing: border-box;
    border-radius: 6.25rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 27rem;
    height: 2.813rem;
    margin-right: auto;

    .search-input {
      font-size: 1rem;
      border: none;
      background: none;
      margin-left: 0.5rem;
    }

    .find-btn {
      background-color: $color-red !important;
      border-radius: 1.125rem;
      border: none;
      font-size: 0.875rem;
      font-weight: bold;
      min-width: 6.75rem;
      height: 2.25rem;
      margin-right: 0.2rem;
      margin-bottom: 0.075rem;
    }
  }

  .view-all-btn {
    cursor: pointer;
    color: #46608a;
    font-size: 0.875rem;
    font-weight: bold;
  }

  .contact-background {
    right: 8rem;
    position: absolute;
    bottom: 0;
    z-index: 1;
  }

  .extra-contact-background {
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: 0;
  }

  &__des {
    font-size: 0.875rem;
    color: #6d6d6f;
  }

  &__empty-data {
    height: 100%;
  }

  &__add-contact-btn {
    background: rgba(70, 96, 138, 0.08);
    border: none;
    border-radius: 0.75rem;
    height: 5.9rem;
    color: #46608a;
    font-size: 1.125rem;
    font-weight: bold;
    margin-bottom: 10rem;

    &:hover {
      background: rgba(70, 96, 138, 0.5);
    }
  }

  .card-title {
    font-family: 'Kazimir Text', sans-serif;
    font-weight: 900;
    font-size: 1.5625rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0;

    .add-contact-btn,
    .add-contact-btn:active {
      width: 10.3125rem;
      height: 2.375rem;
      border-radius: 1.1875rem;
      font-weight: bold;
      background-color: $color-blue;
      border: none;
    }
  }

  .card-body {
    padding: 0;

    .investor {
      font-weight: bold;
      color: $color-blue;

      span {
        height: 1rem;
        font-size: 0.75rem;
        background-color: rgba(70, 96, 138, 0.2);
        border-radius: 0.5rem;
      }
    }

    .peers {
      color: $color-gray3;

      span {
        height: 1rem;
        font-size: 0.75rem;
        background-color: rgba(70, 96, 138, 0.2);
        border-radius: 0.5rem;
        padding: 0.1rem 0.5rem;
      }
    }

    table {
      thead {
        background-color: rgba(70, 96, 138, 0.1);
        font-weight: bold;
        font-size: 0.75rem;
        color: $color-blue;
        border-radius: 0.1875rem;
        letter-spacing: normal;
        text-transform: none;

        th {
          border: none !important;
          padding: 0.25rem 0 !important;
        }

        th:first-child {
          width: 2.375rem;
        }

        th:last-child {
          width: 1.875rem;
        }
      }

      tbody {
        font-size: 0.875rem;
        color: #333;

        td {
          padding: 1.2rem 0 !important;
          vertical-align: middle !important;
          border-bottom: 1px solid #dee2e6;
        }

        tr:first-child {
          td {
            border-bottom: 1px solid #dee2e6;
          }
        }
      }
    }
  }
}
