.modal-create {
  width: 500px;
  height: 100vh;
  margin-top: 0;
  margin-right: 0;
  margin-bottom: 0;

  .modal-content {
    height: 100%;
    border-radius: 0;
    padding: 18px 40px;

    .dropdown-item {
      &:hover {
        background-color: unset;
      }
    }

    .header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 40px;
    }

    .footer {
      width: calc(100% + 80px);
      margin-left: -40px;
      border-top: 1px solid $color-gray;
      padding: 20px 40px;
    }

    .dropdown-type {
      .dropdown-type-toggle {
        width: 120px;
        height: 36px;
        border: 4px;
        background-color: #e9ecf1;
        color: $color-blue;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-left: 12px;
        padding-right: 12px;

        &::after {
          display: none;
        }
      }

      .dropdown-menu {
        min-width: 120px;
        width: 120px;
        height: 155px;
        box-shadow: 0 4px 20px rgba(0, 0, 0, 0.12);
        padding: 17px;
        border: none;
        border-radius: 8px;
        display: flex;
        justify-content: space-between;
        flex-direction: column;

        .dropdown-item {
          display: flex;
          align-items: center;
          padding: 0;

          .color-box {
            width: 8px;
            height: 8px;
            border-radius: 2px;
            margin-right: 7px;
            background-color: $color-blue;
          }

          span {
            font-weight: 400;
            font-size: 14px;
            line-height: 17px;
            color: $color-gray2;

            &.active {
              font-weight: 700;
              color: #333;
            }
          }
        }
      }
    }

    .close-button {
      background-color: unset !important;
      border: none;
      padding: 0;
      margin-left: 37px;
    }

    .dropdown-more {
      width: 6px;

      .dropdown-more-toggle {
        background-color: unset;
        border: none;
        padding: 0;

        &::after {
          display: none;
        }
      }

      .dropdown-menu {
        min-width: 150px;
        width: 150px;
        height: 78px;
        box-shadow: 0 4px 20px rgba(0, 0, 0, 0.12);
        padding: 17px;
        border: none;
        border-radius: 8px;
        display: flex;
        justify-content: space-between;
        flex-direction: column;

        .dropdown-item {
          font-size: 16px;
          font-weight: 600;
          line-height: 19px;
          padding: 0;

          &.delete {
            color: $color-red;
          }
        }
      }
    }

    .name-input {
      color: #333;
      font-size: 24px;
      font-weight: 600;

      .name {
        width: calc(100% + 11px);
        height: 45px;
        border: 1px solid $color-gray;
        border-radius: 4px;
        background: rgba(237, 239, 243, 0.2);
        color: #333;
        font-size: 24px;
        font-weight: 600;
        padding: 12px 10px;
        margin-left: -11px;
        margin-top: -4px;
        box-sizing: border-box;
      }
    }

    .input-group {
      display: flex;
      align-items: center;
      margin-top: 12px;
      margin-bottom: 12px;

      .label {
        align-items: center;
        width: 96px;
        color: #535353;
        font-size: 12px;
        line-height: 15px;
        font-weight: 600;
      }

      .input {
        display: flex;
        align-items: center;
      }
    }

    .dropdown-sprint {
      .dropdown-sprint-toggle {
        display: flex;
        align-items: center;
        background-color: unset;
        border: none;
        color: $color-blue;
        padding: 0;
        font-size: 12px;
        text-transform: uppercase;
        font-weight: 600;

        .input-icon {
          width: 23px;
          display: flex;
          align-items: center;
        }

        .toggle-icon {
          margin-left: 7px;
        }

        &::after {
          display: none;
        }
      }

      .dropdown-menu {
        min-width: 150px;
        width: 150px;
        box-shadow: 0 4px 20px rgba(0, 0, 0, 0.12);
        padding: 17px;
        padding-bottom: 7px;
        border: none;
        border-radius: 8px;
        display: flex;
        justify-content: space-between;
        flex-direction: column;

        .dropdown-item {
          font-size: 16px;
          font-weight: 600;
          line-height: 19px;
          padding: 0;
          margin-bottom: 10px;

          span {
            font-weight: 400;

            &.active {
              font-weight: 700;
            }
          }
        }
      }
    }

    .dropdown-marketing {
      .dropdown-marketing-toggle {
        display: flex;
        align-items: center;
        background-color: unset;
        border: none;
        color: $color-blue;
        padding: 0;
        font-size: 12px;
        text-transform: uppercase;
        font-weight: 600;

        .input-icon {
          width: 23px;
          display: flex;
          align-items: center;
        }

        .toggle-icon {
          margin-left: 7px;
        }

        &::after {
          display: none;
        }
      }

      .dropdown-menu {
        min-width: 320px;
        width: 320px;
        box-shadow: 0 4px 20px rgba(0, 0, 0, 0.12);
        padding: 16px;
        border: none;
        border-radius: 8px;
        display: flex;
        justify-content: space-between;
        flex-direction: column;

        &__header {
          display: flex;
          width: 100%;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 14px;

          &__title {
            color: #333;
            font-size: 12px;
            font-weight: 700;
          }

          &__select-all {
            border: none;
            background-color: unset !important;
            padding: 0;
            color: $color-blue;
            font-size: 12px;
            font-weight: 700;
          }
        }

        .dropdown-item {
          display: flex;
          align-items: center;
          width: 100%;
          font-size: 16px;
          font-weight: 600;
          line-height: 19px;
          padding: 0;
          padding-bottom: 10px;
          padding-top: 8px;
          border-bottom: 1px solid $color-gray;
          background-color: #fff !important;

          &--last {
            padding-bottom: 0;
            border-bottom: none;
          }

          .round {
            border: 1px solid #959aa2;
            width: 16px;
            height: 16px;
            margin-right: 8px;
            border-radius: 100%;
            display: flex;
            align-items: center;
            justify-content: center;

            &--checked {
              background-color: $color-red;
              border: none;
            }
          }

          span {
            font-weight: 600;
            font-size: 14px;
            color: $color-gray2;

            &.active {
              color: #535353;
            }
          }
        }
      }
    }

    .date-picker-wrapper {
      display: flex;
      align-items: center;
      background-color: unset;
      color: $color-blue;

      .input-calendar-icon {
        width: 23px;
        display: flex;
        align-items: center;
      }

      .remove-icon {
        margin-left: 7px;
      }

      button {
        padding: 0;
        border: none !important;
        background-color: unset !important;
      }

      .react-datepicker__input-container {
        input {
          width: 75px;
          color: $color-blue;
          font-weight: 600;
          font-size: 12px;
          text-transform: uppercase;
          border: none;
          background-color: unset;
        }
      }
    }

    .description-input {
      min-height: 70px;
      color: #535353;
      font-size: 14px;
      line-height: 21px;
      margin-top: 5px;

      textarea.description {
        width: calc(100% + 22px);
        margin-left: -11px;
        margin-top: -6px;
        background: rgba(237, 239, 243, 0.2);
        border: 1px solid $color-gray;
        border-radius: 4px;
        padding: 5px 10px 20px 10px;
        color: #535353;
        font-size: 14px;
        line-height: 21px;
      }
    }

    .input-box {
      width: 34px;
      height: 26px;
      background-color: $color-gray;
      border-radius: 2px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 600;
      font-size: 14px;
      border: none;
      color: $color-gray2;

      &.active {
        color: #fff;
        background-color: $color-blue !important;
      }

      &:active {
        background-color: $color-gray;
      }
    }

    .footer-input-group {
      margin-bottom: 30px;

      .label {
        display: flex;
        align-items: center;
        margin-bottom: 5px;

        .label-text {
          font-weight: 700;
          font-size: 16px;
          color: #535353;
          margin-right: 6px;
          line-height: 19px;
        }
      }
    }

    .input-desc {
      color: #6d6d6f;
      font-size: 10px;
      font-weight: 600;
      line-height: 12px;
      margin-top: 6px;
    }
  }
}
