.settings-sidebar {
  .title {
    font-family: 'Kazimir Text', sans-serif;
    font-weight: 900;
    font-size: 1.875rem;
    margin-top: 5rem;
    margin-left: 6.25rem;
  }

  .logout-btn {
    background: #edeff3 !important;
    border-radius: 4px !important;
    color: #46608a !important;
    font-size: 1rem !important;
    font-weight: bold !important;
    text-transform: uppercase !important;
    margin-top: 3rem;
    padding: 0.1rem 1.6rem !important;
    height: 2.6rem !important;
    width: 10rem !important;
    margin-left: 6rem !important;
  }

  .beta-tag {
    width: 33px;
    height: 14px;
    margin-left: 8px;
    background: #f09b00;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 3px 5px;
    border-radius: 30px;
    font-size: 8px;
    border: none;
    font-weight: 800;
  }

  .nav-link {
    font-size: 1rem;
    font-weight: bold;
    letter-spacing: 0.125rem;
    text-transform: uppercase;
    color: $color-gray2;
    padding: 0 0 0 6.25rem;
    width: 16.875rem;
    height: 2.8125rem;
    line-height: 2.8125rem;

    &.active {
      background-color: rgba(70, 96, 138, 0.2);
      border-radius: 0 9999px 9999px 0;
      color: $color-blue;
    }
  }
}
