.unlock-screen-wrapper {
  background-color: rgba(255, 255, 255, 0.92);
  position: absolute;
  z-index: 999;
  height: 98%;
  // width: 95%;
  width: 100%;
  padding-top: 15%;
  margin-left: -8px;

  .unlock-title {
    color: #333;
    font-weight: bold;
    font-size: 1.5rem;
  }

  .unlock-des {
    color: #282828;
    font-size: 0.875rem;
    font-weight: normal;
  }

  .information-text {
    color: #282828;
    font-size: 0.875rem;
    font-weight: normal;
    opacity: 0.8;
  }

  .unlock-button {
    background: #f36328;
    border-radius: 100px;
    color: #fff;
    font-weight: bold;
    font-size: 1.125rem;
    border: none;
    padding: 0.5rem 2rem;
  }
}

.small-unlock {
  height: 95% !important;
  padding-top: 7% !important;
}

.full-unlock {
  height: 100% !important;
  width: 100%;
  padding-top: 7% !important;
}

.comming-soon-wrapper {
  min-height: calc(100vh - 165px);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  .comming_soon_bg {
    width: 390px;
    height: 240px;
    margin-bottom: 48px;
  }

  .comming_soon_header {
    font-size: 48px;
    line-height: 68px;
    font-family: 'Kazimir Text', sans-serif;
    color: #000;
    margin-bottom: 0.5rem;
    font-weight: 900;
    text-align: center;
  }

  .comming_soon_content {
    font-size: 16px;
    line-height: 22px;
    color: #535353;
    text-align: center;
  }
}
