.billing-method-modal {
  max-width: 46.125rem;

  .decor-top {
    position: absolute;
    top: -2rem;
    right: 6rem;
    width: 11.7rem;
  }

  .decor-bottom {
    position: absolute;
    bottom: 0;
    left: 0;
  }

  .modal-header {
    border-bottom: none;
  }

  .modal-body {
    padding: 0 5.3125rem 5rem 5.3125rem;

    .title {
      font-family: 'Kazimir Text', sans-serif;
      font-size: 1.875rem;
      font-weight: 900;
    }
  }
}
