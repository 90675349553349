.founder {
  border-radius: 0.75rem;
  height: 24.25rem;

  &__name {
    font-weight: 600;
    font-size: 1.125rem;
    line-height: 1.375rem;
    color: #282828;
  }

  &__position {
    font-weight: normal;
    font-size: 0.875rem;
    line-height: 1.063rem;
    color: #282828;
    opacity: 0.7;
  }

  &__avatar {
    margin-right: 1.25rem;
  }

  &__item {
    margin-bottom: 1.25rem;
  }

  &__title {
    margin-bottom: 1.875rem;
  }
}
