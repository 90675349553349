.admin-dashboard-overview {
  background-color: rgba(237, 239, 243, 0.4);
  padding: 3.3125rem;
  padding-bottom: 4.6875rem;

  .title {
    font-size: 1.5625rem;
    font-weight: bold;
  }

  .card {
    border: 1px solid rgba(196, 196, 196, 0.5);
    border-radius: 0.75rem;
    overflow: hidden;

    .card-body {
      padding: 1.875rem 0;

      .total-count {
        font-family: 'Kazimir Text', sans-serif;
        font-weight: bold;
        font-size: 2.5rem;
        text-align: center;
      }

      .type {
        font-family: 'Kazimir Text', sans-serif;
        font-weight: 900;
        font-size: 0.875rem;
        text-align: center;
      }
    }

    .card-footer {
      border-top: 1px solid $color-light-gray2;
      background-color: white;
      display: flex;
      padding: 0;

      .section {
        width: 50%;
        text-align: center;
        padding: 1.5rem 0;
        border-right: 1px solid $color-light-gray2;

        span {
          color: $color-gray2;
        }
      }

      .section:last-child {
        border-right: none;
      }
    }
  }

  .recent-activity-section {
    .title {
      color: $color-gray2;
      font-size: 1rem;
      font-weight: bold;
      letter-spacing: 0.125rem;
      text-transform: uppercase;
    }

    .card-body {
      padding: 0;
    }
  }
}
