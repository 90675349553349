.admin-dashboard-categories {
  background-color: rgba(237, 239, 243, 0.4);
  padding: 3.3125rem;
  padding-bottom: 4.6875rem;

  .categories-section {
    .card {
      border: 1px solid rgba(196, 196, 196, 0.5);
      border-radius: 0.75rem;
      overflow: hidden;

      .card-body {
        padding: 0;
      }
    }
  }
}
