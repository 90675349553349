.founders-library-header {
  display: flex;
  justify-content: space-between;
  min-height: 2.5rem;

  .header-btn,
  .header-btn:active {
    background-color: $color-light-gray2 !important;
    padding: 0 1.25rem;
    font-weight: 700;
    font-size: 1rem;
    color: $color-gray2;
    border: none;
    height: 2.375rem;
    border-radius: 1.1875rem;
  }

  .header-btn-active,
  .header-btn-active:active {
    background-color: $color-red !important;
    color: white;
  }
}
