.startup-starter-admin-header {
  display: flex;
  overflow-x: scroll;
  width: 100%;
  margin-bottom: 24px;

  .header_item {
    padding: 11px 20px;
    font-weight: 700;
    font-size: 1rem;
    line-height: 1rem;
    color: $color-gray2;
    background-color: #edeff3;
    border-radius: 100px;
    margin-right: 0.5rem;

    &.active {
      color: #fff;
      background-color: $color-orange;
    }
  }
}
