.app-footer-wrapper {
  background-color: $color-light-blue;
  height: 14.3125rem;
  padding: 0 6.25rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  .signup-newsletter {
    font-family: 'Kazimir Text', sans-serif;
    color: white;
  }

  .signup-newsletter-wrapper {
    background-color: white;
    border: 2px solid #e2e2e2;
    box-sizing: border-box;
    border-radius: 6.25rem;
    display: flex;
    justify-content: space-between;
    width: 21.875rem;
    height: 2.8125rem;
    margin-top: 0.75rem;

    .email-input {
      font-size: 1.125rem;
      border: none;
      background: none;
      margin-left: 0.75rem;
    }

    .signup-btn {
      background-color: $color-orange;
      border-radius: 1.125rem;
      border: none;
      font-size: 1.125rem;
      font-weight: bold;
      min-width: 6.75rem;
      margin: 0.1rem;
      padding: 0.1rem;
    }
  }

  .box-bottom {
    margin-top: 2.375rem;
    margin-bottom: 1.25rem;
    padding-top: 1rem;
    border-top: 1px solid rgba(255, 255, 255, 0.1);
  }

  .all-rights-reserved {
    display: flex;
    justify-content: center;
    width: 100%;
    font-size: 0.875rem;
    color: rgba(255, 255, 255, 0.5);
  }

  .policy-box {
    display: flex;
    justify-content: flex-start;

    a {
      font-weight: 600;
      font-size: 0.875rem;
      color: rgba(255, 255, 255, 0.5);
      margin-right: 30px;
    }
  }
}
