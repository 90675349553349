.funding-modal {
  .form-label {
    color: #929292;
    font-size: 0.875rem;
    text-transform: uppercase;
    font-weight: bold;
  }

  .modal-content {
    top: 5rem;
  }

  .attactment-title {
    margin-right: 2rem;
  }

  .type-of-status-text {
    font-family: 'Kazimir Text', sans-serif;
    font-size: 1.125rem;
    font-weight: 900;
    color: #282828;
  }

  .metting-status {
    color: #f09b00 !important;
  }

  .pass-status {
    color: #f36328 !important;
  }

  .inreview-status {
    color: #959aa1 !important;
  }

  .close-attacment-button {
    position: absolute;
    border: none;
    background: none;
  }

  .ck-toolbar {
    border-top-left-radius: 1rem !important;
    border-top-right-radius: 1rem !important;
  }

  .attachment-button {
    border-radius: 6.25rem;
    background-color: #e9ecf1;
    height: 2.375rem;
    width: 11.438rem;
    color: #46608a;
    border: none;
    font-weight: bold;
  }

  .ck-content {
    border-bottom-left-radius: 1rem !important;
    border-bottom-right-radius: 1rem !important;

    p {
      min-height: 19rem;
    }
  }

  .form-control {
    border-radius: 2.75rem !important;
  }

  .investor-wrapper {
    height: 16rem;
    overflow: auto;

    .avatar {
      width: 10%;
      margin-top: -4rem;
    }

    .investor-infor {
      width: 90%;
    }

    .line-border {
      width: 100%;
      height: 1px;
      background: #959aa1;
      margin: 0.5rem 0;
      opacity: 0.2;
    }

    .bold-invester-text {
      color: #333;
      font-size: 1.125rem;
      font-weight: bold;
      font-family: 'Kazimir Text', sans-serif;
    }

    .normal-invester-text {
      font-size: 0.875rem;
      color: #535353;
    }

    .invester-text {
      color: #959aa1;
      font-size: 0.75rem;
      text-transform: uppercase;
      font-weight: bold;
    }

    .invester-value {
      color: #535353;
      font-weight: bold;
      font-size: 0.875rem;
    }

    .tags-item {
      align-items: center;
      background-color: #e9ecf1 !important;
      border-radius: 1rem !important;
      color: #46608a !important;
      font-weight: normal !important;
      margin-right: 0.5rem !important;
      padding: 0.2rem 0.5rem;
      font-size: 0.75rem;
    }
  }

  .document-title {
    color: #333;
    font-size: 0.875rem;
    font-family: 'Kazimir Text', sans-serif;
    font-weight: bold;
  }

  .document-item {
    border: 1px solid #edeff3;
    border-radius: 0.75rem;
    padding: 0.75rem 1rem;
    margin-bottom: 1rem;
  }

  .document-des {
    color: #535353;
    font-size: 0.75rem;
  }

  .document-shared-body {
    padding: 0 4.5rem;
  }

  .document-background {
    position: absolute;
    top: 0;
    left: 0;
  }

  .modal-header {
    border-bottom: none !important;
  }

  .modal-body {
    form {
      margin-top: -3rem;
      padding-bottom: 3rem;
    }
  }

  .funding-title {
    font-family: 'Kazimir Text', sans-serif;
    font-size: 2rem;
    color: #333;

    .bold-text {
      font-weight: bold;
    }
  }

  .funding-des {
    font-size: 0.875rem;
    color: #333;
  }

  .funding-background {
    position: absolute;
    bottom: 0;
    right: 0;
    border-radius: 0.5rem;
  }

  .funding-button {
    background: #46608a;
    border-radius: 6.25rem;
    color: #fff;
    font-size: 1.25rem;
    font-weight: 700;
    border: none;
    padding: 0.5rem 3.5rem !important;
    bottom: 3rem;
  }

  .unlock-button {
    background: #f36328;
    border-radius: 100px;
    color: #fff;
    font-weight: bold;
    font-size: 1.125rem;
    border: none;
    padding: 0.5rem 2rem;
  }

  .back-button {
    background: none;
    border-radius: 6.25rem;
    color: #959aa1;
    font-size: 1.25rem;
    font-weight: 700;
    border: 2px solid #959aa1;
    padding: 0.5rem 3.5rem !important;
    bottom: 3rem;
  }

  .round {
    position: relative;

    label {
      background-color: #fff;
      border: 1px solid #ccc;
      border-radius: 50%;
      cursor: pointer;
      height: 1.25rem;
      left: 0;
      position: absolute;
      top: 0.2rem;
      width: 1.25rem;

      &::after {
        border: 2px solid #fff;
        border-top: none;
        border-right: none;
        content: "";
        height: 6px;
        left: 4px;
        opacity: 0;
        position: absolute;
        top: 5px;
        transform: rotate(-45deg);
        width: 12px;
      }
    }

    input[type="checkbox"] {
      visibility: hidden;

      &:checked {
        + {
          label {
            background-color: #f36328;
            border-color: #f36328;

            &::after {
              opacity: 1;
            }
          }
        }
      }
    }
  }
}

.funding-status-bar {
  background: rgba(243, 99, 40, 0.12);
  border-radius: 0.75rem;
  padding: 2.2rem 1.9rem;

  .funding-status-text {
    font-family: 'Kazimir Text', sans-serif;
    font-weight: 900;
    font-size: 1.125rem;
    color: #333;
  }

  .amount-investor {
    font-size: 1.125rem;
    font-weight: bold;
    color: #535353;
  }

  .status {
    font-size: 0.625rem;
    color: #6d6d6f;
    font-weight: 600;
    text-transform: uppercase;
  }

  .funding-detail {
    background: #f36328 !important;
    border-radius: 0.25rem;
    color: #fff;
    font-weight: 700;
    font-size: 0.625rem;
    border: none !important;
    padding: 0.75rem 3.5rem;
  }
}
