.modal-custom {
  z-index: 1401;
  position: relative;

  &__close {
    position: absolute;
    top: 20px;
    right: 20px;
  }
}

.tutorial-modal {
  width: 409px;
  padding: 20px 40px 40px 40px;
  border-radius: 12px;
  background-color: #fff;
  position: absolute;
  display: flex;
  flex-direction: column;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.15);
  overflow: hidden;

  &__starter {
    right: -420px;
    top: 0;
  }

  &__startup-investor {
    top: calc(100% + 20px);
    left: 0;
    width: 80%;
    height: 260px;
  }

  &__manage-invest {
    top: calc(100% + 20px);
    left: 0;
    width: 100%;
  }

  &__crm-investor {
    left: -520px;
    top: 0;
    width: 500px;
    height: 510px;
  }

  &__crm {
    left: -420px;
    top: 0;
  }

  &__founder-library {
    left: 0;
    top: -250px;
    height: 237px;
  }

  &__banner {
    right: 0;
    bottom: -250px;
    height: 237px;
  }

  &__canvas {
    left: 0;
    bottom: -270px;
    height: 257px;
  }

  &__contact {
    right: -420px;
    top: 0;
  }

  &__notes {
    left: 0;
    top: -230px;
    height: 217px;
  }

  &__reminder {
    left: -420px;
    top: 0;
  }

  &__startup {
    top: -268px;
    left: 0;
    width: 869px;
    height: 244px;
    background-color: #fff;
    overflow: visible;
    padding: 50px 70px 40px 450px;
  }

  .custom-title {
    color: #282828;
    font-weight: 800;
    font-size: 24px;
    z-index: 1;

    &__startup {
      padding-bottom: 15px;
    }
  }

  .custom-content {
    font-size: 14px;
    line-height: 20px;
    color: #282828;
    margin-bottom: 20px;
  }

  .custom-steps {
    color: #959aa1;
    font-size: 14px;
  }

  .custom-steps-corner {
    color: #fff;
    font-size: 14px;
    position: absolute;
    left: 40px;
    top: 20px;
  }

  .decor-zigzag {
    position: absolute;
    top: 40px;
    right: -50px;
  }

  .decor-startup-investor-top {
    position: absolute;
    top: 0;
    right: 50px;
    z-index: 0;
  }

  .decor-startup-investor-bot {
    position: absolute;
    bottom: 0;
    left: 120px;
  }

  .invest-decor-top {
    position: absolute;
    top: 0;
    right: 0;
    z-index: -1;
  }

  .invest-decor-bottom {
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: -1;
  }

  .modal-startup-bg {
    position: absolute;
    top: 0;
    left: 0;
    height: 244px;
  }

  .bg_crm_investor {
    width: calc(100% + 80px);
    margin-left: -40px;
    margin-top: 40px;
  }

  .crm_investor_decor {
    position: absolute;
    top: -20px;
    left: 60px;
    z-index: -1;
  }

  .decor-2-zigzag {
    position: absolute;
    bottom: -36px;
    right: 285px;
  }

  .starter-block {
    width: 350px;
    display: flex;
    flex-direction: column;
  }

  .decor-bottom {
    position: absolute;
    bottom: -12px;
    left: -22px;
    z-index: -1;
  }

  .button-group {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .back {
      height: 40px;
      padding-left: 0;
      font-size: 16px;
      font-weight: 700;
      color: #959aa1 !important;
      background-color: unset !important;
      border: none !important;
    }

    .next {
      height: 40px;
      width: 160px;
      background-color: $color-red !important;
      color: #fff !important;
      font-size: 18px;
      border: none !important;
      font-weight: 700;
      border-radius: 60px;
    }
  }
}

.tutorial-dashboard-modal {
  .modal-lg {
    max-width: 650px;
  }

  .modal-content {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 650px;
    height: 640px;
    border-radius: 0.75rem;
    box-shadow: 0 0 30px rgba(104, 90, 90, 0.15);
    background-color: #fff;
    text-align: center;
    align-items: center;
    padding-left: 60px;
    padding-right: 60px;

    .logo {
      width: 116px;
      margin-top: 32px;
    }

    .banner {
      width: 432px;
      margin-top: 45px;
      margin-bottom: 20px;
      z-index: 2;
    }

    .banner-decor {
      position: absolute;
      z-index: 1;
      top: -70px;
      right: 50px;
    }

    .title {
      font-family: 'Kazimir Text', sans-serif;
      font-size: 32px;
      margin-bottom: 1rem;

      span {
        font-weight: 700;
      }
    }

    .content {
      font-size: 14px;
      color: #282828;
      line-height: 17px;
      margin-bottom: 40px;
    }

    .steps {
      margin-top: 12px;
      color: #959aa1;
      font-size: 14px;
    }
  }
}

.tutorial-button {
  font-family: 'Proxima Nova', sans-serif;
  height: 44px;
  border-radius: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 67px;
  padding-right: 67px;
  color: #fff !important;
  background-color: $color-red !important;
  border: none !important;
  font-weight: 700;
}

.close-icon {
  position: absolute;
  background-color: unset !important;
  border: none !important;
  top: 0;
  right: 0;
  width: 51px !important;
  height: 51px !important;
}
