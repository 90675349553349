.orc-structure-wrapper {
  .member-list {
    width: 130px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-right: 1.25rem;
  }

  .org-structure-box {
    width: calc(100% - 130px);
    min-width: 43.75rem;
  }

  .org-structure {
    text-align: center;
    position: relative;
    background-color: #f4f5f7;
    border-radius: 0.75rem;
    padding: 2rem;
    padding-bottom: 4rem;
    min-width: calc(100% - 130px);
    overflow: scroll;
    display: grid;

    &__row {
      display: flex;
      justify-content: space-around;
      padding-bottom: 3.5rem;
    }

    &__item {
      border: 1px dashed rgba(70, 96, 138, 0.5);
      background: rgba(70, 96, 138, 0.15);
      border-radius: 0.375rem;
      height: 6rem;
      position: relative;

      .trash-button {
        border: none;
        background-color: unset;
        width: 24px;
        padding: 0;
        position: absolute;
        top: 0;
        right: 0;
      }

      .input-position {
        width: 6.625rem;
        height: 1.375rem;
        background: rgba(255, 255, 255, 0.5);
        border: 1px solid #fff;
        border-radius: 0.25rem;
        color: #535353;
        font-size: 0.75rem;
        line-height: 1rem;
        text-align: center;
        position: absolute;
        bottom: 10px;
        right: 10px;
        left: 10px;

        &:focus {
          border: 1px solid #f36328;
        }
      }

      .input-position-active {
        width: 6.625rem;
        height: 1.375rem;
        background: rgba(255, 255, 255, 0.5);
        border: 1px solid #fff;
        border-radius: 0.25rem;
        color: #535353;
        font-size: 0.75rem;
        line-height: 1rem;
        text-align: center;

        &:focus {
          border: 1px solid #f36328;
        }
      }
    }
  }

  .team-member-item {
    position: relative;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    width: 8.125rem;
    height: 5.25rem;
    background: #fafbfd;
    border: 1px dashed rgba(149, 154, 162, 0.5);
    border-radius: 0.375rem;
    margin-bottom: 0.5rem;

    &.team-member-item__position {
      background: rgba(70, 96, 138, 0.15);
      height: 100%;
      border: unset;
      background-color: unset;
    }

    .dragable {
      position: absolute;
      top: 0.5rem;
      right: 0.5rem;
    }

    .name {
      font-size: 0.75rem;
      color: #333;
      font-weight: 700;
    }
  }

  .add-position-button {
    position: absolute;
    bottom: 20px;
    left: calc(50% - 6.125rem);
    margin: 1rem auto;
    border: 1px dashed #959aa2;
    border-radius: 0.375rem;
    width: 12.125rem;
    height: 2.25rem;
    color: #959aa2;
    font-size: 0.875rem;
    font-weight: 700;
    background-color: unset;
  }
}
