.marketing-strategy-wrapper {
  .channel-list {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-right: 1.25rem;

    .channel-item {
      position: relative;
      align-items: center;
      flex-direction: column;
      justify-content: center;
      width: 100%;
      height: 5.7rem;
      border-radius: 0.375rem;
      margin-bottom: 1.5rem;
      border-left: 4px solid #f2994a;
      background: rgba(242, 153, 74, 0.06);
      padding: 0.5rem;

      .des {
        font-weight: normal;
        font-size: 0.75rem;
        color: #6d6d6f;
      }

      .title {
        font-weight: 600;
        font-size: 0.875rem;
        color: #333;
      }

      .priority {
        color: #219653;
        font-weight: 600;
        font-size: 0.75rem;
      }

      &.channel-item__position {
        background: rgba(70, 96, 138, 0.15);
        height: 100%;
        border: unset;
        background-color: unset;
      }

      .dragable {
        position: absolute;
        top: 0.5rem;
        right: 0.5rem;
      }

      .name {
        font-size: 0.75rem;
        color: #333;
        font-weight: 700;
      }
    }
  }

  .strategy-content {
    text-align: center;
    background-color: #f4f5f7;
    border-radius: 0.75rem;
    min-width: 43.75rem;
    width: calc(100% - 130px);
    padding: 2rem;

    &__row {
      display: flex;
      justify-content: space-around;
      padding-bottom: 3.5rem;
    }

    &__item {
      border: 1px dashed rgba(70, 96, 138, 0.5);
      background: rgba(70, 96, 138, 0.15);
      border-radius: 0.375rem;
      height: 6rem;
      position: relative;

      .trash-button {
        border: none;
        background-color: unset;
        padding: 0;
        position: absolute;
        top: 0;
        right: 0.5rem;
      }

      .input-position {
        width: 6.625rem;
        height: 1.375rem;
        background: rgba(255, 255, 255, 0.5);
        border: 1px solid #fff;
        border-radius: 0.25rem;
        color: #535353;
        font-size: 0.75rem;
        line-height: 1rem;
        text-align: center;
        position: absolute;
        bottom: 10px;
        right: 10px;
        left: 10px;
      }

      .input-position-active {
        width: 6.625rem;
        height: 1.375rem;
        background: rgba(255, 255, 255, 0.5);
        border: 1px solid #f36328;
        border-radius: 0.25rem;
        color: #535353;
        font-size: 0.75rem;
        line-height: 1rem;
        text-align: center;
      }
    }
  }

  .btn-export {
    font-weight: 700;
    color: #677d9f;
    background-color: #edeff3;
    border-radius: 6.25rem;
    height: 2.625rem;
    width: 17.625rem;
    border: none;

    &:active {
      color: #677d9f !important;
      border: none !important;
      background-color: #edeff3 !important;
    }
  }

  .beta-tag {
    width: 33px;
    height: 14px;
    margin-left: 8px;
    background: #f09b00;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 3px 5px;
    border-radius: 30px;
    font-size: 8px;
    border: none;
    font-weight: 800;
    margin-top: 4px;
  }

  .strategy-item {
    width: 100%;
    padding: 1rem 0;
    background: rgba(70, 96, 138, 0.08);
    border-radius: 0.75rem;

    .quarter_name {
      border: none !important;
      font-weight: 900 !important;
      color: #46608a !important;
      font-size: 1.375rem !important;
      font-family: 'Kazimir Text', sans-serif !important;
      background: none !important;
    }

    .quarter_des {
      border: none !important;
      color: #6d6d6f !important;
      font-size: 1rem !important;
      background: none !important;
    }

    .month_name {
      color: #535353 !important;
      font-weight: 600 !important;
      font-size: 0.75rem !important;
      background-color: rgba(70, 96, 138, 0.12) !important;
      border-radius: 4px !important;
      height: 1.2rem !important;
      min-height: 1.2rem !important;
      margin-bottom: 0.5rem;
    }

    .strategy-data {
      padding: 0 1.5rem;
    }

    .block-drop {
      height: 3rem;
      width: 100%;
      border: 1px dashed #959aa2;
      border-radius: 4px;

      &:hover {
        background: rgba(243, 99, 40, 0.12);
        border: 1px dashed #f36328;
      }

      .des {
        font-size: 0.75rem;
        font-weight: 600;
        color: rgba(83, 83, 83, 0.5);
      }
    }
  }

  .startegy-item-input {
    background: #fff;
    border-radius: 0.75rem;
    padding: 1rem 0.7rem;
    margin: 1rem 0;

    .task-title {
      width: 100%;
      background: none !important;
      border: none !important;
      height: 1.18rem !important;
      min-height: 1.18rem !important;
      font-size: 1rem;
      font-weight: 600;
      color: #333;
    }

    .delete-button {
      background: rgba(226, 226, 226, 0.3);
      border: none;
      border-radius: 50%;
      width: 1.75rem;
      height: 1.75rem;
      padding: 0 !important;

      &:hover {
        background: rgba(226, 226, 226, 0.5);
      }
    }

    .channel-title {
      padding: 0.3rem 0.5rem;
      background: rgba(242, 153, 74, 0.12);
      border-radius: 4px;
      color: #f2994a;
      font-weight: 600;
      font-size: 0.625rem;
    }

    .notes {
      color: #535353;
      font-size: 0.875rem;
    }
  }
}
