.founders-library-downloadable-widget {
  background-color: $color-light-gray2 !important;
  border: none !important;
  border-radius: 1.25rem !important;
  padding: 1.875rem;
  padding-bottom: 1.5625rem;
  position: relative;
  height: 100%;

  .card-title {
    color: #333;
    font-family: 'Kazimir Text', sans-serif;
    font-size: 1.75rem;
    font-weight: 900;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0;

    .visit {
      font-weight: bold;
      font-size: 0.875rem;
      color: $color-blue;
      height: 1.5rem;
    }
  }

  .card-text {
    font-size: 0.875rem;
    color: rgba(40, 40, 40, 0.6);
  }

  .card-body {
    padding: 0;
    margin-top: 1.5625rem;

    .downloadable-img {
      object-fit: cover;
    }

    .downloadable-title {
      color: rgba(40, 40, 40, 0.8);
      font-family: 'Kazimir Text', sans-serif;
      font-weight: 900;
      font-size: 1.25rem;
    }

    .carousel-item {
      width: 13.75rem;
    }
  }

  .card-footer {
    display: flex;
    justify-content: center;
    align-items: center;
    background: none;
    border: none;
    padding: 0;
    color: #535353;
    font-size: 1.125rem;

    .link-btn {
      line-height: 0;
    }

    span {
      font-weight: bold;
    }
  }
}
