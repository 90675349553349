.crm-new-note-wrapper {
  .react-datepicker-popper {
    z-index: 2;
  }

  button.submit-button {
    border-radius: 1.1875rem;
    border: none;
    font-size: 1rem;
    height: 2.75rem;
    font-weight: bold;
    background-color: #f36328 !important;
    color: $color-white;
    width: 100%;
    width: 11.375rem;
  }

  .close-attacment-button {
    position: absolute;
    border: none;
    background: none;
  }

  .attactment-title {
    margin-right: 2rem;
  }

  .form-group {
    label {
      color: $color-gray3;
      font-size: 0.875rem;
      line-height: 1.063rem;
      font-weight: bold;
      text-transform: uppercase;
      letter-spacing: 0.125rem;
    }

    .form-control {
      height: 2.875rem;
      border: 0.125rem solid #e2e2e2;
      box-sizing: border-box;
      border-radius: 1.5625rem;
      padding: 0 1.75rem;
      font-size: 1rem;
      color: $color-mine-shaft;

      &.tag-input {
        padding: 0 1.75rem;
        color: #959aa1;
        height: 2.25rem;
        border-radius: 2rem;
        padding-right: 1rem;
        padding-left: 1rem;
        border: 1px dashed #959aa1;

        &.has-color {
          color: #fff;
          font-weight: 600;
          border: none;

          &.light {
            color: #7a5c58;
          }
        }
      }
    }
  }

  .ck.ck-editor {
    .ck-editor__editable {
      min-height: 18.75rem;
    }

    .ck.ck-toolbar.ck-toolbar_grouping {
      border-top: 2px solid #e2e2e2;
      border-left: 2px solid #e2e2e2;
      border-right: 2px solid #e2e2e2;
      border-top-left-radius: 0.625rem;
      border-top-right-radius: 0.625rem;
    }

    .ck.ck-content {
      border-bottom: 2px solid #e2e2e2;
      border-left: 2px solid #e2e2e2;
      border-right: 2px solid #e2e2e2;
      border-bottom-left-radius: 0.625rem;
      border-bottom-right-radius: 0.625rem;
    }
  }

  .attachment-button {
    border-radius: 6.25rem;
    background-color: #e9ecf1;
    height: 2.375rem;
    width: 11.438rem;
    color: $color-light-blue;
    border: none;
    font-weight: bold;
  }

  .input-group {
    .input-group-prepend {
      position: absolute;
      z-index: 1;
      top: 0.75rem;
      left: 1rem;
    }

    input {
      padding-left: 2.75rem !important;
    }
  }

  .select-box {
    position: relative;
    cursor: pointer;

    .type-dropdown {
      appearance: none;
      -moz-appearance: none;
      -webkit-appearance: none;
      cursor: pointer;
    }

    img {
      position: absolute;
      right: 1rem;
      top: 1.25rem;
    }
  }

  .react-datepicker-wrapper {
    width: 100%;
  }

  .custom-control {
    min-height: 2rem;
    padding-left: 2.75rem;
  }

  .custom-switch .custom-control-label::before {
    left: -2.75rem;
    width: 2.75rem;
    pointer-events: all;
    border-radius: 0.75rem;
    position: absolute;
    top: 0.25rem;
    height: 1.5rem;
    content: "";
  }

  .custom-switch .custom-control-label::after {
    top: calc(0.25rem + 2px);
    left: calc(-2.75rem + 2px);
    width: calc(1.5rem - 4px);
    height: calc(1.5rem - 4px);
    border-radius: 0.75rem;
    position: absolute;
    display: block;
  }

  .custom-switch .custom-control-input:checked ~ .custom-control-label::after {
    background-color: #fff;
    transform: translateX(1.25rem);
  }

  .color-picker-wrapper {
    width: 216px;
    height: 235px;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.12);
    border-radius: 8px;
    position: absolute;
    right: 0;
    top: 4.5rem;
    z-index: 9999;
  }

  .button-tag {
    border: 1px dashed #e2e2e2;
    color: #e2e2e2;
    font-size: 16px;
    background-color: unset !important;
    border-radius: 31px;
    height: 35px;
    width: 150px;
    margin-top: 5px;
    line-height: 19px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .color-picker-circle-wrapper {
    width: 218px;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.12);
    border-radius: 8px;
    position: absolute;
    right: 1rem;
    top: 4.5rem;
    z-index: 9999;
    background-color: #fff;

    .body {
      padding: 24px;
      border-bottom: 1px solid #e2e2e2;

      .circle-picker {
        width: unset !important;
      }
    }

    .footer {
      padding: 16px 24px 24px 24px;
      display: flex;
      justify-content: flex-end;

      .btn {
        padding: 8px 22px;
        color: #fff;
        font-weight: 700;
        font-size: 16px;
        line-height: 20px;
        background-color: #10afa7;
        border-radius: 31px;
        border: unset;
      }
    }
  }

  .time_range {
    .react-datepicker-wrapper {
      width: 50%;
      text-align: center;

      input {
        border: none;
        width: 70%;
        text-align: center;
      }
    }
  }
}
