.notifications-page-wrapper {
  padding: 3.875rem 2.5rem 2.5rem 2.5rem;

  .title {
    font-weight: bold;
    font-size: 1.875rem;
  }

  .section {
    display: flex;
    justify-content: space-between;

    .section-title {
      font-size: 1.125rem;
      font-weight: 600;
    }

    .section-desc {
      color: $color-gray2;
      width: 25rem;
    }
  }
}
