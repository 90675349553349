h1,
h2,
h3,
h4,
h5,
p {
  color: $color-black;
}

.font-16 {
  font-size: 16px;
}

.text-pinky {
  color: #10afa7;
}

.text-pink {
  color: #eb5757;
}
