.admin-dashboard-user-detail {
  .startup-item-wrapper {
    width: auto;
    border: 1px solid rgba(149, 154, 162, 0.25);
    height: auto;
    margin-bottom: 30px;

    .id {
      font-size: 14px;
    }

    .name {
      font-size: 18px;
    }

    .mission {
      @include ellipsis-special(2);

      font-size: 12px;
      color: #6d6d6f;
    }

    .startup-item-body {
      min-height: 84px;
    }

    .startup-item-description {
      font-size: 14px;
    }
  }
}
