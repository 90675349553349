.metric {
  border-radius: 0.75rem;
  height: 41.5rem;

  &__title {
    margin-bottom: 1.875rem;
  }

  &__name {
    font-weight: normal;
    font-size: 0.875rem;
    line-height: 1.063rem;
    color: #282828;
    opacity: 0.7;
  }

  &__value {
    font-weight: 600;
    font-size: 1.375rem;
    line-height: 1.688rem;
    color: #46608a;
  }

  &__item {
    padding: 1.438rem 0;
  }
}
