.favorites-page-wrapper {
  .content {
    padding: 1.25rem 6.25rem 7.5rem 6.25rem;
    position: relative;

    .banner-section {
      position: relative;
      width: 100%;
      height: 200px;
      margin-top: 1.875rem;
      background-color: #f6f7f9;
      border-radius: 0.875rem;

      img.bg {
        position: absolute;
        height: 200px;
        right: 0;
        top: 0;
      }

      .banner-content {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 2.5rem;

        .label {
          color: $color-red;
          font-size: 0.875rem;
          font-weight: bold;
        }

        .title {
          color: #333;
          font-family: 'Kazimir Text', sans-serif;
          font-weight: 900;
          font-size: 2.5rem;
          line-height: 3rem;
        }
      }

      .banner-search {
        display: flex;
        align-items: center;
        position: absolute;
        right: 2.5rem;
        top: 50%;
        transform: translateY(-50%);

        .search-wrapper {
          background-color: white;
          border: 2px solid #e2e2e2;
          box-sizing: border-box;
          border-radius: 6.25rem;
          display: flex;
          justify-content: space-between;
          width: 28.375rem;
          height: 2.8125rem;
          margin-right: 1.875rem;

          .search-input {
            font-size: 1rem;
            border: none;
            background: none;
            margin-left: 0.75rem;
          }

          .lookup-btn {
            background-color: $color-red;
            border-radius: 1.125rem;
            border: none;
            font-size: 1rem;
            font-weight: bold;
            width: 9.75rem;
            margin: 0.1rem;
            padding: 0.1rem;
          }
        }

        .sort-wrapper {
          color: rgba(40, 40, 40, 0.6);
          font-size: 0.875rem;

          b {
            color: $color-blue;
          }
        }
      }
    }

    .section-title {
      color: rgba(51, 51, 51, 0.8);
      font-family: 'Kazimir Text', sans-serif;
      font-weight: 900;
      font-size: 1.875rem;
    }

    .blog-wrapper {
      position: relative;

      .blog-img {
        object-fit: cover;
      }

      .blog-title {
        color: #535353;
        font-family: 'Kazimir Text', sans-serif;
        font-weight: 900;
        font-size: 1.25rem;
      }

      .blog-date {
        color: rgba(40, 40, 40, 0.6);
        font-size: 0.875rem;
      }
    }

    .favorite {
      position: absolute;
      top: 0.625rem;
      right: 0.625rem;
    }

    .podcast-wrapper {
      position: relative;

      .podcast-img {
        object-fit: cover;
      }

      .podcast-title-wrapper {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;

        .podcast-title {
          width: 70%;
          color: #535353;
          font-family: 'Kazimir Text', sans-serif;
          font-weight: 900;
          font-size: 1.25rem;
        }

        .podcast-show {
          margin-bottom: 0.1rem;
        }
      }
    }

    .video-wrapper {
      position: relative;

      .video-type {
        color: white;
        font-size: 0.875rem;
        font-weight: bold;
      }

      .video-title-wrapper {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;

        .video-title {
          width: 70%;
          color: #535353;
          font-family: 'Kazimir Text', sans-serif;
          font-weight: 900;
          font-size: 1.25rem;
        }
      }
    }

    .decor-left {
      position: absolute;
      left: 0;
      bottom: -0.1rem;
      z-index: -1;
    }

    .decor-right {
      position: absolute;
      right: -2.1875rem;
      bottom: -3.875rem;
      z-index: -1;
    }
  }
}
