.pitch-deck-preview-wrapper {
  padding: 64px;
  position: relative;
  zoom: 0.2;
  width: 1250px;
  height: 720px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;

  &--preview {
    zoom: 1;
  }

  .logo {
    width: 48px;
    height: 48px;
    position: absolute;
    top: 64px;

    &--right {
      right: 64px;
    }

    &--left {
      left: 64px;
    }
  }

  .info {
    position: absolute;
    bottom: 64px;
    left: 64px;
    text-align: left;
  }

  .title-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    &__name {
      font-size: 80px;
      line-height: 80px;
      font-weight: 700;
    }

    &__line {
      width: 320px;
      height: 1px;
      background-color: #535353;
      opacity: 0.25;
      margin-top: 20px;
      margin-bottom: 20px;
    }

    &__desc {
      color: #535353;
      font-weight: 600;
      font-size: 18px;
      line-height: 18px;
    }
  }

  .header {
    position: absolute;
    top: 64px;
    width: 1152px;

    &--corner {
      width: 400px;
    }
  }

  .problem-box {
    position: absolute;
    top: 198px;
    left: 254px;

    .content {
      &__market-analysis-circle-summary {
        width: 458px;
        height: 458px;
        position: relative;

        &--first-item {
          width: 458px;
          height: 458px;
          opacity: 0.25;
          box-shadow: 0 -4px 80px rgb(0 0 0 / 12%);
          border-radius: 50%;
          position: absolute;
        }

        &--first-item-border {
          opacity: 0.25;
          width: 458px;
          height: 458px;
          border-radius: 50%;
          position: absolute;
        }

        &--second-item {
          width: 323px;
          height: 323px;
          border-radius: 50%;
          position: absolute;
          bottom: 0;
          left: 67px;
          opacity: 0.5;
        }

        &--second-item-border {
          width: 323px;
          height: 323px;
          opacity: 0.5;
          border-radius: 50%;
          position: absolute;
          bottom: 0;
          left: 67px;
        }

        &--third-item {
          width: 207px;
          height: 207px;
          box-shadow: 0 -4px 80px rgba(0, 0, 0, 0.25);
          border-radius: 50%;
          position: absolute;
          bottom: 0;
          left: 125px;
          padding-top: 1rem;
          opacity: 0.75;
        }

        &--third-item-border {
          width: 207px;
          opacity: 0.75;
          height: 207px;
          border-radius: 50%;
          position: absolute;
          bottom: 0;
          left: 125px;
          padding-top: 1rem;
        }

        &__title {
          font-size: 24px;
          color: #fff;
          text-transform: uppercase;
          font-weight: bold;
          margin-top: 25px;
        }

        &__description {
          font-size: 0.75rem;
          color: #fff;
          font-weight: normal;
        }
      }
    }
  }

  .problem-detail {
    position: absolute;
    left: 763px;
    display: flex;

    &--line1 {
      top: 233px;
    }

    &--line2 {
      top: 370px;
    }

    &--line3 {
      top: 507px;
    }

    &__icon {
      width: 64px;
      height: 64px;
      border-radius: 100%;
      background: #edeff3;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 16px;
    }

    &__content {
      width: 320px;
      padding-top: 16px;

      .title {
        text-align: left;
      }

      .content {
        text-align: left;
      }
    }
  }

  .problem-line {
    position: absolute;
    left: 586;
    width: 161px;
    border-bottom: 1px dashed #535353;

    &--line1 {
      top: 264px;
    }

    &--line2 {
      top: 401px;
    }

    &--line3 {
      top: 551px;
    }
  }

  .solution-content {
    position: absolute;
    top: 151px;
    width: 553px;
    left: 64px;

    .solution-list {
      margin-top: 40px;
      margin-left: 34px;

      .solution {
        margin-bottom: 32px;
        display: flex;
        align-items: center;
      }
    }
  }

  .product-content {
    position: absolute;
    left: 179px;

    &--line1 {
      top: 160px;
    }

    &--line2 {
      top: 399px;
    }

    height: 200px;
    width: 920px;
    display: flex;
    justify-content: space-between;

    .product-item {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 240px;
    }
  }

  .business-model-content {
    display: flex;
    justify-content: space-between;
    position: absolute;
    width: 1152px;
    height: 381px;
    top: 200px;

    .business-model {
      width: 319px;
      height: 381px;
      border: 1px solid #ddd;
      border-radius: 4px;

      .title {
        margin-top: 30px;
        margin-bottom: 30px;
      }

      .price-label {
        width: 100%;
        height: 48px;
        color: #fff;
        align-items: center;
        justify-content: center;
        display: flex;
      }

      .tier-list {
        display: flex;
        flex-direction: column;
        padding: 20px 30px;

        .tier {
          display: flex;
          align-items: center;
          margin-bottom: 11px;
        }
      }
    }
  }

  .market-strategy-content {
    display: flex;
    justify-content: space-between;
    position: absolute;
    width: 1152px;
    top: 200px;

    .market-strategy {
      display: flex;
      flex-direction: column;
      width: 337px;

      &__header {
        height: 115px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin-bottom: 35px;
      }

      .line {
        width: 128px;
        height: 1px;
        background-color: #000;
      }
    }
  }

  .competition-content {
    .line1 {
      position: absolute;
      top: 421px;
      left: 263px;
      width: 764px;
      height: 1px;
      background-color: #c4c4c4;
    }

    .line2 {
      position: absolute;
      top: 262px;
      left: 640px;
      width: 1px;
      height: 300px;
      background-color: #c4c4c4;
    }

    .top {
      position: absolute;
      top: 208px;
      left: 263px;
      width: 764px;
    }

    .bottom {
      position: absolute;
      top: 592px;
      left: 263px;
      width: 764px;
    }

    .right {
      position: absolute;
      top: 409px;
      right: 63px;
      width: 160px;
    }

    .left {
      position: absolute;
      top: 409px;
      left: 63px;
      width: 160px;
    }

    .img1 {
      position: absolute;
      width: 171px;
      height: 68px;
      top: 292px;
      left: 350px;
    }

    .img2 {
      position: absolute;
      width: 171px;
      height: 68px;
      top: 292px;
      right: 350px;
    }

    .img3 {
      position: absolute;
      width: 171px;
      height: 68px;
      top: 464px;
      left: 350px;
    }

    .img4 {
      position: absolute;
      width: 171px;
      height: 68px;
      top: 464px;
      right: 350px;
    }
  }

  .team-content {
    width: 880px;
    position: absolute;
    top: 187px;
    left: 200px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    .team {
      width: 390px;
      display: flex;
      margin-bottom: 50px;

      &__detail {
        margin-left: 16px;
        display: flex;
        flex-direction: column;
      }

      .position {
        font-size: 14px;
      }
    }
  }

  .traction-box {
    width: 876px;
    height: 150px;
    position: absolute;
    top: 135px;
    left: 218px;
    border: 2px solid #959aa2;
    border-radius: 4px;
    display: flex;
    justify-content: space-around;
    align-items: center;

    &__item {
      width: 170px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;

      &__title {
        font-size: 18px;
        line-height: 32px;
        font-weight: 700;
        color: #333;
      }

      &__content {
        font-size: 42px;
        font-weight: 700;
        color: #333;
        line-height: 50px;
      }
    }
  }

  .traction-sidebar {
    width: 400px;
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 355px;
    left: 790px;

    &__item {
      display: flex;
      align-items: center;
      margin-bottom: 35px;

      &__value {
        width: 110px;
        margin-right: 30px;
        font-size: 32px;
        line-height: 32px;
        font-weight: 700;
        color: #000;
      }

      &__title {
        font-size: 18px;
        font-weight: 700;
        color: #000;
      }
    }
  }

  .traction-chart {
    position: absolute;
    height: 250px;
    width: 600px;
    top: 367px;
    left: 130px;
  }

  .the-ask-box {
    width: 251px;
    height: 91px;
    border: 3px solid #2386c7;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 36px;
    font-weight: 700;
    color: #000;
    position: absolute;
    top: 343px;
    left: 502px;
  }

  .ask-box {
    display: flex;
    justify-content: center;
    flex-direction: column;
    position: absolute;
    top: 260px;
    width: 210px;
    min-height: 250px;

    &--left {
      left: 180px;
    }

    &--right {
      right: 180px;
    }

    &__item {
      display: flex;
      margin-top: 10px;

      &__value {
        font-size: 18px;
        font-weight: 700;
        width: 50px;
        margin-right: 12px;
      }

      &__title {
        color: #000;
        font-weight: 600;
        font-size: 18px;
      }
    }
  }

  .ask_line {
    position: absolute;
    width: 87px;
    height: 1px;
    top: 389px;

    &--left {
      left: 403px;
    }

    &--right {
      right: 403px;
    }
  }

  .vision_content {
    padding-top: 60px;
    margin-bottom: -60px;

    p {
      font-size: 36px;
      margin-bottom: 60px;

      strong {
        font-weight: 700;
      }
    }
  }

  .metric-content {
    position: absolute;
    height: 400px;
    top: 200px;
    left: 120px;
    width: 1028px;
  }

  .solution-img {
    position: absolute;
    width: 526px;
    height: 463px;
    top: 151px;
    right: 64px;
  }

  h2.title {
    font-size: 32px;
    line-height: 32px;
    color: #333;
    font-weight: 700;
    font-family: 'Kazimir Text', sans-serif;
    text-align: left;

    &--center {
      text-align: center;
    }
  }

  h3.title {
    font-size: 24px;
    line-height: 34px;
    color: #333;
    font-weight: 700;
    font-family: 'Kazimir Text', sans-serif;
    text-align: left;

    &--center {
      text-align: center;
    }
  }

  h5.title {
    font-size: 18px;
    line-height: 22px;
    color: #333;
    font-weight: 700;
    text-align: left;

    &--center {
      text-align: center;
    }

    &--white {
      color: #fff;
    }
  }

  p.content {
    font-size: 14px;
    color: #6d6d6f;
    text-align: left;

    &--only-center {
      text-align: center;
    }

    &--center {
      text-align: center;
      padding-left: 200px;
      padding-right: 200px;
    }
  }

  .description {
    font-size: 14px;
    line-height: 19px;
    color: #6d6d6f;

    &--half {
      width: 50%;
    }
  }
}
