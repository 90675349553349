.pitch-deck-builder-wrapper {
  padding: 1rem;

  &__admin {
    padding: 0;
  }

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    &__title {
      font-size: 1.5rem;
      font-weight: 900;
      color: #333;
      font-family: 'Kazimir Text', sans-serif;
    }

    &__preview {
      display: flex;
      align-items: center;
      justify-content: center;

      .create-button {
        background: none;
        font-weight: 700;
        background-color: #f36328 !important;
        height: 2.813rem;
        width: 15rem;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 1rem;
        color: #fff;
        border-radius: 10rem;
        border: none !important;

        img {
          margin-right: 13px;
        }
      }
    }
  }

  .content {
    margin-top: 1rem;
    border-radius: 0.75rem;
    border: 1px solid rgba(196, 196, 196, 0.5);
    background-color: #fff;
    padding: 1.875rem 1.875rem;
    min-height: 770px;
    width: 72rem;
    display: block;

    &__title {
      color: #46608a;
      font-size: 1rem;
      font-weight: 700;
    }

    &__body {
      color: #6d6d6f;
      font-size: 0.875rem;
      font-weight: 400;
      height: 650px;

      &--center {
        display: flex;
        justify-content: center;
        align-items: center;
      }

      &__box {
        color: #333;
        display: flex;
        flex-direction: column;
        align-items: center;

        h2 {
          font-size: 24px;
          font-weight: 900;
          font-family: 'Kazimir Text', sans-serif;
        }

        span {
          font-size: 14px;
          line-height: 17px;
        }
      }

      &__content {
        display: flex;
        margin-left: -20px;
        margin-right: -20px;
        flex-wrap: wrap;

        .pitch_deck_item {
          position: relative;
          margin: 20px;
          width: 292px;
          height: 200px;
          padding: 8px 8px 16px 8px;
          display: flex;
          flex-direction: column;
          border: 2px solid $color-gray;
          border-radius: 8px;

          &__more {
            position: absolute;
            top: -142px;
            right: 0;
            width: 32px;
            height: 32px;
            border-radius: 100%;
            background-color: #fff !important;
            box-shadow: 0 2px 2px rgba(0, 0, 0, 0.15);
            display: flex;
            justify-content: center;
            align-items: center;
            border: none !important;
            padding: 0;
            padding-left: 13px;

            .more-img {
              width: 3.2px;
            }
          }

          a {
            padding-left: 8px;
            font-size: 14px;
            line-height: 14px;
            font-weight: 700;
            color: #333;
            padding-top: 16px;
          }

          img {
            border-radius: 8px;
          }
        }
      }
    }
  }
}
