.admin-dashboard-header {
  padding: 0;

  .search-wrapper {
    margin: 1.25rem 3.25rem;
    margin-right: auto;
  }

  .admin-text {
    color: $color-gray2;
    font-size: 1rem;
    font-weight: bold;
  }

  .user-avatar {
    object-fit: cover;
  }

  .account-dropdown {
    .dropdown-toggle::after {
      display: none !important;
    }
  }
}
