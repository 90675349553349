.dashboard-my-favorite-wrapper {
  background-color: rgba(255, 255, 255, 0.5) !important;
  border: none !important;
  border-radius: 0.75rem !important;
  margin-top: 0.625rem;
  padding: 1.25rem;
  position: relative;

  .dropdown {
    position: absolute;
    right: 1.25rem;

    button {
      border: none !important;
      background: none !important;
      padding: 0 !important;
      color: #282828 !important;

      &::after {
        display: none;
      }
    }

    img {
      width: 0.25rem;
      opacity: 0.5;
    }
  }

  .card-body {
    display: flex;
    justify-content: space-between;

    .title {
      width: 60%;
      font-family: 'Kazimir Text', sans-serif;
      font-weight: 900;
      font-size: 1.125rem;
      margin-bottom: 0.5rem;
      color: #333;
    }

    .view-button {
      margin-top: 40px;
      color: black;
      opacity: 0.6;
      border: 1px solid #ccc;
    }

    .date {
      color: #6d6d6f;
      font-size: 0.875rem;
    }
  }
}
