.tag-normal-form {
  .react-tag-input {
    box-sizing: border-box;
    position: relative;
    width: 100%;
    height: auto;
    overflow-y: auto;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    background: #fff;
    min-height: 2.875rem;
    border: 0.125rem solid #e2e2e2;
    border-radius: 1.5625rem;
    padding: 0.4rem 0.75rem;
    font-size: 1rem;
    color: #333;

    * {
      box-sizing: border-box;
    }

    .react-tag-input__input {
      width: auto;
      flex-grow: 1;
      height: 1.875em;
      margin-top: 0.25rem;
      font-size: 1em;
      line-height: 1;
      background: transparent;
      color: #333;
      border: none;
      border-radius: 3px;
      outline: 0;
      box-shadow: none;
      -webkit-appearance: none;

      &::placeholder {
        color: rgb(128, 128, 128);
      }

      &:focus {
        border: none;
      }
    }

    .react-tag-input__tag {
      position: relative;
      display: flex;
      align-items: center;
      background-color: #e9ecf1 !important;
      border-radius: 1rem !important;
      color: #46608a !important;
      font-weight: normal !important;
      margin-right: 0.5rem !important;
      padding: 0 0.5rem;
    }

    .react-tag-input__tag__content {
      outline: 0;
      border: none;
      white-space: nowrap;
      padding: 0 0.125em;
      font-weight: 600;
      font-size: 1rem;
    }

    .react-tag-input__tag__remove {
      position: relative;
      height: 2em;
      width: 2em;
      font-size: 1rem;
      font-size: 0.85em;
      cursor: pointer;
      background: none;
      border-top-right-radius: 3px;
      border-bottom-right-radius: 3px;

      &::before {
        position: absolute;
        top: 50%;
        left: 50%;
        content: " ";
        height: 0.9em;
        width: 0.15em;
        background-color: #46608a;
        transform: translateX(-50%) translateY(-50%) rotate(45deg);
      }

      &::after {
        position: absolute;
        top: 50%;
        left: 50%;
        content: " ";
        height: 0.9em;
        width: 0.15em;
        background-color: #46608a;
        transform: translateX(-50%) translateY(-50%) rotate(-45deg);
      }

      .react-tag-input__tag__remove-readonly {
        width: 0;

        &::before {
          content: "";
          width: 0;
        }

        &::after {
          content: "";
          width: 0;
        }
      }
    }
  }
}
