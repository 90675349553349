.dashboard-personal-crm-reminder {
  background-color: $color-gray !important;
  border-radius: 0.75rem !important;
  border: none !important;
  overflow: hidden;

  .card-body {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 !important;

    .bar {
      width: 0.5rem;
    }

    .color-red {
      background-color: $color-red;
      color: $color-red;
      border-color: $color-red;
    }

    .color-orange {
      background-color: $color-orange;
      color: $color-orange;
      border-color: $color-orange;
    }

    .reminder-now {
      font-size: 0.75rem;
      background: none;
    }

    .reminder-title {
      font-family: 'Kazimir Text', sans-serif;
      font-weight: 900;
      font-size: 0.9375rem;
    }

    .reminder-date {
      font-size: 0.875rem;
      color: rgba(51, 51, 51, 0.6);
    }

    .details-btn,
    .details-btn:hover {
      background: none;
      border-radius: 2rem;
      padding: 0.2rem 0.8rem;
      font-size: 0.875rem;
      margin-right: 0.25rem;
    }
  }
}
