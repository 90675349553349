.settings-layout-wrapper {
  display: flex;
  position: relative;

  .decor-left {
    position: absolute;
    bottom: -10.625rem;
    left: 0;
    z-index: -1;
  }

  .decor-right {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 2;
  }

  .sidebar-wrapper {
    flex: 0 0 25.8125rem;
  }

  .content-wrapper {
    flex: 1;
    margin: 5rem 7.5rem 6.25rem 0;

    .card {
      border: 1px solid rgba(196, 196, 196, 0.5);
      border-radius: 0.75rem;
      position: relative;
      min-height: 49.375rem;

      .content-decor {
        position: absolute;
        top: -2rem;
        left: -3.4375rem;
      }

      .card-body {
        padding: 0;
        z-index: 2;
      }
    }
  }
}
