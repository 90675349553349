.admin-dashboard-new-category {
  background-color: #f8f9fa;
  padding: 3.3125rem;
  padding-bottom: 4.6875rem;

  .card {
    border-color: rgba(196, 196, 196, 0.5) !important;
    border-radius: 0.75rem !important;
    padding: 0;

    .card-body {
      padding: 1.875rem 2.1875rem;

      .form-field {
        font-size: 1.125rem;
      }

      .form-field-desc {
        color: $color-gray3;
        font-size: 0.875rem;
        font-style: italic;
      }

      .category-color-text {
        font-size: 1.125rem;
        font-weight: 600;
        color: #333;
      }

      .color-wrapper {
        width: 11.25rem;
        border: 0.125rem solid #e2e2e2;
        border-radius: 0.625rem;
        font-size: 1.125rem;
        color: #333;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0.4rem;
        padding-left: 0.875rem;
        padding-right: 0.625rem;
        margin-left: 5rem;

        .color-btn {
          width: 1.75rem;
          height: 1.75rem;
          border-radius: 0.25rem;
          border: none;
        }
      }

      .color-picker-wrapper {
        position: absolute;
        right: -3.75rem;
        top: 3.5rem;
      }
    }
  }
}
