.startup-notes-wrapper {
  padding: 1rem;

  .header {
    &__title {
      color: #282828;
      font-size: 1.25rem;
      font-family: 'Kazimir Text', sans-serif;
      font-weight: bold;
    }

    &__description {
      color: #282828;
      opacity: 0.8;
      font-size: 0.875rem;
    }

    .add-button {
      color: #46608a;
      font-size: 1rem;
      font-weight: bold;
      padding: 0.5rem 1rem;
      background: unset;
      border: 1px solid #46608a;
      border-radius: 6.25rem;

      .image {
        margin-right: 0.5rem;
      }
    }
  }

  .note-content-wrapper {
    background: #fff;
    box-shadow: 4px 13px 50px rgba(0, 0, 0, 0.1);
    border-radius: 0.5rem;
    padding: 2rem;
    height: 100%;
  }

  .note-empty-date {
    margin: 4rem auto;
    width: 27rem;
    height: 6rem;
    background: rgba(70, 96, 138, 0.08);
    border-radius: 0.75rem;
    cursor: pointer;

    .add-text {
      color: #46608a;
      font-weight: bold;
      font-size: 1.125rem;
    }
  }

  .note_content {
    height: 50rem;

    .view-more-option {
      color: #46608a;
      font-size: 0.875rem;
      font-weight: bold;

      &:hover {
        cursor: pointer;
      }
    }

    .note-item-wrapper {
      height: 100%;
      overflow-y: auto;

      &::-webkit-scrollbar {
        width: 3px;
      }

      &::-webkit-scrollbar-thumb {
        background: #888;
        border-radius: 5px;
      }

      &::-webkit-scrollbar-thumb:hover {
        background: #555;
      }

      .note-item {
        position: relative;
        width: 97%;

        &__date {
          color: rgba(40, 40, 40, 0.5);
          font-size: 0.875rem;
        }

        &__title {
          color: #282828;
          font-size: 1.125rem;
          font-weight: bold;
        }

        &__more {
          position: absolute;
          right: -1rem;
          top: 0;
        }

        &__content {
          color: rgba(40, 40, 40, 0.5);
          font-size: 1rem;
        }

        &__tag {
          display: flex;
          align-items: center;
          justify-content: center;
          color: white;
          font-size: 0.875rem;
          font-weight: 600;
          border-radius: 1.25rem;
          height: 1.875rem;
          padding-left: 1rem;
          padding-right: 1rem;
        }

        &__attachment {
          color: rgba(40, 40, 40, 0.3);
          font-size: 0.875rem;
        }

        &__avatars {
          display: inline-flex;
          flex-direction: row-reverse;

          .note-avatar:not(:last-child) {
            margin-left: -0.75rem;
          }
        }
      }
    }
  }
}
