.admin-dashboard-search-result {
  background-color: rgba(237, 239, 243, 0.4);
  padding: 1.875rem 3.25rem 5rem 3.25rem;

  .title {
    font-size: 1.25rem !important;
    font-weight: normal !important;
    letter-spacing: 2px !important;

    b {
      letter-spacing: normal;
      text-transform: uppercase;
    }
  }

  .loading-wrapper {
    margin: 20rem 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .section-title {
    color: $color-gray2;
    font-size: 1rem;
    font-weight: bold;
    letter-spacing: 2px;
    text-transform: uppercase;
  }

  .card {
    border-radius: 0.75rem;

    .card-body {
      padding: 0;
    }
  }

  .read-full {
    color: $color-blue !important;
    font-size: 0.75rem;
    cursor: pointer;
    font-weight: 300;
  }
}
