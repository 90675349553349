.personal-crm-reminder-widget {
  height: 100%;
  background-color: rgba(243, 99, 40, 0.08);
  border-radius: 0.75rem;
  border: none;
  position: relative;

  &__des {
    font-size: 0.875rem;
    color: #6d6d6f;
  }

  &__empty-data {
    height: 100%;
  }

  &__add-reminder-btn {
    background: rgba(243, 99, 40, 0.12);
    border: none;
    border-radius: 0.75rem;
    height: 3.25rem;
    color: #f36328;
    font-size: 0.875rem;
    font-weight: bold;
    margin-bottom: 10rem;

    &:hover {
      background: rgba(243, 99, 40, 0.1);
    }
  }

  .card-title {
    font-family: 'Kazimir Text', sans-serif;
    font-weight: 900;
    font-size: 1.5625rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0;
  }

  .card-body {
    padding: 0;
    display: flex;
    flex-direction: column;

    .reminders-wrapper {
      overflow: auto;
      min-height: 12.5rem;

      .reminder-wrapper {
        background: rgba(255, 255, 255, 0.7);
        border-radius: 0.75rem;
        display: flex;
        align-items: center;
        border-top-left-radius: 0.25rem;
        border-bottom-left-radius: 0.25rem;

        .reminder-time {
          font-size: 0.75rem;
        }

        .reminder-title {
          font-family: 'Kazimir Text', sans-serif;
          font-size: 0.875rem;
          font-weight: 900;
        }

        .reminder-tag {
          font-size: 0.75rem;
        }

        .reminder-avatars {
          display: inline-flex;
          flex-direction: row-reverse;

          .reminder-avatar:not(:last-child) {
            margin-left: -0.4375rem;
          }

          .reminder-avatar-more {
            width: 1.375rem;
            height: 1.375rem;
            margin-left: -0.4375rem;
            border-radius: 0.65625rem;
            background-color: $color-red;
            font-size: 0.5625rem;
            font-weight: bold;
            color: white;
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }
      }
    }
  }
}
