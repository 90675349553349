.admin-dashboard-sidebar {
  background-color: $color-blue;
  padding: 2.5rem;
  height: 100%;

  .nav-link {
    color: rgba(255, 255, 255, 0.4);
    font-size: 1rem;
    font-weight: bold;
    padding: 0.8rem 0;

    p {
      margin-top: 0.2rem;
    }
  }
}
