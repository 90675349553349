.downloadables-page-wrapper {
  .content {
    padding: 1.25rem 6.25rem 3.375rem 6.25rem;
    position: relative;

    .banner-section {
      position: relative;
      width: 100%;
      margin-top: 1.875rem;
      height: 200px;
      border-radius: 0.875rem;
      background-color: #f6f7f9;

      img {
        height: 222px;
        position: absolute;
        top: -21px;
        right: 0;
      }

      .banner-content {
        position: absolute;
        top: 59%;
        transform: translateY(-55%);
        left: 2.5rem;

        .label {
          color: $color-red;
          font-size: 0.875rem;
          font-weight: bold;
        }

        .title {
          color: #333;
          font-family: 'Kazimir Text', sans-serif;
          font-weight: 900;
          font-size: 2.5rem;
          line-height: 3rem;
        }

        .desc {
          color: #535353;
          font-size: 0.875rem;
        }
      }
    }

    .category-section {
      font-family: 'Kazimir Text', sans-serif;
      font-weight: 900;
      font-size: 1.5rem;
      margin-top: 2.5rem;
      width: 100%;

      .category-item {
        border: unset;
        border-radius: 0.625rem;
        color: white;
        font-family: 'Kazimir Text', sans-serif;
        font-weight: 900;
        font-size: 1.125rem;
        width: 184px;
        height: 6.25rem;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;

        &.light {
          color: #7a5c58;
        }
      }
    }

    .search-section {
      margin-top: 3.75rem;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .search-wrapper {
        background-color: white;
        border: 2px solid #e2e2e2;
        box-sizing: border-box;
        border-radius: 6.25rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 21.875rem;
        height: 2.8125rem;

        .search-input {
          font-size: 1rem;
          border: none;
          background: none;
          margin-left: 0.75rem;
        }

        .lookup-btn {
          background-color: $color-red;
          border-radius: 1.125rem;
          border: none;
          font-size: 0.875rem;
          font-weight: bold;
          min-width: 6.75rem;
          height: 2.25rem;
          margin-right: 0.1rem;
          margin-bottom: 0.1rem;
        }
      }

      .sort-wrapper {
        display: flex;
        align-items: center;

        span {
          color: rgba(40, 40, 40, 0.6);
          font-size: 0.875rem;
          line-height: 0.875rem;

          .sort-type {
            font-weight: bold;
            color: $color-light-blue;
            background: none;
            border: none;
            padding: 0;
          }
        }
      }
    }

    .downloadable-section {
      margin-top: 1.25rem;

      .carousel-items-wrapper {
        overflow-x: unset;
      }

      .downloadables-wrapper {
        margin: 0 -2rem;
      }

      .downloadable-wrapper {
        padding: 0 2rem;
        margin-top: 1rem;

        .downloadable-content {
          padding: 0.75rem;
          position: relative;

          .downloadable-img {
            object-fit: cover;
          }

          .downloadable-title {
            color: rgba(40, 40, 40, 0.8);
            font-family: 'Kazimir Text', sans-serif;
            font-weight: 900;
            font-size: 1.375rem;
            width: 60%;
          }

          .downloadable-download {
            text-align: center;
            background-color: unset;
            border: none;

            p {
              color: $color-blue;
              font-size: 0.75rem;
              font-weight: bold;
            }
          }

          .new-mark {
            background: #7a5c58;
            border-radius: 0.125rem;
            position: absolute;
            top: 0.875rem;
            left: -0.75rem;
            color: white;
            font-weight: bold;
          }

          .downloadable-favorite {
            position: absolute;
            top: 1.25rem;
            right: 1.25rem;
            display: none;
          }
        }

        .downloadable-content:hover {
          cursor: pointer;
          border-radius: 0.5rem;
          box-shadow: 0 20px 40px rgba(0, 0, 0, 0.12);

          .downloadable-favorite {
            display: block;
          }
        }
      }

      .downloadable-section-footer {
        display: flex;
        justify-content: center;
        margin-top: 5rem;
        font-size: 1.125rem;
      }
    }

    .decor-left {
      position: absolute;
      left: 0;
      bottom: 0;
      margin-bottom: -3.4rem;
      z-index: -1;
    }

    .decor-right {
      position: absolute;
      right: 0;
      bottom: 0;
      margin-bottom: -3rem;
      z-index: -1;
    }
  }
}
