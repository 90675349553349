.sidebar-timeline-wrapper {
  box-sizing: border-box;
  // width: 250px;
  width: 300px;
  position: absolute;
  top: 60px;
  left: 0;
  background: #fff;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.12);
  display: flex;
  flex-direction: column;
  z-index: 100;
  height: calc(100% - 60px);

  .header {
    width: 100%;
    height: 49px;
    background-color: #f8fafb;
    padding: 17px 20px;
    font-size: 12px;
    color: $color-gray2;
    font-weight: 600;
    line-height: 15px;
    margin-bottom: 53px;
  }

  .add-sprint {
    &__button {
      // margin-top: 20px;
      // margin-left: 20px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 35px;
      width: 110px;
      color: $color-blue !important;
      font-size: 14px;
      line-height: 21px;
      padding: 2px;
      padding-left: 12px;
      border: none !important;
      font-weight: 600;
      background-color: unset !important;
    }

    &__img {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 24px;
      height: 24px;
      border-radius: 100%;
      background-color: $color-blue;
    }
  }

  .sprint {
    .sprint-header {
      width: 100%;
      box-sizing: border-box;
      height: 54px;
      // height: 75px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-left: 17px;
      padding-right: 17px;
      border-bottom: 1px solid $color-gray;

      &--right {
        display: flex;
        align-items: center;

        .carret {
          margin-right: 9px;
        }

        .carret-button {
          padding: 0;
          border: unset;
          background-color: unset;
        }

        .sprint-name {
          color: #333;
          font-weight: 600;
          font-size: 14px;
          line-height: 21px;
        }

        .sprint-name-input {
          color: #333;
          font-weight: 600;
          font-size: 14px;
          line-height: 21px;
          border: 1px solid $color-gray;
          border-radius: 4px;
        }
      }

      &--left {
        .dropdown-menu {
          box-shadow: 0 4px 20px rgba(0, 0, 0, 0.12);
          padding: 17px;
          border: none;
          border-radius: 8px;
          inset: 0 0 auto auto !important;
        }

        .toggle-more {
          padding: 0;

          &:focus {
            background-color: unset;
            border-color: unset;
          }
        }

        .dropdown-item {
          padding: 0;
          margin-bottom: 9px;
          font-size: 16px;
          color: #000;
          line-height: 19px;
          font-weight: 600;
          background-color: #fff;
        }

        .item-delete {
          color: $color-red;
          margin-bottom: 0;
        }
      }
    }

    .sprint-content {
      width: 100%;
      padding: 17px;
      padding-bottom: 10px;
      padding-top: 10px;
      display: flex;
      flex-direction: column;
      border-bottom: 1px solid $color-gray;

      .task {
        margin-top: 7px;
        margin-bottom: 7px;
        height: 14px;
        // height: 24px;
        display: flex;
        align-items: center;

        &__dot {
          width: 4px;
          height: 4px;
          border-radius: 1px;
          margin-right: 5px;

          &--now {
            background-color: $color-blue;
          }

          &--next {
            background-color: $color-tangerine;
          }

          &--later {
            background-color: $color-red;
          }

          &--done {
            background-color: $color-green2;
          }
        }

        &__name {
          font-size: 12px;
          font-weight: 600;
          color: #6d6d6f;
          line-height: 14px;

          &--done {
            text-decoration-line: line-through;
            opacity: 0.5;
          }
        }

        &__button {
          background: none;
          border: none;
          padding: 0;
          margin: 0;
        }
      }
    }
  }

  .add {
    width: 100%;

    button {
      height: 17px;
      display: flex;
      align-items: center;
      color: $color-red !important;
      font-size: 14px;
      font-weight: 600;
      line-height: 17px;
      background-color: unset !important;
      border: unset;
      padding: 0;

      img {
        margin-right: 14px;
      }
    }
  }
}
