.users-page-wrapper {
  padding: 3.875rem 2.5rem 2.5rem 2.5rem;

  .title {
    font-weight: bold;
    font-size: 1.875rem;
  }

  .active-users {
    font-weight: bold;
    letter-spacing: 2px;
    text-transform: uppercase;
    color: $color-gray2;
  }

  .user-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #e2e2e2;

    .user-thumbnail {
      width: 3.125rem;
      height: 3.125rem;
      border-radius: 9999px;
      letter-spacing: 2px;
      text-transform: uppercase;
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: bold;
    }

    .user-name {
      font-weight: bold;
    }

    .user-email {
      color: $color-gray2;
    }
  }

  .user-wrapper:last-child {
    border-bottom: none;
  }

  .invite-users {
    background-color: rgba(70, 96, 138, 0.05);
    border-radius: 0.75rem;

    .invite-title {
      font-weight: bold;
      letter-spacing: 2px;
      text-transform: uppercase;
      color: $color-gray2;
    }

    .invite-desc {
      color: $color-gray2;
    }

    .invite-users-wrapper {
      background-color: white;
      border: 2px solid #e2e2e2;
      border-radius: 9999px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      height: 2.8125rem;

      .email-input {
        border: none;
        background: none;
        font-size: 1rem;
      }

      .send-btn {
        background-color: $color-orange;
        border-radius: 9999px;
        border: none;
        font-size: 1.125rem;
        font-weight: bold;
        min-width: 6.375rem;
        height: 2.0626rem;
        margin-right: 0.3rem;
        line-height: 0;
      }
    }
  }
}
