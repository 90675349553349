@font-face {
  font-family: 'Kazimir Text';
  font-weight: 900;
  src: local('Kazimir Text'), url(../../font/KazimirText/KazimirText-Black.ttf) format('truetype');
}

@font-face {
  font-family: 'Kazimir Text';
  font-weight: 400;
  src: local('Kazimir Text'), url(../../font/KazimirText/KazimirText-Light.ttf) format('truetype');
}

@font-face {
  font-family: 'Kazimir Text';
  font-weight: 700;
  src: local('Kazimir Text'), url(../../font/KazimirText/KazimirText-Medium.ttf) format('truetype');
}

@font-face {
  font-family: 'Kazimir Text';
  font-weight: bold;
  src: local('Kazimir Text'), url(../../font/KazimirText/KazimirText-Bold.ttf) format('truetype');
}

@font-face {
  font-family: 'Proxima Nova';
  src: local('Proxima Nova'), url(../../font/ProximaNova/ProximaNova-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'Proxima Nova';
  font-weight: 600;
  src: local('Proxima Nova'), url(../../font/ProximaNova/ProximaNova-Medium.ttf) format('truetype');
}

@font-face {
  font-family: 'Proxima Nova';
  font-weight: 300;
  src: local('Proxima Nova'), url(../../font/ProximaNova/ProximaNova-Light.ttf) format('truetype');
}

@font-face {
  font-family: 'Proxima Nova';
  font-style: italic;
  src: local('Proxima Nova'), url(../../font/ProximaNova/ProximaNova-Regular-Italic.ttf) format('truetype');
}

@font-face {
  font-family: 'Proxima Nova';
  font-weight: bold;
  src: local('Proxima Nova'), url(../../font/ProximaNova/ProximaNova-Bold.ttf) format('truetype');
}

// @font-face {
//   font-family: 'DMSerifDisplay';
//   font-style: italic;
//   src: local('DMSerifDisplay'), url(../../font/DMSerifDisplay/DMSerifDisplay-Italic.ttf) format('truetype');
// }

// @font-face {
//   font-family: 'DMSerifDisplay';
//   font-weight: 400;
//   src: local('DMSerifDisplay'), url(../../font/DMSerifDisplay/DMSerifDisplay-Regular.ttf) format('truetype');
// }
