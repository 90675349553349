.reminder-calendar-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;

  .month-selector {
    width: 10.5rem;
    background: none;
    border: none;
    cursor: pointer;
    color: rgba(122, 92, 88, 0.7);
    font-size: 0.75rem;
    font-weight: bold;
    letter-spacing: 0.125rem;
    text-transform: uppercase;
  }

  .weekday {
    color: rgba(122, 92, 88, 0.5);
    font-size: 0.75rem;
    text-transform: uppercase;
    flex: 1 0 0;
    text-align: center;
  }

  .prev-arrow {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
  }

  .next-arrow {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
  }

  .day-wrapper {
    flex: 1 0 0;
    display: flex;
    justify-content: center;
    align-items: center;

    .day {
      color: #7a5c58 !important;
      font-size: 0.875rem;
      font-weight: bold;
      width: 1.75rem;
      height: 1.75rem;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 0.875rem;
    }
  }
}
