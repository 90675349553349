.admin-dashboard-plans {
  background-color: rgba(237, 239, 243, 0.4);
  padding: 3.3125rem;
  padding-bottom: 4.6875rem;

  .plans-section {
    .card {
      border: 1px solid rgba(196, 196, 196, 0.5);
      border-radius: 0.75rem;
      overflow: hidden;
      margin-bottom: 20px;

      .card-body {
        padding: 0;

        form {
          padding: 34px;

          .form-group {
            .form-label {
              font-style: normal;
              font-weight: bold;
              font-size: 14px;
              line-height: 100%;
              color: #333;
            }
          }
        }
      }
    }
  }
}
