.founders-library-blog-widget {
  background-color: rgba(208, 166, 150, 0.2) !important;
  border: none !important;
  border-radius: 1.25rem !important;
  padding: 1.875rem;

  .card-title {
    color: #333;
    font-family: 'Kazimir Text', sans-serif;
    font-size: 1.75rem;
    font-weight: 900;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0;

    .visit-blog {
      font-weight: bold;
      font-size: 0.875rem;
      color: $color-blue;
      height: 1.5rem;
    }
  }

  .card-text {
    font-size: 0.875rem;
    color: rgba(40, 40, 40, 0.6);
  }

  .card-body {
    padding: 0;
    margin-top: 2.5rem;

    .blog-img {
      width: 100%;
      height: 9.375rem;
      object-fit: cover;
    }

    .blog-img-small {
      object-fit: cover;
    }

    .blog-title {
      color: rgba(40, 40, 40, 0.8);
      font-family: 'Kazimir Text', sans-serif;
      font-weight: 900;
      font-size: 1.25rem;
    }

    .blog-title-small {
      color: rgba(40, 40, 40, 0.8);
      font-family: 'Kazimir Text', sans-serif;
      font-weight: 900;
      font-size: 1rem;
    }

    .blog-date {
      color: rgba(40, 40, 40, 0.6);
      font-style: italic;
      font-size: 0.75rem;
    }

    .blog-desc {
      p,
      span,
      h1,
      h2,
      h3,
      h4,
      h5 {
        color: rgba(40, 40, 40, 0.6);
        font-size: 1rem;
      }
    }
  }
}
