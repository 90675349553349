.startup-document {
  &__item {
    border-radius: 0.75rem;
    height: 14.438rem;
    margin-bottom: 2.5rem;
  }

  &__item-date {
    font-style: normal;
    font-weight: normal;
    font-size: 0.75rem;
    line-height: 0.94;
    color: rgba(40, 40, 40, 0.5);
  }

  &__item-title {
    font-style: normal;
    font-weight: 600;
    font-size: 1rem;
    line-height: 1.188rem;
    color: #282828;
  }

  &__item-des {
    font-style: normal;
    font-weight: normal;
    font-size: 75;
    line-height: 0.94;
    color: rgba(40, 40, 40, 0.5);
  }

  &__item-tag {
    padding: 0.1rem 0.3rem;
    border-radius: 0.25rem;
    font-size: 0.875rem;
  }
}

.blue-bg {
  background: rgba(46, 161, 187, 0.2);
  color: #4470c2;
}

.green-bg {
  background: rgba(199, 104, 95, 0.2);
  color: #74ab4f;
}
