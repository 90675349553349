.tooltip-wrapper {
  padding: 1.5rem;
  background-color: #fff;
  border: 1px solid #e2e2e2;
  border-radius: 0.5rem;
  width: 13rem;
  opacity: 1;
  z-index: 1;

  &__color-picker {
    position: absolute;

    &__right {
      right: -13.625rem;
      bottom: -6.25rem;
    }

    &__bottom {
      bottom: -6.25rem;
    }
  }

  .tooltip-title {
    color: #282828;
    font-size: 0.75rem;
    font-weight: 600;
    padding-bottom: 1rem;
  }

  .tooltip-content {
    color: #282828;
    font-size: 0.75rem;
    font-weight: 400;
  }
}
