.product-roadmap-wrapper {
  padding: 1rem;
  height: 95%;
  min-height: 800px;

  .export-button {
    color: #46608a;
    font-size: 1rem;
    font-weight: bold;
    padding: 0.5rem 1rem;
    background: rgba(70, 96, 138, 0.1);
    border: none;
    border-radius: 6.25rem;
  }

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    &__title {
      font-size: 1.5rem;
      font-weight: 900;
      color: #333;
      font-family: 'Kazimir Text', sans-serif;
    }

    .beta-tag {
      width: 33px;
      height: 14px;
      margin-left: 8px;
      background: #f09b00;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 3px 5px;
      border-radius: 30px;
      font-size: 8px;
      border: none;
      font-weight: 800;
    }
  }

  .header-content {
    display: flex;
    align-items: flex-start;
    height: 40px;
    margin-top: 20px;

    &__item {
      font-size: 14px;
      font-weight: 600;
      color: $color-gray2;
      line-height: 17px;
      text-transform: uppercase;
      margin-right: 20px;
      margin-left: 20px;
      padding: 0;
      border: none;
      background-color: unset !important;
      border-radius: unset;

      &:active {
        color: $color-red !important;
      }

      &--active {
        font-weight: 700;
        color: $color-red;
        padding-bottom: 20px;
        border-bottom: 3px solid $color-red;
      }
    }
  }

  .content {
    margin-top: 1rem;
    border-radius: 0.75rem;
    border: 1px solid rgba(196, 196, 196, 0.5);
    background-color: #fff;
    overflow-y: scroll;
    // height: 100%;
    position: relative;
  }

  .feature-header {
    width: 250px !important;
    height: 49px;
    padding: 17px 20px;
    font-size: 0.75rem;
    font-weight: 600;
    color: #959aa1;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.12);
  }

  .timeline-header {
    height: 102px;
  }

  .month-header {
    .month-header-item {
      line-height: 54px;
      text-align: center;
      cursor: pointer;

      div {
        text-transform: uppercase;
      }
    }
  }

  .timeline-wrapper {
    // width: 100%;
    width: 1157px !important;
  }

  .day-header {
    padding-top: 22px;
    background-color: #fff;

    .day-header-item {
      line-height: 30px;
      text-align: center;
      cursor: pointer;
      background-color: #fff;
      border-right: 1px solid #e8ecef;
    }
  }
}

.react-calendar-timeline {
  * {
    box-sizing: border-box;
  }

  .rct-outer {
    display: block;
    overflow: hidden;
    white-space: nowrap;
  }

  .rct-scroll {
    display: inline-block;
    white-space: normal;
    vertical-align: top;
    overflow-x: scroll;
    overflow-y: hidden;
    -ms-touch-action: none;
    touch-action: none;
  }

  .rct-item {
    &--now {
      background-color: #a2afc4 !important;
    }

    &--next {
      background-color: #f7cd7f !important;
    }

    &--later {
      background-color: #f9b193 !important;
    }

    &--done {
      background-color: #93d6af !important;
    }

    &--hide {
      background-color: #edeff3 !important;
    }

    &:hover {
      z-index: 88;
    }

    .rct-item-content {
      position: sticky;
      position: -webkit-sticky;
      left: 0;
      overflow: hidden;
      display: inline-block;
      border-radius: 2px;
      padding: 0 6px;
      height: 100%;
    }
  }

  .rct-sidebar {
    overflow: hidden;
    white-space: normal;
    display: inline-block;
    vertical-align: top;
    position: relative;
    box-sizing: border-box;
    border-right: 1px solid #bbb;

    &.rct-sidebar-right {
      border-right: 0;
      border-left: 1px solid #bbb;
    }

    .rct-sidebar-row {
      padding: 0 4px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      box-sizing: border-box;
      margin: 0;
      border-bottom: 1px solid #bbb;

      &.rct-sidebar-row-odd {
        background: rgba(0, 0, 0, 0.05);
      }

      &.rct-sidebar-row-even {
        background: transparent;
      }
    }
  }

  .rct-vertical-lines .rct-vl {
    position: absolute;
    // border-left: 1px solid #bbb;
    background-color: #fff;
    border-right: 1px solid #e8ecef;
    z-index: 30;

    &.rct-vl-first {
      border-left-width: 2px;
    }

    &.rct-day-6 {
      // background: rgba(250, 246, 225, 0.5);
    }

    &.rct-day-0 {
      // background: rgba(250, 246, 225, 0.5);
    }
  }

  .rct-horizontal-lines {
    -webkit-user-select: none;
    -moz-user-select: -moz-none;
    -ms-user-select: none;
    user-select: none;

    .rct-hl-even {
      // border-bottom: 1px solid #bbb;
      box-sizing: border-box;
      z-index: 40;
      background: transparent;
    }

    .rct-hl-odd {
      // border-bottom: 1px solid #bbb;
      box-sizing: border-box;
      z-index: 40;
      // background: rgba(0, 0, 0, 0.05);
    }
  }

  .rct-cursor-line {
    position: absolute;
    width: 2px;
    background: #2196f3;
    z-index: 51;
  }

  .rct-dateHeader {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    border-bottom: 1px solid #bbb;
    cursor: pointer;
    font-size: 14px;
    background-color: #f0f0f0;
    border-left: 2px solid #bbb;
  }

  .rct-dateHeader-primary {
    background-color: initial;
    border-left: 1px solid #bbb;
    border-right: 1px solid #bbb;
    color: #959aa1;
    height: 48px;
    font-size: 0.75rem;
    font-weight: 600;
  }

  .rct-header-root {
    background: #f8fafb;
    color: #959aa1;
  }

  .resize-right {
    width: 4px;
    height: 4px;
    border-radius: 100%;

    &--now {
      background-color: $color-blue;
    }

    &--next {
      background-color: $color-tangerine;
    }

    &--later {
      background-color: $color-red;
    }

    &--done {
      background-color: $color-green2;
    }
  }

  .resize-left {
    width: 4px;
    height: 4px;
    border-radius: 100%;

    &--now {
      background-color: $color-blue;
    }

    &--next {
      background-color: $color-tangerine;
    }

    &--later {
      background-color: $color-red;
    }

    &--done {
      background-color: $color-green2;
    }
  }

  .rct-item-handler-right {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    right: -3px !important;
  }

  .rct-item-handler-left {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    left: -3px !important;
  }
}
