.my-account-page-wrapper {
  padding: 2.8125rem 3.25rem 7.25rem 2.5rem;

  .title {
    font-weight: bold;
    font-size: 1.875rem;
  }

  .edit-btn {
    position: absolute;
    top: -0.3125rem;
    right: -0.6875rem;
    z-index: 2;
  }

  .form-group {
    .form-label {
      font-size: 0.875rem;
      font-weight: bold;
      letter-spacing: 2px;
      text-transform: uppercase;
      color: $color-gray2;
    }

    .form-control {
      height: 2.8125rem;
      border: 2px solid #e2e2e2;
      border-radius: 9999px;
      font-size: 1.125rem;
      font-weight: 600;
      color: $color-black;
    }
  }

  .select-box {
    position: relative;
    cursor: pointer;

    .type-dropdown {
      appearance: none;
      -moz-appearance: none;
      -webkit-appearance: none;
      cursor: pointer;
    }

    img {
      position: absolute;
      right: 1rem;
      top: 1.25rem;
    }
  }

  .change-password {
    color: $color-gray2;
    font-size: 1rem;
    font-weight: bold;
    letter-spacing: 2px;
    text-transform: uppercase;
  }

  .deactive-account-text {
    font-size: 0.75rem;
    color: $color-orange;

    a {
      font-size: 0.75rem;
      color: $color-orange;
      text-decoration: underline;
    }
  }

  .position-relative {
    .rounded-circle {
      object-fit: cover;
    }
  }

  .save-btn,
  .save-btn:active {
    width: 18.75rem;
    height: 2.8125rem;
    border: none;
    border-radius: 9999px;
    background-color: $color-blue !important;
    color: white;
    font-size: 1rem;
    font-weight: bold;
  }
}

.upload-photo-modal {
  .modal-body {
    padding: 2.5rem;
    position: relative;

    .decor {
      position: absolute;
      right: -3.625rem;
      bottom: -2rem;
    }

    .dropzone {
      background: rgba(226, 226, 226, 0.5);
      border: 1px dashed $color-gray2;
      border-radius: 0.3125rem;
      text-align: center;
      cursor: pointer;

      .upload-img {
        margin-top: 7.75rem;
      }

      .upload-title {
        font-size: 1.125rem;
        font-weight: bold;
      }

      .upload-desc {
        margin-bottom: 7.75rem;
        font-size: 0.875rem;
        color: #000;

        span {
          color: $color-orange;
        }
      }
    }
  }
}

.drop-photo-zone {
  .modal-content {
    min-height: 30rem;

    .modal-body {
      padding-bottom: 0 !important;
    }

    img {
      object-fit: cover;
    }

    .modal-footer {
      border-top: none;

      .tip {
        color: #6d6d6f;
        font-size: 12px;
        font-style: italic;
      }

      .btn {
        margin: 0.5rem 0 !important;
      }

      .crop-btn {
        border: none;
        background-color: #46608a !important;
        color: white;
        font-size: 1rem;
        font-weight: bold;
      }
    }
  }

  .reactEasyCrop_Container {
    position: absolute;
    top: 5%;
    left: 10%;
    right: 10%;
    width: 80%;
    height: 905;
  }
}
