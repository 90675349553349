.export-pdf-wrapper {
  .modal-lg {
    width: 80%;
    min-width: 75rem;

    .pdf-content {
      padding: 1rem;
      margin: 0 auto;
      overflow: auto;

      &__file {
        width: 65rem;
        min-width: 65rem;
      }
    }

    .banner {
      background-color: #f6edea;
      border-radius: 0.75rem;
      min-height: 11.25rem;
      margin-left: 0;
      margin-right: 0;

      p {
        color: #282828;
        font-weight: 600;
        font-size: 1.125rem;
      }

      .startup-name {
        color: #282828;
        font-size: 1.875rem;
        line-height: 2.2rem;
        font-weight: 800;
        font-family: 'Kazimir Text', sans-serif;

        img {
          object-fit: cover;
        }
      }

      .description {
        color: #959aa1;
        font-weight: 600;
        font-size: 1.125rem;
      }

      .startup-infor__founded {
        border-right: 1px solid #e2e2e2;
      }
    }

    .modal-body {
      .startup-infor.startup-infor-pdf {
        height: unset;
      }

      .title {
        font-style: normal;
        font-weight: bold;
        font-size: 1.125rem;
        line-height: 1.375rem;
        letter-spacing: 0.125rem;
        text-transform: uppercase;
        color: #282828;
      }

      .founder__headers {
        background-color: #f6f7f9;
        color: #46608a;
        font-size: 0.875rem;
        font-weight: 600;
      }

      .founder__content {
        .name {
          color: #282828;
          font-size: 1rem;
          opacity: 0.7;
        }

        .percent {
          color: #46608a;
          font-size: 1.25rem;
          font-weight: bold;
        }
      }
    }

    .footer {
      display: flex;
      justify-content: flex-end;
      margin: 1rem 5.75rem 4rem 5.75rem;

      button {
        color: #fff;
        font-size: 1rem;
        font-weight: bold;
        background-color: #f36328;
        border-radius: 2rem;
        width: 11.375rem;
        height: 2.813rem;
        display: flex;
        align-items: center;
        justify-content: center;
        border: none;
      }
    }
  }
}
