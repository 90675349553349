.personal-crm-task-widget {
  height: 100%;
  background-color: #fffbe9;
  border-radius: 0.75rem;
  border: none;
  position: relative;

  &__des {
    font-size: 0.875rem;
    color: #6d6d6f;
  }

  &__empty-data {
    height: 100%;
  }

  &__add-task-btn {
    background: rgba(240, 155, 0, 0.12);
    border: none;
    border-radius: 0.75rem;
    height: 3.25rem;
    color: #f09b00;
    font-size: 0.875rem;
    font-weight: bold;

    &:hover {
      background: rgba(240, 155, 0, 0.1);
    }
  }

  .card-title {
    font-family: 'Kazimir Text', sans-serif;
    font-weight: 900;
    font-size: 1.5625rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0;
  }

  .card-body {
    padding: 0;

    .task-wrapper {
      display: flex;

      &:first-child {
        margin-top: 0 !important;
      }

      .task-name {
        color: rgba(40, 40, 40);

        &:hover {
          cursor: pointer;
        }
      }
    }
  }
}
