.startup-infor {
  border-radius: 0.75rem;
  height: 58rem;

  .card-body {
    flex: none;

    img {
      // object-fit: cover;
    }

    .startup-stages {
      .startup-subheading {
        color: #6d6d6f;
        font-size: 14px;
        font-weight: 700;
        text-transform: uppercase;
      }

      .subheading-tag {
        color: #f36328;
        background-color: rgba(243, 99, 40, 0.08);
        font-weight: 600;
        border-radius: 30px;
      }

      .subheading-main-tags {
        font-size: 14px;
        text-align: center;
        color: #f36328;
        background-color: rgba(243, 99, 40, 0.08);
        font-weight: 600;
        border-radius: 30px;
      }
    }
  }

  &__name {
    color: #282828;
    font-size: 1.875rem;
    line-height: 2.2rem;
    font-weight: 800;
    font-family: 'Kazimir Text', sans-serif;
  }

  &__description {
    font-family: 'Proxima Nova', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 1.125rem;
    line-height: 1.375rem;
    align-items: center;
  }

  &__founded-employee {
    color: #959aa2;
    font-family: 'Proxima Nova', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 1.125rem;
    line-height: 1.375rem;
  }

  &__founded {
    border-right: 1px solid #e2e2e2;
  }

  &__line {
    opacity: 0.1;
    border: 1px solid #46608a;
  }

  &__title {
    font-family: 'Proxima Nova', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 0.875rem;
    line-height: 1rem;
    letter-spacing: 0.125;
    text-transform: uppercase;
    color: #959aa2;
  }

  &__details {
    font-size: 16px;
    font-weight: 700 !important;
    text-transform: uppercase;
  }
}
