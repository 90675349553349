.marketing-plan-wrapper {
  .marketing-plan-item {
    display: flex;
    align-items: center;
    margin-top: 1rem;

    .trash-button {
      width: 3.125rem;
      height: 3.125rem;
      border-radius: 100%;
      background-color: #f6f6f6;
      display: flex;
      justify-content: center;
      align-items: center;
      border: none;

      &:active {
        background-color: #f6f6f6;
      }
    }
  }

  .redirect-block {
    &__elevator_pitch {
      &:hover {
        cursor: pointer;
      }
    }
  }
}
