.personal-crm-activity-widget {
  box-shadow: 0 0.5rem 2.5rem rgba(0, 0, 0, 0.08);
  border-radius: 0.5rem;
  border: none !important;
  height: 100%;

  .card-title {
    color: #333;
    font-family: 'Kazimir Text', sans-serif;
    font-size: 1.125rem;
    font-weight: 900;
  }

  .card-body {
    padding: 0;

    .activity-wrapper {
      border-left: 1px solid rgba(70, 96, 138, 0.12);
      position: relative;

      &:last-child {
        border-left: none;
      }

      .dot {
        position: absolute;
        left: -0.25rem;
        top: 0;
        background-color: $color-blue;
        width: 0.5rem;
        height: 0.5rem;
        border-radius: 0.25rem;
      }

      .activity-date {
        font-size: 0.75rem;
        font-weight: bold;
      }

      .activity-time {
        font-size: 0.75rem;
        font-style: italic;
      }

      .activity-desc {
        font-size: 0.75rem;
        color: #6d6d6f;
      }

      .activity-creator {
        font-size: 0.75rem;
        color: $color-blue !important;
        font-weight: bold;
      }
    }
  }
}
