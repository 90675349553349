.crm-connect-sources-modal {
  .modal-header {
    padding-bottom: 0;
    border-bottom: unset;
  }

  .modal-body {
    padding: 0 2.5rem 1.875rem 2.5rem;
    text-align: center;

    .connect-title {
      font-size: 1.125rem;
      font-weight: 700;
      color: #333;
    }

    .connect-desc {
      font-size: 1rem;
      font-weight: 400;
      color: #333;
    }

    .source-wrapper {
      background-color: $color-light-gray2;
      border-radius: 0.5rem;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .source-title {
        color: #333;
        font-size: 0.875rem;
        font-weight: 600;
      }

      .source-imported {
        font-size: 0.75rem;
        font-weight: 600;
        font-style: italic;
      }

      .connect-status {
        font-size: 0.875rem;
        font-weight: 600;
      }

      .action-btn,
      .action-btn:active {
        width: 5.75rem;
        height: 2rem;
        border: 1px solid #959aa1;
        border-radius: 0.25rem;
        background: none;
        font-size: 0.875rem;
        font-weight: bold;
        line-height: 0.875rem;
      }
    }

    .continue-btn,
    .continue-btn:hover {
      width: 20.3125rem;
      height: 2.8125rem;
      border-radius: 9999px;
      background-color: $color-red;
      border: unset;
      color: white;
      font-size: 1rem;
      font-weight: bold;
      float: right;
    }
  }

  .contact-tables {
    &__header {
      color: #959aa1;
      font-weight: 600;
      font-size: 0.75rem;
    }

    &__row {
      margin-top: 8px;
      height: 50px;
      display: flex;
      align-items: center;
      background-color: #f6f7f9;
      border-radius: 8px;

      &__avatar {
        width: 24px;
        height: 24px;
        border-radius: 100%;
      }

      &__name {
        font-size: 14px;
        font-weight: 700;
        color: #333;
      }

      &__source {
        font-size: 12px;
        font-weight: 600;
        color: #6d6d6f;
      }

      .check-contact {
        width: 32px;
        height: 32px;
        border-radius: 100%;
        background-color: $color-red;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .uncheck-contact {
        width: 32px;
        height: 32px;
        border-radius: 100%;
        border: 1px solid #8f939a;
      }
    }
  }
}
