.empty-result-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .icon-wrapper {
    background-color: rgba(70, 96, 138, 0.1);
    border-radius: 0.1875rem;
    width: 4.5625rem;
    height: 4.5625rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .no-match-text {
    font-size: 1.25rem;
    opacity: 0.7;
  }

  .try-different-text {
    color: $color-gray2;
    font-size: 1rem;
  }
}
