.personal-crm-note-widget {
  height: 100%;
  background-color: rgba(208, 166, 150, 0.12);
  border-radius: 0.75rem;
  border: none;

  &__des {
    font-size: 0.875rem;
    color: #6d6d6f;
  }

  &__empty-data {
    height: 100%;
  }

  &__add-note-btn {
    background: rgba(122, 92, 88, 0.12);
    border: none;
    border-radius: 0.75rem;
    height: 3.25rem;
    color: #7a5c58;
    font-size: 0.875rem;
    font-weight: bold;

    &:hover {
      background: rgba(122, 92, 88, 0.8);
    }
  }

  .card-title {
    font-family: 'Kazimir Text', sans-serif;
    font-weight: 900;
    font-size: 1.5625rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0;

    .add-note-btn,
    .add-note-btn:active {
      width: 6.8125rem;
      height: 2.375rem;
      border-radius: 1.1875rem;
      background-color: $color-eunry;
      border: none;
      font-weight: bold;
    }
  }

  .card-body {
    padding: 0;

    .note-wrapper {
      display: flex;
      border-bottom: 1px solid #e2e2e2;
      cursor: pointer;

      &:first-child {
        margin-top: 0 !important;
      }

      &:last-child {
        border-bottom: none;
      }

      .note-title {
        font-weight: bold;
      }

      .note-date {
        color: rgba(40, 40, 40, 0.5);
        font-size: 0.75rem;
      }

      .note-content {
        color: rgba(40, 40, 40, 0.5);
        font-size: 0.75rem;
      }

      .note-tag {
        color: white;
        font-size: 0.75rem;
        font-weight: 600;
        border-radius: 1.25rem;

        &.light {
          color: #7a5c58;
        }
      }

      .note-attachment {
        color: rgba(40, 40, 40, 0.3);
        font-size: 0.75rem;
      }

      .note-avatars {
        display: inline-flex;
        flex-direction: row-reverse;

        .note-avatar:not(:last-child) {
          margin-left: -0.4375rem;
        }
      }
    }
  }
}
