.admin-dashboard-new-post {
  background-color: #f8f9fa;
  padding: 3.3125rem;
  padding-bottom: 4.6875rem;

  .form-new-post {
    background-color: $color-white;
    border-radius: 0.75rem;
    border: 1px solid rgba(196, 196, 196, 0.5);

    .downloadable-file-title {
      font-style: italic;
      font-size: 0.625rem;
      color: #6d6d6f;
    }

    .form-right {
      border-right: 1px solid rgba(196, 196, 196, 0.5);

      .form-label {
        font-size: 0.875rem;
        color: $color-black;
        font-weight: bold;
      }
    }

    .ck-editor__editable {
      min-height: 20rem;
    }

    .title {
      font-size: 0.875rem;
      color: $color-black;
      font-weight: bold;
    }

    .title_share {
      font-weight: 600;
      font-size: 0.875rem;

      img {
        margin-top: -0.2rem;
      }

      &__facebook {
        color: #425896 !important;
      }

      &__twitter {
        color: #65a7f0 !important;
      }

      &__instagram {
        color: #282828 !important;
      }

      &__pinterest {
        color: #d02d2f !important;
      }
    }

    .status {
      padding: 1rem;
      border-bottom: 1px solid rgba(196, 196, 196, 0.5);
    }

    .detail {
      padding: 1rem;
      border-bottom: 1px solid rgba(196, 196, 196, 0.5);
    }

    .image {
      padding: 1rem;

      .button-input-file {
        background-color: rgba(237, 239, 243, 0.5);
        border: 1px dashed $color-gray2;
        border-radius: 0.625rem;

        span {
          color: $color-gray2;
          font-size: 0.75rem;
        }

        .image-upload {
          border-radius: 0.625rem;
        }
      }
    }

    .form-group {
      label {
        color: $color-gray2;
        font-size: 0.875rem;
      }
    }

    select {
      border: none;
      padding: 0;
      color: $color-light-blue;
      font-size: 0.875rem;
      font-weight: bold;
      text-align-last: right;
      height: 1.5rem;

      &:focus {
        box-shadow: none;
      }
    }

    &.podcast {
      .ck-editor__editable {
        min-height: 10rem;
      }
    }
  }
}
