.login-page-wrapper {
  .remember {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-bottom: 2.5rem;
  }

  .link-btn-orange {
    color: $color-orange !important;
    background: none !important;
    border: none !important;
    padding: 0;
  }

  .link-btn-signin {
    font-weight: bold;
    font-size: 1.125rem;
  }

  .forgot-btn {
    font-weight: bold;
  }

  .checkbox-btn {
    color: $color-dark-gray !important;
    background: none !important;
    border: none !important;
    padding: 0;
  }
}
