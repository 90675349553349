.startup-admin-wrapper {
  padding: 40px;
  background-color: #f4f5f7;
  min-height: 1000px;

  .content-wrapper {
    position: relative;
    margin-top: 0;
    padding: 0;

    .heading {
      font-weight: 700;
      font-size: 25px;
      line-height: 25px;
      letter-spacing: 2px;
      color: #282828;
      margin-bottom: 33px;
    }
  }

  .startup-content-admin-header {
    border-bottom: 1px solid $color-gray;
    height: 37px;
    overflow-x: scroll;
    display: flex;
    flex-flow: row;
    width: 100%;
    margin-bottom: 40px;

    .item {
      font-size: 1rem;
      line-height: 1rem;
      font-weight: 700;
      color: $color-gray2;
      margin-right: 20px;
      height: 36px;
      flex: 1;

      &--last {
        margin-right: 0;
      }

      &.company-setting {
        min-width: 133px;
        max-width: 133px;
      }

      &.intro {
        min-width: 75px;
        max-width: 75px;
      }

      &.executive-summary {
        min-width: 143px;
        max-width: 143px;
      }

      &.company-overview {
        min-width: 142px;
        max-width: 142px;
      }

      &.product-service {
        min-width: 144px;
        max-width: 144px;
      }

      &.market-analysis {
        min-width: 116px;
        max-width: 116px;
      }

      &.marketing-plan {
        min-width: 110px;
        max-width: 110px;
      }

      &.sale-financial {
        min-width: 159px;
        max-width: 159px;
      }

      &.discovery {
        min-width: 73px;
        width: 73px;
        max-width: 73px;
      }

      &.target-market {
        min-width: 102px;
        width: 102px;
        max-width: 102px;
      }

      &.smart-goals {
        min-width: 99px;
        width: 99px;
        max-width: 99px;
      }

      &.channels {
        min-width: 69px;
        width: 69px;
        max-width: 69px;
      }

      &.strategy {
        min-width: 62px;
        width: 62px;
        max-width: 62px;
      }

      &.active {
        color: $color-orange;
        padding-bottom: 10px;
        border-bottom: 4px solid $color-orange;
      }
    }
  }
}
