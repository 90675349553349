.admin-layout-wrapper {
  display: flex;

  .sidebar-wrapper {
    flex: 0 0 20.1875rem;
    min-height: 100vh;
  }

  .content-wrapper {
    flex: 1;
    max-width: calc(100vw - 20.1875rem);
  }
}
