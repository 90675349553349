.startup-investment-wrapper {
  .list-investment {
    min-height: 878px;
  }

  .bold-invester-text {
    color: #333;
    font-size: 1.125rem;
    font-weight: bold;
  }

  .empty-investments {
    min-height: 830px;
  }

  .card-body {
    padding: 1.5rem !important;
  }

  .metting-status {
    color: #f09b00 !important;
  }

  .pass-status {
    color: #f36328 !important;
  }

  .empty-text {
    font-family: 'Kazimir Text', sans-serif;
    font-size: 2rem;
    color: #7a5c58;

    .bold-empty-text {
      font-weight: 900;
    }
  }

  .des-empty-text {
    color: #7a5c58;
    font-size: 0.875rem;
  }

  .inreview-status {
    color: #959aa1 !important;
  }

  .founder-background {
    position: absolute;
    right: 0;
    bottom: 0;
    border-bottom-right-radius: 0.75rem;
  }

  .point-background {
    position: absolute;
    top: -10rem;
    right: 0;
    bottom: 0;
    left: -30rem;
    margin: auto;
  }

  .tags-item {
    align-items: center;
    background-color: #e9ecf1 !important;
    border-radius: 1rem !important;
    color: #46608a !important;
    font-weight: normal !important;
    margin-right: 0.5rem !important;
    padding: 0.2rem 0.5rem;
    font-size: 0.75rem;
  }

  .investor-infor {
    width: 90%;
  }

  .document-item {
    border: 1px solid #edeff3;
    border-radius: 0.75rem;
    padding: 0.75rem 1rem;
    margin-bottom: 1rem;
    background: #fff;
  }

  .normal-invester-text {
    font-size: 0.75rem;
    color: #6d6d6f;
    font-weight: 400;
  }

  .invester-text {
    color: #6d6d6f;
    font-size: 0.625rem;
    text-transform: uppercase;
    font-weight: 600;
  }

  .line-border {
    height: 1px;
    background: #959aa1;
    margin: 0.5rem 0;
    opacity: 0.2;
    width: 60%;
  }

  .view-company-button {
    border-radius: 0.25rem !important;
    border: 1px solid #edeff3;
    background: #fff;
    color: #6d6d6f;
    font-size: 0.75rem;
    width: 11.25rem;
    font-weight: bold;
  }

  .left-infor {
    width: 65%;
  }

  .status-select-button {
    border-radius: 0.25rem !important;
    background: rgba(149, 154, 161, 0.12);
    color: #959aa1;
    font-weight: 700;
    font-size: 0.875rem;
    width: 11.25rem;
  }

  .invester-value {
    color: #535353;
    font-weight: bold;
    font-size: 0.75rem;
  }

  .avatar {
    width: 10%;
    margin-top: -3rem;
  }

  .startup-investment-status-bar {
    padding-bottom: 2.5rem;
    position: relative;
    background-color: #fff;
    border-radius: 1rem;

    .status-type {
      margin-right: 2rem;
      color: #959aa1;
      font-size: 1rem;
      font-weight: 700;

      &:hover {
        color: #282828 !important;
        cursor: pointer;
      }

      .amount-starup {
        height: 1rem;
        width: 1.125rem;
        background: #f36328;
        border-radius: 1.8rem;
        color: #fff;
        font-weight: 600;
        font-size: 0.625rem;
        padding: 0.2rem 0.4rem;
        margin-left: 0.3rem;
      }
    }

    .active {
      color: #282828 !important;
      border-bottom: 3px solid #f09b00;
    }
  }
}
