.positioning-statement-update {
  &__content {
    border-radius: 0.563rem;
    box-shadow: 4px 13px 50px rgba(0, 0, 0, 0.1);

    .card-body {
      padding: 2rem;
    }
  }

  &__title {
    font-size: 1.25rem;
    line-height: 1.44rem;
    color: #282828;
    margin-bottom: 0.5rem;
    font-weight: bold;
    font-family: 'Kazimir Text', sans-serif;
  }

  &__description {
    width: 70%;
    font-size: 0.875rem;
    line-height: 1.063rem;
    color: #282828;
    margin-bottom: 1.875rem;
    font-weight: normal;
    opacity: 0.8;
  }

  &__item-name {
    font-family: 'Proxima Nova', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 0.875rem;
    line-height: 1.063rem;
    display: flex;
    align-items: center;
    letter-spacing: 0.125rem;
    text-transform: uppercase;
    color: #929292;
  }

  input {
    border-radius: 2.75rem !important;
  }

  .save-btn {
    background: #f36328 !important;
    border-radius: 1.938rem;
    color: #fff;
    font-weight: bold;
    font-size: 1rem;
    line-height: 1.188rem;
    display: flex;
    align-items: center;
    text-align: center;
    font-family: 'Proxima Nova', sans-serif;
    border: none;
    padding: 0.5rem 3rem;
    height: 45px;
  }

  &__long-version {
    height: 50%;
    border-bottom: 1px solid #e2e2e2;

    &--title {
      color: #282828;
      font-weight: bold;
      opacity: 0.8;
      line-height: 103.8%;
      font-size: 1rem;
      margin-bottom: 1.25rem;
      text-transform: uppercase;
    }

    &--content {
      color: #282828;
      font-weight: normal;
      opacity: 0.8;
      line-height: 2.188rem;
      font-size: 1.875rem;
      margin-bottom: 1.25rem;
      font-family: 'Kazimir Text', sans-serif;
    }
  }

  &__concise-version {
    height: 50%;
    margin-top: 1.25rem;

    &--title {
      color: #282828;
      font-weight: bold;
      opacity: 0.8;
      line-height: 103.8%;
      font-size: 1rem;
      margin-bottom: 1.25rem;
      text-transform: uppercase;
    }

    &--content {
      color: #282828;
      font-weight: normal;
      opacity: 0.8;
      line-height: 2.188rem;
      font-size: 1.875rem;
      margin-bottom: 1.25rem;
      font-family: 'Kazimir Text', sans-serif;
    }
  }

  .hightlight-text {
    color: #46608a;
  }

  .menu-icon {
    position: absolute;
    right: 1rem;
    top: 1rem;

    .dropdown-menu {
      position: relative !important;
    }
  }
}
