.kanban-wrapper {
  width: 100%;
  background-color: #fff;
  padding: 20px;
  border-top: 1px solid $color-gray;

  .item {
    min-height: 155px;
    width: 275px;
    box-shadow: 0 10px 40px rgba(0, 0, 0, 0.12);
    border-radius: 8px;
    margin-right: 20px;
    overflow: hidden;

    &__header {
      height: 37px;
      border-top: 4px solid $color-blue;
      display: flex;
      align-items: center;
      padding-left: 10px;
      padding-right: 10px;
      background-color: #f8fafb;

      &--next {
        border-color: $color-tangerine;
      }

      &--later {
        border-color: $color-red;
      }

      &--done {
        border-color: $color-green2;
      }

      &__dot {
        width: 8px;
        height: 8px;
        border-radius: 2px;
        background-color: $color-blue;
        margin-right: 6px;

        &--next {
          background-color: $color-tangerine;
        }

        &--later {
          background-color: $color-red;
        }

        &--done {
          background-color: $color-green2;
        }
      }

      &__number {
        margin-left: 6px;
        padding: 2px 4px;
        border-radius: 100px;
        background-color: $color-gray;
        color: $color-gray2;
        font-weight: 700;
        font-size: 10px;
        line-height: 12px;
      }
    }

    &__body {
      padding: 16px 12px 12px 12px;

      .feature {
        width: 100%;
        height: 78px;
        border-radius: 4px;
        border: 1px solid $color-gray;
        padding: 10px 12px;
        margin-bottom: 12px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        background-color: #fff;

        &--dragging {
          transform: rotate(2.57deg);
        }

        &__header {
          color: #333;
          font-size: 14px;
          font-weight: 600;
          display: flex;
          align-items: center;

          .check {
            width: 16px;
            height: 16px;
            border-radius: 100%;
            background-color: $color-green2;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: 8px;
          }

          &--done {
            color: $color-gray2;
            text-decoration-line: line-through;
          }
        }

        &__content {
          display: flex;
          justify-content: space-between;
          color: $color-blue;
          font-size: 12px;
          font-weight: 600;

          &--done {
            color: $color-gray2;
          }

          &__sprint {
            display: flex;
            align-items: center;

            img {
              margin-right: 8px;
            }
          }

          &__date {
            display: flex;
            align-items: center;

            img {
              margin-right: 8px;
            }
          }
        }
      }
    }
  }
}
