.onboarding-wrapper {
  position: relative;
  min-height: 100vh;

  .profile-picture {
    margin-top: 0 !important;
  }

  .bg-secondary {
    background-color: #e9ecf1 !important;
    border-radius: 1rem !important;
    color: #46608a !important;
    font-weight: normal !important;
    margin-bottom: 0.5rem !important;
    margin-left: 0.5rem !important;

    svg {
      opacity: 0.5 !important;
    }
  }

  .br-line {
    height: 2px;
    background: #edeff3;
    width: 100%;
    margin: 0 auto 2rem;
  }

  .finish-form {
    max-width: 63%;
  }

  .label-form-finish {
    color: #535353;
    font-size: 1rem;
    font-weight: bold;
  }

  .small-label-finish {
    font-weight: normal;
    font-size: 0.875rem;
    color: #6d6d6f;
  }

  .label-form {
    font-size: 0.875rem;
    color: #929292;
    font-weight: bold;
    text-transform: uppercase;
  }

  .first-active-step {
    max-width: 60%;
    width: 12%;
    position: absolute;
    height: 2px;
    background: #f36328;
  }

  .second-active-step {
    max-width: 60%;
    width: 24%;
    position: absolute;
    height: 2px;
    background: #f36328;
  }

  .third-active-step {
    max-width: 60%;
    width: 36%;
    position: absolute;
    height: 2px;
    background: #f36328;
  }

  .fourth-active-step {
    max-width: 60%;
    width: 48%;
    position: absolute;
    height: 2px;
    background: #f36328;
  }

  .fifth-active-step {
    max-width: 60%;
    width: 60%;
    position: absolute;
    height: 2px;
    background: #f36328;
  }

  .title-text {
    font-family: 'Kazimir Text', sans-serif !important;
    font-size: 32px !important;
    font-weight: 400 !important;
    color: #4f4f4f !important;

    span {
      font-family: 'Kazimir Text', sans-serif !important;
      font-weight: 700 !important;
    }
  }

  .bold-title-text {
    font-weight: bold;
  }

  .type-user-label {
    color: #4f4f4f;
    font-size: 1.125rem;
    font-weight: 600;
  }

  .type-user {
    padding: 1.375rem 2.8rem;
    border: 2px solid #e2e2e2;
    border-radius: 0.5rem;
    background: #fff;
    font-size: 1.25rem;
    font-weight: 600;
    color: #959aa2;

    &:hover {
      border: 2px solid #f36328 !important;
      background: rgba(243, 99, 40, 0.08) !important;
      color: #f36328 !important;
      cursor: pointer;
    }
  }

  .type-user-active {
    border: 2px solid #f36328 !important;
    background: rgba(243, 99, 40, 0.08) !important;
    color: #f36328 !important;
  }

  .bg-img {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .logo-img {
    position: absolute;
    left: 6.25rem;
    top: 2.5rem;
  }

  .card {
    position: absolute;
    right: 6.25rem;
    top: 50%;
    transform: translateY(-50%);
    border-radius: 1.25rem;
    width: 43.625rem;
    height: 42.125rem;

    .form-check-label {
      font-size: 1rem;
      color: #4f4f4f;

      a {
        font-weight: 700;
        color: $color-blue;
      }
    }

    .card-body {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      .card-title {
        font-family: 'Kazimir Text', sans-serif;
        font-weight: 900;
        font-size: 3.1rem;
        color: $color-dark-gray;
      }

      .card-text {
        font-size: 1.2rem;
        font-weight: bold;
        color: $color-dark-gray;
        margin-bottom: 2.25rem;
      }

      form {
        display: flex;
        flex-direction: column;

        .form-group {
          margin-bottom: 1.375rem;
        }

        .image-icon {
          width: 148px;
          height: 148px;
          align-items: center;
          justify-content: center;
          display: flex;
          border-radius: 100%;
          background-color: $color-orange;
          margin-bottom: 20px;

          span {
            color: #fff;
            font-size: 48px;
            font-weight: 300;
          }
        }

        .form-control {
          width: 100%;
          min-height: 3.125rem;
          height: 100% !important;
          border: 0.125rem solid #e2e2e2;
          box-sizing: border-box;
          border-radius: 2.75rem !important;
          padding: 0 1.75rem;
          font-size: 1.125rem;
        }

        .text-area-control {
          min-height: 5.75rem !important;
          border-radius: 1.5rem !important;
        }

        .invalid-feedback {
          margin-left: 1.75rem;
        }

        .action-btn,
        .action-btn:active {
          width: 26.125rem;
          height: 3.125rem;
          background-color: $color-orange;
          border-radius: 1.5625rem;
          border: none;
          color: white;
          font-weight: bold;
          font-size: 1.125rem;
        }
      }

      .footer {
        margin-top: 2.5rem;
        display: flex;
        justify-content: center;
        align-items: center;

        p {
          margin: 0;
          color: $color-dark-gray;
          font-size: 1.125rem;
        }

        .link-btn {
          color: $color-orange !important;
          border: none;
          background: none;
          font-weight: bold;
          font-size: 1.125rem;

          &:hover {
            background: none;
            border: none;
          }
        }
      }
    }
  }
}
