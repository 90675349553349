.modal-startup-starter-wrapper {
  // .br-line {
  //   height: 2px;
  //   background: #edeff3;
  //   width: 100%;
  //   margin: 0 auto 2rem;
  // }

  // .active-step {
  //   margin-top: -2.1rem;
  //   margin-bottom: 2rem;
  //   height: 2px;
  //   background: #f36328;
  // }

  // .first-active-step {
  //   width: 20%;

  //   &--init {
  //     width: 50%;
  //   }
  // }

  // .second-active-step {
  //   width: 40%;

  //   &--init {
  //     width: 100%;
  //   }
  // }

  // .third-active-step {
  //   width: 60%;
  // }

  // .fourth-active-step {
  //   width: 80%;
  // }

  // .last-active-step {
  //   width: 100%;
  // }

  .modal-title {
    font-size: 1.863rem;
    font-family: 'Kazimir Text', sans-serif;
    font-weight: 900;
  }
  // .sub-para {
  //   font-family: 'Proxima Nova', sans-serif;
  // }

  .modal-header {
    border-bottom: 0;
    padding-left: 0 !important;
  }

  .modal-body {
    padding: 1.875rem 3.125rem;

    .contacts-suggestion {
      position: absolute;
      background: #fff;
      width: 14rem;
      border-radius: 0.1rem;
      box-shadow: 0 0.25rem 1.25rem rgb(0 0 0 / 12%);
      padding: 0.5rem;
      z-index: 2;

      .contact-item {
        margin-bottom: 0.5rem;

        &:hover {
          cursor: pointer;
        }

        span {
          color: #535353;
        }
      }
    }

    .percent-prefix {
      position: absolute;
      font-weight: bold;
      left: 6.2rem;
    }

    .nav-tabs {
      border: 1px solid #e2e2e2;
      border-radius: 2rem;
      width: 100%;
      margin: 0 auto;
      padding: 0.125rem;

      .nav-link {
        border: unset;
        border-radius: 2rem;
        font-weight: bold;
        color: #959aa1;
        font-size: 1rem;
        padding: 0.5rem 1.5rem;

        &--init {
          padding: 0.5rem 8.625rem;
        }

        &.active {
          background-color: #e9ecf1;
          color: $color-blue;
        }
      }

      .nav-item {
        margin-bottom: 0;
      }
    }

    .new-startup-modal {
      .profile-picture {
        position: relative;
        margin-top: 1.5rem;
        width: 8.5rem;
        height: 8.5rem;
      }

      .form-check input[type=checkbox] {
        width: 20px;
        height: 20px;
        background: #f36328;
        margin: 1px 5px;
      }

      .form-check input[type=checkbox]:checked {
        width: 20px;
        height: 20px;
        background: #f36328;
        margin: 1px 5px;
      }

      .connection-picture {
        position: relative;
        width: 5.375rem;
        height: 5.375rem;
      }

      .add-btn {
        position: absolute;
        right: 0;
        top: 0;
        width: 3.4375rem;
        height: 3.4375rem;
        margin-right: -1.25rem;
        color: $color-black !important;
        background: none !important;
        border: none !important;
        padding: 0;

        &.connection-add-btn {
          width: 2.4375rem;
          height: 2.4375rem;
        }
      }

      .startup-subheadings {
        font-size: 20px;
        font-family: 'Kazimir Text', sans-serif;
        font-weight: 700;
      }

      .form-label {
        font-size: 14px;
        font-family: 'Proxima Nova', sans-serif;
        font-weight: 400;
      }

      .input-group {
        .input-group-prepend {
          position: absolute;
          z-index: 1;
          top: 0.75rem;
          left: 1rem;
        }

        input {
          padding-left: 2.75rem !important;
        }
      }

      .form-group {
        label {
          color: $color-gray3;
          font-size: 0.875rem;
          line-height: 1.063rem;
          font-weight: bold;
          text-transform: uppercase;
          letter-spacing: 0.125rem;
        }

        .form-control {
          height: 2.875rem;
          border: 2px solid #e2e2e2;
          box-sizing: border-box;
          // border-radius: 1.5625rem;
          border-radius: 44px !important;
          padding: 0 1.75rem;
          font-size: 1rem;
          color: $color-mine-shaft;

          &.position {
            border: 1px solid #e2e2e2;
            background-color: #f6f7f9;
            color: #959aa2;
            font-size: 1rem;
            width: 14rem;
            height: 2.75rem;
            border-radius: 0.313rem;
            padding: 0 0.438rem;

            &::placeholder {
              color: #959aa2 !important;
              font-weight: normal !important;
              font-size: 1rem;
            }
          }

          &.founder-name {
            border: 1px solid #e2e2e2;
            background-color: #f6f7f9;
            color: #282828;
            font-size: 1.25rem;
            width: 14rem;
            height: 2.75rem;
            border-radius: 0.313rem;
            font-weight: bold;
            padding: 0 0.438rem;

            &.outfocus-input {
              background-color: #fff;
            }

            &::placeholder {
              color: #959aa2 !important;
              font-weight: normal !important;
              font-size: 1rem;
            }
          }

          &.founder-position {
            border: 1px solid #e2e2e2;
            background-color: #f6f7f9;
            color: #959aa1;
            font-size: 1rem;
            width: 14rem;
            height: 2.5rem;
            border-radius: 0.313rem;
            padding: 0 0.438rem;

            &.outfocus-input {
              background-color: #fff;
            }
          }

          &.text-area {
            padding-top: 0.5rem;
            padding-bottom: 0.5rem;
            height: 6rem;
          }

          &.tag {
            color: $color-red;
            font-weight: 600;
          }
        }
      }

      .business-details-form {
        .form-control {
          border-radius: 10px !important;
          height: 3.9rem !important;
        }
      }
    }

    .contact-select-item {
      .contact-name {
        font-size: 0.875rem;
        color: #000;
        font-weight: bold;
      }

      .contact-source {
        color: #959aa1;
        font-size: 0.75rem;
      }
    }
  }

  .button-group {
    display: flex;
    flex-direction: row-reverse;

    button {
      border-radius: 31px;
      border: none;
      font-size: 16px;
      height: 45px;
      color: $color-gray2 !important;
      font-weight: bold;
      width: 172px;

      &.submit-button {
        background-color: #f09b00 !important;
        color: $color-white !important;
        // width: 100%;
        font-family: 'Proxima Nova', sans-serif;
        font-weight: 700;
      }
    }
  }

  .design-bottom {
    left: 0;
    bottom: 0;
    position: absolute;
  }

  .trash-button {
    width: 3.125rem;
    height: 3.125rem;
    border-radius: 50%;
    border: unset;
    background-color: #f6f6f6;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .button-add-founder {
    width: 100%;
    // border: 2px dashed #46608a;
    border: none !important;
    color: #46608a;
    font-size: 1rem;
    font-weight: bold;
    display: flex;
    align-items: center;
    height: 3.25rem;
    justify-content: center;
    background-color: unset;
    margin-top: 2rem;
    border-radius: 0.375rem;
  }

  .select-box {
    position: relative;
    cursor: pointer;

    .type-dropdown {
      appearance: none;
      -moz-appearance: none;
      -webkit-appearance: none;
      cursor: pointer;
    }

    img {
      position: absolute;
      right: 1rem;
      top: 1.25rem;
    }
  }
}
