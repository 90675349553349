.startup-starter-page-wrapper {
  .content {
    margin-top: -1rem;
    padding: 0 6.5625rem 7.125rem 6.5625rem;
    position: relative;

    &__admin {
      margin-top: 0;
      padding: 0;
    }

    .banner-section {
      position: relative;
      width: 100%;

      .banner-content {
        position: absolute;
        top: 65%;
        transform: translateY(-65%);
        left: 3.125rem;

        .label {
          color: $color-blue;
          font-size: 0.875rem;
          font-weight: bold;
          letter-spacing: 2px;
          text-transform: uppercase;
        }

        .title {
          color: white;
          font-family: 'Kazimir Text', sans-serif;
          font-weight: 900;
          font-size: 2.5rem;
        }

        .desc {
          color: white;
          font-size: 0.875rem;
        }
      }
    }

    .card.startup-starter-card {
      border-radius: 0.75rem;
      background-color: #f6f7f9;
      border: none;
      position: relative;

      .card-body {
        padding: 3.125rem 2.5rem;
        background-color: #f6f7f9;
        border-radius: 0.75rem;

        &__admin {
          border-radius: 0;
          min-height: calc(100vh - 85px);
        }

        .card-title {
          .title {
            font-size: 1.563rem;
            font-family: 'Kazimir Text', sans-serif;
            font-weight: bold;
          }

          .add-btn {
            background-color: unset;
            font-size: 1rem;
            font-weight: bold;
            color: $color-blue;
            padding: 0.5rem 1.5rem;
            border: 1px solid $color-blue;
            border-radius: 2rem;
          }
        }
      }
    }

    .decor-right {
      position: absolute;
      right: -6rem;
      top: 2rem;
      z-index: -1;
    }

    .decor-paint {
      position: absolute;
      bottom: -17rem;
      left: -7rem;
      z-index: -1;
    }

    .decor-two-line {
      position: absolute;
      top: 0.75rem;
      z-index: 2;
      left: -3rem;
    }
  }
}
