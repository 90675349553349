.error_meesage {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545;
}

.marketing-channels-wrapper {
  width: 100%;

  &__empty-data {
    height: 6rem;
    background: rgba(70, 96, 138, 0.08);
    border-radius: 0.75rem;
    width: 100%;
  }

  &__include-data {
    &__heading {
      color: #6d6d6f;
      font-size: 0.75rem;
      text-transform: uppercase;
      font-weight: 600;
      margin-bottom: 0.7rem;
    }

    &__content {
      height: 3.75rem;
      background-color: rgba(255, 233, 195, 0.2);
      margin-bottom: 0.5rem;
    }

    .first-block {
      width: 25%;
    }

    .second-block {
      width: 25%;
    }

    .third-block {
      width: 15%;
    }

    .fourth-block {
      width: 15%;
    }

    .fifth-block {
      width: 10%;
    }

    .sixth-block {
      width: 10%;

      .item-btn {
        width: 24px;
        height: 24px;
        border: none !important;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 100%;
        padding: 0;

        &--add {
          background-color: #fee9db;
        }

        &--delete {
          background-color: #fee9db;
        }
      }
    }

    .title {
      font-family: 'Kazimir Text', sans-serif;
      font-size: 0.875rem;
      color: #535353;
      font-weight: bold;
    }

    .channel {
      font-size: 0.75rem;
      color: #333;
    }

    .square-block {
      width: 0.75rem;
      height: 0.75rem;
      margin-right: 0.5rem;
      border-radius: 2px;
      border: 1px solid rgba(149, 154, 162, 0.5);
      background-color: #fff;
    }

    .active {
      background: #46608a !important;
    }
  }

  &__notification {
    color: #959aa2;
    font-weight: 600;
    font-size: 1.125rem;
  }

  &__modal-list-channel {
    .tooltip-content {
      font-size: 0.75rem;
      color: #535353;
    }

    .bold-text {
      font-weight: 700;
    }

    .modal-title {
      color: #333;
      font-weight: 900;
      font-size: 1.5rem;
      font-family: 'Kazimir Text', sans-serif;
    }

    &--item {
      width: 100%;
      height: 2.5rem;
      background-color: rgba(255, 233, 195, 0.2);
      padding: 0 0.5rem;

      &__name {
        font-family: 'Kazimir Text', sans-serif;
        color: #535353;
        font-weight: bold;
        font-size: 0.875rem;
      }

      &__des {
        font-family: 'Proxima Nova', sans-serif;
        color: #6d6d6f;
        font-weight: normal;
        font-size: 0.75rem;
      }

      &__add-btn {
        background: none !important;
        border: none;
      }

      &__ticket {
        background-color: rgba(122, 92, 88, 0.2);
        color: #7a5c58;
        font-size: 0.625rem;
        font-weight: bold;
        height: 1.25rem;
        margin-top: 0.625rem;
        margin-right: 1.5rem;
        padding: 0.2rem 0.5rem;
        border-radius: 4px;
      }
    }
  }

  &__modal-channel-item {
    .modal-title {
      color: #333;
      font-weight: 900;
      font-size: 1.5rem;
      font-family: 'Kazimir Text', sans-serif;
    }

    .small-text {
      font-size: 0.625rem;
      color: #6d6d6f;
      font-weight: 600;
      margin-top: 0.3rem;
    }

    &__values {
      .active {
        background: #46608a !important;
        color: #fff !important;
      }
    }

    &--text-title {
      color: #535353;
      font-weight: bold;
    }

    &--type-channel {
      font-size: 0.875rem;
      color: #333;
    }

    &--block-step {
      height: 1.625rem;
      width: 4.8rem;
      background: #edeff3;
      border-radius: 2px;
      color: #959aa2;
      font-size: 0.875rem;
      font-weight: 600;
      cursor: pointer;

      &:hover {
        background: #46608a !important;
        color: #fff !important;
      }
    }

    .round {
      position: relative;

      label {
        background-color: #fff;
        border: 1px solid #ccc;
        border-radius: 50%;
        cursor: pointer;
        height: 1.25rem;
        left: 0;
        position: absolute;
        top: 0.2rem;
        width: 1.25rem;

        &::after {
          border: 2px solid #fff;
          border-top: none;
          border-right: none;
          content: "";
          height: 6px;
          left: 4px;
          opacity: 0;
          position: absolute;
          top: 5px;
          transform: rotate(-45deg);
          width: 12px;
        }
      }

      input[type="checkbox"] {
        visibility: hidden;

        &:checked {
          + {
            label {
              background-color: #f36328;
              border-color: #f36328;

              &::after {
                opacity: 1;
              }
            }
          }
        }
      }
    }

    &__heading {
      &--title {
        text-transform: uppercase;
        color: #46608a;
        font-size: 1rem;
        font-weight: bold;
      }

      &--des {
        color: #6d6d6f;
        font-size: 1rem;
        font-weight: normal;
      }
    }
  }
}

.content {
  &__input-title {
    color: #535353;
    font-size: 1rem;
    font-weight: 700;
  }

  &__text {
    color: #6d6d6f;
    font-size: 0.875rem;
    font-weight: 400;
  }

  &__back-button {
    color: #959aa1;
    background-color: #fff;
    border: 1px solid #959aa1;
    font-size: 1rem;
    font-weight: 700;
    height: 2.813rem;
    padding-right: 2.375rem;
    padding-left: 2.375rem;
    border-radius: 2rem;
  }

  &__button-submit {
    color: #fff;
    background-color: #f36328;
    font-size: 1rem;
    font-weight: 700;
    height: 2.813rem;
    padding-right: 2.375rem;
    padding-left: 2.375rem;
    border-radius: 2rem;
    border: none;

    &:hover {
      background-color: #f36328;
    }
  }

  &__button-save {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0;
    gap: 7.5px;
    border: 1px dashed #959aa2;
    border-radius: 31px;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    color: #959aa2;
    width: 164px;
    height: 45px;
    background: white;
  }
}
