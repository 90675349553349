.personal-crm-page-wrapper {
  z-index: 0;

  .tag-title {
    text-transform: capitalize;
  }

  .content {
    padding: 2.5rem 6.25rem 3.125rem 6.25rem;
    position: relative;

    .decor-left-top {
      position: absolute;
      top: 5.5rem;
      left: 1.5rem;
    }

    .decor-left-bottom {
      position: absolute;
      bottom: 0;
      left: 0;
    }

    .decor-right {
      position: absolute;
      bottom: -1.25rem;
      right: -3rem;
    }
  }
}
