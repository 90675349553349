.crm-import-contacts-modal {
  .modal-header {
    padding-bottom: 0;
    border-bottom: unset;
  }

  .download-text {
    color: $color-orange;
  }

  .modal-body {
    padding: 0 2.5rem 2.5rem 2.5rem;

    .title {
      font-size: 1.25rem;
      font-weight: 700;
      color: #333;
      text-align: center;
    }

    .desc {
      font-size: 1rem;
      color: #333;
      text-align: center;
    }

    .dropzone {
      background: rgba(237, 239, 243, 0.5);
      border: 1px dashed $color-gray2;
      box-sizing: border-box;
      border-radius: 0.625rem;
      text-align: center;
      cursor: pointer;

      .upload-title {
        font-size: 1.125rem;
        font-weight: 700;
        color: $color-gray2;
      }

      .upload-desc {
        font-size: 1rem;
        font-weight: 400;
        color: $color-gray2;
      }
    }

    .import-btn,
    .import-btn:hover {
      width: 20.3125rem;
      height: 2.8125rem;
      border-radius: 9999px;
      background-color: $color-red;
      border: unset;
      color: white;
      font-size: 1rem;
      font-weight: bold;
      float: right;
    }
  }
}
