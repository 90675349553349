.personal-crm-contact-detail-widget {
  background-color: rgba(240, 155, 0, 0.12);
  border-radius: 0.5rem;
  border: none;
  height: 100%;
  position: relative;

  .edit-btn {
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
  }

  .contact-name {
    font-size: 1.375rem;
    font-weight: bold;
  }

  .contact-phone {
    font-size: 0.875rem;
    color: $color-red;
    font-weight: 600;
  }

  .contact-email {
    text-decoration: underline;
  }

  .section-title {
    font-size: 0.875rem;
    font-weight: bold;
  }

  .contact-birthday {
    font-size: 0.875rem;
    opacity: 0.75;

    span {
      font-size: 0.75rem;
      color: $color-red;
      font-style: italic;
    }
  }

  .job-info {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    line-height: 1.5rem;
    max-height: 3rem;
  }

  .contact-note {
    font-size: 0.75rem;
    color: #6d6d6f;
  }

  .contact-link {
    font-size: 0.875rem;
    color: $color-red;
    font-weight: 600;
    text-decoration: underline;
  }

  .contact-tag {
    color: $color-gray2;
    background-color: rgba(149, 154, 162, 0.2);
    border-radius: 9999px;
    margin-bottom: 0.5rem;
  }

  .connection {
    color: #333;
    font-size: 0.875rem;
  }
}
