.crm-new-contact-wrapper {
  .react-tag-input {
    box-sizing: border-box;
    position: relative;
    width: 100%;
    height: auto;
    overflow-y: auto;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    background: #fff;
    min-height: 2.875rem;
    border: 0.125rem solid #e2e2e2;
    border-radius: 1.5625rem;
    padding: 0 1.75rem;
    font-size: 1rem;
    color: #333;

    div.react-tag-input__tag:not(:last-child)::after {
      content: ",";
      margin-right: 4px;
      color: #5d5d5d;
      font-size: bold;
    }

    * {
      box-sizing: border-box;
    }

    .react-tag-input__input {
      width: auto;
      flex-grow: 1;
      height: 1.875em;
      margin-top: 0.25rem;
      font-size: 1em;
      line-height: 1;
      background: transparent;
      color: #333;
      border: none;
      border-radius: 3px;
      outline: 0;
      box-shadow: none;
      -webkit-appearance: none;

      &::placeholder {
        color: rgb(128, 128, 128);
      }

      &:focus {
        border: none;
      }
    }

    .react-tag-input__tag {
      position: relative;
      display: flex;
      align-items: center;
      font-size: 0.85em;
      line-height: 1;
      color: #f36328;
      padding-top: 0.5rem;
    }

    .react-tag-input__tag__content {
      outline: 0;
      border: none;
      white-space: nowrap;
      padding: 0 0.125em;
      font-weight: 600;
      font-size: 1rem;
    }

    .react-tag-input__tag__remove {
      display: none;
      position: relative;
      height: 2em;
      width: 2em;
      font-size: 1rem;
      font-size: 0.85em;
      cursor: pointer;
      background: #d4d4d4;
      border-top-right-radius: 3px;
      border-bottom-right-radius: 3px;

      &::before {
        position: absolute;
        top: 50%;
        left: 50%;
        content: " ";
        height: 0.9em;
        width: 0.15em;
        background-color: #333;
        transform: translateX(-50%) translateY(-50%) rotate(45deg);
      }

      &::after {
        position: absolute;
        top: 50%;
        left: 50%;
        content: " ";
        height: 0.9em;
        width: 0.15em;
        background-color: #333;
        transform: translateX(-50%) translateY(-50%) rotate(-45deg);
      }
    }

    .react-tag-input__tag__remove-readonly {
      width: 0;

      &::before {
        content: "";
        width: 0;
      }

      &::after {
        content: "";
        width: 0;
      }
    }
  }
}
