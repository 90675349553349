.business-model-wrapper {
  .header {
    &__title {
      color: #282828;
      font-size: 1.25rem;
      font-family: 'Kazimir Text', sans-serif;
      font-weight: bold;
    }

    &__description {
      color: #282828;
      opacity: 0.8;
      font-size: 0.875rem;
    }

    .export-button {
      color: #46608a;
      font-size: 1rem;
      font-weight: bold;
      padding: 0.5rem 1rem;
      background: rgba(70, 96, 138, 0.1);
      border: none;
      border-radius: 6.25rem;

      .image {
        margin-right: 0.5rem;
      }
    }
  }
}
