/* stylelint-disable no-descending-specificity */
@keyframes oci-infinite-spinning {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.orgchart {
  display: flex;
  justify-content: center;

  ul {
    padding-left: 0;
    text-align: center;
    display: flex;
    margin: 0;
    transition: transform 0.3s, opacity 0.3s;

    li {
      display: inline-block;
      position: relative;

      .oc-node {
        box-sizing: border-box;
        display: inline-block;
        position: relative;
        padding: 3px;
        border: 2px dashed transparent;
        margin-bottom: 20px;

        &:not(:only-child) {
          &::after {
            content: "";
            position: absolute;
            bottom: -13px;
            left: calc(50% - 1px);
            width: 2px;
            height: 16px;
            background-color: #f5d2c5;
          }
        }

        .oc-heading {
          box-sizing: border-box;
          padding: 2px;
          margin: 0;
          width: 130px;
          height: 20px;
          text-align: center;
          font-size: 0.75rem;
          font-weight: bold;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          background-color: rgba(217, 83, 79, 0.8);
          color: #fff;
          border-radius: 4px 4px 0 0;

          .oc-symbol {
            float: left;
            margin-top: 12px;
            margin-left: 2px;

            &::before {
              background-color: #fff;
              border-color: #f5d2c5;
            }

            &::after {
              background-color: #fff;
            }
          }
        }

        .oc-content {
          box-sizing: border-box;
          padding: 2px;
          width: 130px;
          height: 20px;
          font-size: 0.625rem;
          border: 1px solid rgba(217, 83, 79, 0.8);
          border-radius: 0 0 4px 4px;
          text-align: center;
          background-color: #fff;
          color: #333;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }

      .oc-node.allowedDrop {
        .org-structure__item {
          border-color: rgba(243, 99, 40) !important;
          background-color: rgba(243, 99, 40, 0.15) !important;
        }
      }

      .oc-node.currentDropTarget {
        background-color: rgba(68, 157, 68, 0.9);
      }

      .oc-node.isChildrenCollapsed {
        &:not(:only-child) {
          &::after {
            content: none;
          }
        }
      }
    }
  }

  >ul {
    >li {
      >ul {
        li {
          &::before {
            content: "";
            position: absolute;
            top: -11px;
            left: 0;
            width: 100%;
            border-top: 2px solid #f5d2c5;
            box-sizing: border-box;
          }

          &:first-child {
            &::before {
              left: calc(50% - 1px);
              width: calc(50% + 1px);
            }
          }

          &:last-child {
            &::before {
              width: calc(50% + 1px);
            }
          }

          &:only-child {
            &::before {
              width: 2px;
            }
          }

          >.oc-node {
            &::before {
              content: "";
              position: absolute;
              top: -11px;
              left: calc(50% - 1px);
              width: 2px;
              height: 14px;
              background-color: #f5d2c5;
            }
          }
        }

        li.isSiblingsCollapsed {
          &::before {
            content: none;
          }

          >.oc-node {
            &::before {
              top: -13px;
              height: 11px;
            }
          }
        }

        li.isAncestorsCollapsed {
          >.oc-node {
            &::before {
              content: none;
            }
          }
        }
      }
    }
  }

  .oc-node {
    .oc-edge {
      position: absolute;
      cursor: default;
      transition: 0.2s;

      &::before {
        border-color: rgba(68, 157, 68, 0.5);
      }

      &:hover {
        cursor: pointer;
      }
    }

    .verticalEdge {
      width: calc(100% - 6px);
      width: -webkit-calc(100% - 6px);
      width: -moz-calc(100% - 6px);
      height: 10px;
      left: 3px;

      &::before {
        position: absolute;
        left: calc(50% - 0.3125rem);
      }
    }

    .topEdge {
      top: -2px;
    }

    .topEdge.oci-chevron-up {
      &::before {
        top: 2px;
      }
    }

    .topEdge.oci-chevron-down {
      &::before {
        bottom: 3px;
      }
    }

    .bottomEdge {
      bottom: -2px;
    }

    .bottomEdge.oci-chevron-up {
      &::before {
        bottom: -3px;
      }
    }

    .bottomEdge.oci-chevron-down {
      &::before {
        bottom: 1px;
      }
    }

    .horizontalEdge {
      width: 10px;
      height: calc(100% - 6px);
      height: -webkit-calc(100% - 6px);
      height: -moz-calc(100% - 6px);
      top: 3px;

      &::before {
        position: absolute;
        top: calc(50% - 0.3125rem);
      }
    }

    .rightEdge {
      right: -2px;
    }

    .leftEdge {
      left: -2px;
    }

    .rightEdge.oci-chevron-left {
      &::before {
        right: -3px;
      }
    }

    .rightEdge.oci-chevron-right {
      &::before {
        right: 1px;
      }
    }

    .leftEdge.oci-chevron-right {
      &::before {
        left: -3px;
      }
    }

    .leftEdge.oci-chevron-left {
      &::before {
        left: 1px;
      }
    }
  }

  .oc-edge {
    &:hover {
      &::before {
        border-color: #449d44;
      }
    }
  }

  .slide-up {
    opacity: 0;
    transform: translateY(-40px);
  }

  .slide-down {
    opacity: 0;
    transform: translateY(40px);
  }

  .slide-left {
    opacity: 0;
    transform: translateX(-130px);
  }

  .slide-right {
    opacity: 0;
    transform: translateX(130px);
  }
}

.orgchart.noncollapsable {
  .oc-node {
    .oc-edge {
      display: none;
    }
  }
}

.oci {
  display: inline-block;
  position: relative;
  font-style: normal;
}

.oci-chevron-up {
  &::before {
    content: "";
    display: inline-block;
    box-sizing: border-box;
    vertical-align: text-bottom;
    width: 0.625rem;
    height: 0.625rem;
    border-width: 0 0.2rem 0.2rem 0;
    border-style: solid;
    border-color: #000;
    background: transparent;
    transform: rotate(-135deg);
  }
}

.oci-chevron-right {
  &::before {
    content: "";
    display: inline-block;
    box-sizing: border-box;
    width: 0.625rem;
    height: 0.625rem;
    border-width: 0 0.2rem 0.2rem 0;
    border-style: solid;
    border-color: #000;
    background: transparent;
    transform: rotate(-45deg);
  }
}

.oci-chevron-down {
  &::before {
    content: "";
    display: inline-block;
    box-sizing: border-box;
    width: 0.625rem;
    height: 0.625rem;
    border-width: 0 0.2rem 0.2rem 0;
    border-style: solid;
    border-color: #000;
    background: transparent;
    transform: rotate(45deg);
  }
}

.oci-chevron-left {
  &::before {
    content: "";
    display: inline-block;
    box-sizing: border-box;
    width: 0.625rem;
    height: 0.625rem;
    border-width: 0 0.2rem 0.2rem 0;
    border-style: solid;
    border-color: #000;
    background: transparent;
    transform: rotate(135deg);
  }
}

.oci-leader {
  &::before {
    position: absolute;
    content: "";
    display: inline-block;
    width: 0.4rem;
    height: 0.4rem;
    border-radius: 0.2rem;
    background: #000;
    top: -0.75rem;
    left: 0.125rem;
  }

  &::after {
    position: absolute;
    content: "";
    display: inline-block;
    width: 0.875rem;
    height: 0.375rem;
    border-radius: 0.25rem 0.25rem 0 0;
    background: #000;
    top: -0.3rem;
    left: -0.125rem;
  }
}

.oci-plus-square {
  &::before {
    content: "+";
    display: inline-block;
    vertical-align: text-bottom;
    text-align: center;
    width: 1rem;
    height: 1rem;
    background-color: #000;
    color: #fff;
  }
}

.oci-plus-circle {
  &::before {
    content: "+";
    display: inline-block;
    vertical-align: text-bottom;
    text-align: center;
    width: 1rem;
    height: 1rem;
    border-radius: 0.5rem;
    background-color: #000;
    color: #fff;
  }
}

.oci-minus-square {
  &::before {
    content: "−";
    display: inline-block;
    vertical-align: text-bottom;
    text-align: center;
    width: 1rem;
    height: 1rem;
    background-color: #000;
    color: #fff;
  }
}

.oci-minus-circle {
  &::before {
    content: "−";
    display: inline-block;
    vertical-align: text-bottom;
    text-align: center;
    width: 1rem;
    height: 1rem;
    border-radius: 0.5rem;
    background-color: #000;
    color: #fff;
  }
}

.oci-arrow-circle-up {
  &::before {
    content: "▲";
    display: inline-block;
    text-align: center;
    vertical-align: text-bottom;
    width: 1rem;
    height: 1rem;
    border-radius: 0.5rem;
    background-color: #000;
    color: #fff;
    font-size: 0.875rem;
  }
}

.oci-arrow-circle-down {
  &::before {
    content: "▼";
    text-align: center;
    display: inline-block;
    vertical-align: text-bottom;
    width: 1rem;
    height: 1rem;
    border-radius: 0.5rem;
    background-color: #000;
    color: #fff;
    font-size: 0.875rem;
  }
}

.oci-info-circle {
  &::before {
    content: "i";
    display: inline-block;
    vertical-align: text-bottom;
    width: 1rem;
    height: 1rem;
    border-radius: 0.5rem;
    background-color: #000;
    color: #fff;
    text-align: center;
    font-weight: bold;
  }
}

.oci-spinner {
  &::before {
    content: "";
    vertical-align: text-bottom;
    display: inline-block;
    box-sizing: border-box;
    width: 1rem;
    height: 1rem;
    border: 0.1rem solid #000;
    border-right-color: transparent;
    border-radius: 0.625rem;
    animation: oci-infinite-spinning 0.75s linear infinite;
  }
}

.oc-mask {
  display: none;
}
/* stylelint-enable no-descending-specificity */
