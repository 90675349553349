.billing-method-modal {
  .card-header-tabs.nav-tabs {
    margin-bottom: 1rem;
    border-bottom: 4px solid #f4f4f4;

    .nav-link {
      font-size: 1rem;
      color: #000;
      border: none;
      margin-bottom: -4px;

      &.active {
        border-bottom: 4px solid #f09b00;
        font-weight: bold;
      }
    }
  }

  .form-group {
    label {
      margin-bottom: 0;

      &.label {
        color: $color-gray3;
        font-size: 0.875rem;
        line-height: 1.063rem;
        font-weight: bold;
        text-transform: uppercase;
        letter-spacing: 0.125rem;
        margin-bottom: 0.5rem;
      }
    }

    .form-control {
      height: 2.875rem;
      border: 0.125rem solid #e2e2e2;
      box-sizing: border-box;
      border-radius: 1.5625rem;
      padding: 0 1rem;
      font-size: 1rem;
      color: $color-mine-shaft;
    }
  }

  .select-box {
    position: relative;
    cursor: pointer;
    display: inline-block;

    &.month {
      width: 40%;
    }

    &.year {
      width: 60%;
    }

    .type-dropdown {
      appearance: none;
      -moz-appearance: none;
      -webkit-appearance: none;
      cursor: pointer;
    }

    img {
      position: absolute;
      right: 1rem;
      top: 1.25rem;
    }
  }

  .button-group {
    display: flex;
    flex-direction: row-reverse;

    button {
      border-radius: 1.1875rem;
      border: none;
      font-size: 1rem;
      height: 2.75rem;
      font-weight: bold;
      color: $color-white !important;
      background-color: $color-blue;
      width: 100%;
    }
  }
}
