.competitor-wrapper {
  .competitor-item {
    .add-button {
      border-radius: 50%;
      background-color: #f6f6f6;
      border: unset;
      width: 2.063rem;
      height: 2.063rem;
    }

    .market-share-input {
      border: 2px solid #edeff3;
      border-radius: 0.313rem;
      width: 4.125rem;
      height: 2.688rem;
      -webkit-appearance: none;
      color: #282828;
      font-size: 1rem;
      font-weight: 600;
      padding-left: 0.5rem;
      text-align: center;

      &.metric-input {
        width: 6.438rem;
        padding-top: 0.3rem;
        text-align: center;
        border-radius: 2.1rem;
      }
    }

    .label {
      color: #282828;
      font-size: 1.125rem;
      font-weight: 600;
    }
  }

  .form-group {
    label {
      color: $color-gray3;
      font-size: 0.875rem;
      line-height: 1.063rem;
      font-weight: bold;
      text-transform: uppercase;
      letter-spacing: 0.125rem;
    }

    .form-control {
      height: 2.875rem;
      border: 0.125rem solid #e2e2e2;
      box-sizing: border-box;
      border-radius: 1.5625rem;
      padding: 0 1.75rem;
      font-size: 1rem;
      color: $color-mine-shaft;

      &.position {
        border: 1px solid #e2e2e2;
        background-color: #f6f7f9;
        color: #959aa2;
        font-size: 1rem;
        width: 14rem;
        height: 2.75rem;
        border-radius: 0.313rem;
        padding: 0 0.438rem;

        &::placeholder {
          color: #959aa2 !important;
          font-weight: normal !important;
          font-size: 1rem;
        }
      }

      &.founder-name {
        border: 1px solid #e2e2e2;
        background-color: #f6f7f9;
        color: #282828;
        font-size: 1.25rem;
        width: 14rem;
        height: 2.75rem;
        border-radius: 0.313rem;
        font-weight: bold;
        padding: 0 0.438rem;

        &.outfocus-input {
          background-color: #fff;
          border: none;
        }

        &::placeholder {
          color: #959aa2 !important;
          font-weight: normal !important;
          font-size: 1rem;
        }
      }

      &.founder-position {
        border: 1px solid #e2e2e2;
        background-color: #f6f7f9;
        color: #959aa1;
        font-size: 1rem;
        width: 14rem;
        height: 2.5rem;
        border-radius: 0.313rem;
        padding: 0 0.438rem;

        &.outfocus-input {
          background-color: #fff;
          border: none;
        }
      }

      &.text-area {
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
        height: 6rem;
      }

      &.tag {
        color: $color-red;
        font-weight: 600;
      }
    }
  }

  .trash-button {
    width: 3.125rem;
    height: 3.125rem;
    border-radius: 50%;
    border: unset;
    background-color: #f6f6f6;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .select-box {
    position: relative;
    cursor: pointer;

    .type-dropdown {
      appearance: none;
      -moz-appearance: none;
      -webkit-appearance: none;
      cursor: pointer;
    }

    img {
      position: absolute;
      right: 1rem;
      top: 1.25rem;
    }
  }
}
