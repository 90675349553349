.pitch-deck-layout-wrapper {
  .pitch-deck-header-wrapper {
    background-color: $color-blue;
    height: 78px;
    padding-left: 100px;
    padding-right: 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .content-left {
      display: flex;
      align-items: center;

      &__logo {
        width: 116px;
      }

      &__split {
        width: 2px;
        height: 32px;
        background-color: #fff;
        opacity: 0.5;
        margin-left: 24px;
        margin-right: 24px;
      }

      h2 {
        font-weight: 700;
        font-size: 22px;
        font-family: 'Kazimir Text', sans-serif;
        color: #fff;
        margin: 0;
      }
    }

    .content-right {
      display: flex;
      align-items: center;

      .header-button {
        width: 258px;
        height: 45px;
        box-sizing: border-box;
        border-radius: 100px;
        color: #fff !important;
        font-weight: 700;
        font-size: 18px;
        line-height: 18px;
        margin-right: 16px;
        display: flex;
        align-items: center;
        justify-content: center;

        &--save {
          background-color: $color-blue !important;
          border: 1px solid #fff !important;
        }

        &--export {
          background-color: $color-red;
          border: none !important;
        }

        &--beta-tag {
          width: 33px;
          height: 14px;
          margin-left: 8px;
          background: #f09b00;
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          padding: 3px 5px;
          border-radius: 30px;
          font-size: 8px;
          border: none;
          font-weight: 800;
        }

        .export-img {
          margin-right: 10px;
          width: 18px;
        }
      }

      .close-button {
        margin-left: 24px;
        width: 20px;
        background-color: unset;
        height: 20px;
        padding: 0 !important;
        border: none !important;
      }
    }
  }

  .content-main {
    display: flex;
    background-color: #f4f5f7;

    .ck-content {
      height: 300px;
    }

    .header-box {
      width: calc(100% - 295px);
    }

    .header-main {
      position: relative;
      background-color: #f4f5f7;
      padding: 40px 60px 0 100px;
      height: 85px;
      display: flex;
      align-items: center;

      .preview-button {
        position: absolute;
        top: 40px;
        right: 60px;
        background: none;
        font-weight: 700;
        color: #46608a;
        height: 45px;
        width: 218px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 1rem;
        border: 1px solid #46608a;
        border-radius: 10rem;

        &:active {
          background-color: #46608a;
          border: none;
        }
      }
    }

    .pitch-deck-form {
      color: #333;
      font-weight: 900;
      font-family: 'Kazimir Text', sans-serif;
      font-size: 24px;
      min-width: 500px;
    }

    .pitch-deck-name {
      position: relative;
      color: #333;
      font-weight: 900;
      font-family: 'Kazimir Text', sans-serif;
      font-size: 24px;
      display: inline-block;

      .btn {
        position: absolute;
        top: 2px;
        right: -30px;
        background-color: unset;
        border: none;
        padding: 0;
        margin: 0;
      }
    }

    .content-box {
      background-color: #f4f5f7;
      padding: 40px 60px 40px 100px;

      &__header {
        &__left {
          .pitch_deck_name {
            font-weight: 900;
            color: #333;
            font-size: 24px;
            line-height: 34px;
          }

          .edit_icon {
            width: 18px;
            height: 18px;
            margin: 11px;
          }
        }

        &__button {
          color: $color-blue;
          border: 1px solid $color-blue;
          width: 218px;
          height: 45px;
          display: flex;
          justify-content: center;
          align-items: center;

          .eye {
            width: 20px;
            margin-right: 10px;
          }
        }
      }

      .content {
        background-color: #fff;
        border: 1px solid rgba(196, 196, 196, 0.5);
        border-radius: 12px;
        padding: 30px;

        .add-button {
          border-radius: 50%;
          background-color: #f6f6f6;
          border: unset;
          width: 2.063rem;
          height: 2.063rem;
        }

        .market-share-input {
          width: calc(100% - 100px);
          -webkit-appearance: none;
          height: 2.875rem;
          border: 0.125rem solid #e2e2e2;
          box-sizing: border-box;
          border-radius: 1.5625rem;
          padding: 0 0.75rem;
          font-size: 1rem;
          color: #333;
          text-align: center;
        }

        &__add-button {
          background: none !important;
          border: none !important;
          font-size: 1rem;
          color: #f36328 !important;
          font-weight: bold;
        }

        .trash-button {
          width: 3.125rem;
          height: 3.125rem;
          border-radius: 50%;
          border: unset;
          background-color: #f6f6f6;
          display: flex;
          align-items: center;
          justify-content: center;
        }

        .round-chart-item {
          position: relative;
        }

        .round-chart-label {
          font-size: 0.875rem;
          font-weight: bold;
          color: #535353;
        }

        &__title {
          color: #46608a;
          font-size: 1rem;
          font-weight: 700;
        }

        &__input-title {
          color: #535353;
          font-size: 1rem;
          font-weight: 700;
        }

        &__des-block {
          background: rgba(255, 233, 195, 0.2);
          font-size: 0.875rem;
          color: #6d6d6f;
          padding: 0.5rem;
          margin-top: 1rem;
        }

        &__input-medium-title {
          color: #282828;
          font-size: 1.125rem;
          font-weight: 600;
        }

        &__text {
          color: #6d6d6f;
          font-size: 0.875rem;
          font-weight: 400;
        }

        &__recommend {
          font-style: italic;
          color: #6d6d6f;
          font-size: 0.75rem;
        }

        &__button-submit {
          color: #fff;
          background-color: #f36328;
          font-size: 1rem;
          font-weight: 700;
          height: 2.813rem;
          padding-right: 2.375rem;
          padding-left: 2.375rem;
          border-radius: 2rem;
          border: none;

          &:active {
            background-color: #f36328 !important;
            border: none;
          }
        }

        &__back-button {
          color: #959aa1;
          background-color: #fff;
          border: 1px solid #959aa1;
          font-size: 1rem;
          font-weight: 700;
          height: 2.813rem;
          padding-right: 2.375rem;
          padding-left: 2.375rem;
          border-radius: 2rem;

          &:active {
            background-color: #fff !important;
            color: #959aa1 !important;
            border: 1px solid #959aa1 !important;
          }
        }

        .form-group {
          .form-control {
            height: 2.875rem;
            border: 0.125rem solid #e2e2e2;
            box-sizing: border-box;
            border-radius: 1.5625rem;
            padding: 0 1.75rem;
            font-size: 1rem;
            color: $color-mine-shaft;
          }

          textarea.form-control {
            border: 2px solid #edeff3;
            border-radius: 1.25rem;
            font-size: 1rem;
            color: #333;
            font-weight: 400;
            padding: 1rem;
            height: unset;
            padding-top: 0.5rem;
            padding-bottom: 0.5rem;
          }
        }

        .drag-drop-input {
          border: 1px dashed #46608a;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 0.375rem;
          height: 5.625rem;
          min-height: 5.625rem;

          &.squad {
            width: 10rem;
          }

          .dropzone {
            width: 100%;
            min-height: 5.625rem;
            height: 100%;

            .dropzone-content {
              height: 100%;
              padding-left: 2.5rem;
              padding-right: 2.5rem;
              display: flex;
              align-items: center;
              justify-content: center;
              flex-direction: column;
              min-height: 5.625rem;
            }

            &:focus {
              border: none;
            }

            p {
              color: #6d6d6f;
              font-size: 0.75rem;
              font-weight: 400;

              span {
                color: #46608a;
                font-size: 0.75rem;
                font-weight: 700;
              }
            }

            .image {
              width: 100%;
              height: 100%;
              border-radius: 0.75rem;
              object-fit: cover;
            }

            .text-content {
              color: #6d6d6f;
              font-size: 0.75rem;
              font-weight: 400;
            }
          }
        }

        .select-box {
          position: relative;
          cursor: pointer;

          .type-dropdown {
            appearance: none;
            -moz-appearance: none;
            -webkit-appearance: none;
            cursor: pointer;
          }

          img {
            position: absolute;
            right: 1rem;
            top: 1.25rem;
          }
        }

        .block-picker {
          margin-left: 2.5rem;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;

          .color-btn {
            position: relative;
            width: 3.125rem;
            height: 2.625rem;
            border-radius: 0.25rem;
            border: none;
          }

          .color-picker-wrapper {
            position: absolute;
            right: -3.75rem;
            top: 3.5rem;
            z-index: 1;
          }

          .label {
            color: #6d6d6f;
            font-weight: 600;
            font-size: 0.75rem;
          }
        }

        .upload-image {
          width: 3.875rem;
          min-width: 3.875rem;
          height: 3.875rem;
          border-radius: 100%;
          position: relative;

          .btn-upload {
            background: none !important;
            border: none !important;
            position: absolute;
            right: -1.5rem;
            top: -0.5rem;
            z-index: 100;
          }

          .image-view {
            width: 100%;
            height: 100%;
            border-radius: 100%;
          }
        }

        .redirect-block {
          display: flex;
          align-items: center;
          height: 4.625rem;
          width: 21rem;
          border: 1px solid rgba(196, 196, 196, 0.5);
          border-radius: 0.75rem;
          padding: 1rem;
          justify-content: space-between;

          &__logo {
            width: 2.625rem;
            height: 2.625rem;
            border-radius: 100%;
            background: #46608a;
            opacity: 0.7;
            display: flex;
            align-items: center;
            justify-content: center;
          }

          &__title {
            color: 333333;
            font-size: 1rem;
            font-weight: 600;
          }

          &__time {
            color: rgba(40, 40, 40, 0.5);
            font-size: 0.75rem;
            font-weight: 400;
          }
        }

        .add-item-button {
          color: #f36328;
          font-weight: 700;
          font-size: 1rem;
          background: unset;
          border: none;

          &:active {
            background: unset;
            color: #f36328;
          }
        }
      }
    }

    .sidebar {
      width: 295px;
      background-color: #fff;
      padding: 24px;

      .slider {
        margin-bottom: 16px;

        &__content {
          width: 251px;
          height: 148px;
          border-radius: 8px;
          border: 2px solid $color-gray;
          margin-bottom: 16px;
          background-color: unset;
          padding: 0;
          position: relative;

          &--active {
            border: 2px solid $color-tangerine;
          }

          .number-button {
            z-index: 1;
            position: absolute;
            top: 4px;
            left: 4px;
            padding: 4px 8px;
            font-size: 14px;
            line-height: 14px;
            font-weight: 700;
            color: #fff;
            background-color: #959aa2;
            border-radius: 4px;
            display: inline-block;

            &--active {
              background-color: #f09b00;
            }
          }
        }

        &__title {
          font-size: 14px;
          line-height: 14px;
          color: #333;
          font-weight: 700;
        }
      }
    }

    .competition {
      display: flex;
      flex-wrap: wrap;
      width: 400px;
      height: 240px;
      position: relative;
      margin-top: 100px;
      margin-bottom: 100px;

      .input-competition {
        position: absolute;
        width: 170px;

        &--top {
          top: -70px;
          left: 120px;
        }

        &--bottom {
          bottom: -70px;
          left: 120px;
        }

        &--left {
          left: -190px;
          top: 97px;
        }

        &--right {
          right: -190px;
          top: 97px;
        }
      }

      .top-left {
        width: 200px;
        height: 120px;
        border-bottom: 1px solid #c4c4c4;
        border-right: 1px solid #c4c4c4;

        .drag-drop-input {
          margin-left: -30px;
          margin-right: 30px;
        }
      }

      .top-right {
        width: 200px;
        height: 120px;
        border-bottom: 1px solid #c4c4c4;

        .drag-drop-input {
          margin-right: -30px;
          margin-left: 30px;
        }
      }

      .bot-left {
        width: 200px;
        height: 120px;
        border-right: 1px solid #c4c4c4;
        padding-top: 30px;

        .drag-drop-input {
          margin-left: -30px;
          margin-right: 30px;
        }
      }

      .bot-right {
        width: 200px;
        height: 120px;
        padding-top: 30px;

        .drag-drop-input {
          margin-right: -30px;
          margin-left: 30px;
        }
      }
    }
  }
}

.modal-preivew {
  .modal-lg {
    min-width: 1282px;
    width: 1282px;
  }
}
