.target-market-wrapper {
  &__ranger-block {
    padding-top: 2rem;
  }

  &__ranger {
    padding-top: 0.3rem;
  }

  &__title {
    font-family: 'Proxima Nova', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 1rem;
    color: #000;
  }

  &__description {
    font-family: 'Proxima Nova', sans-serif;
    font-style: normal;
    font-weight: normal;
    line-height: 1.063rem;
    font-size: 0.875rem;
    color: #535353;
  }

  &__heading {
    box-sizing: border-box;
    border-radius: 1rem;
    background: rgba(#edeff3, 0.5);
    height: 4.625rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 1.875rem;
    padding-right: 1.875rem;
    width: 100%;
    text-decoration: none !important;
  }

  &__item {
    border: 2px solid #edeff3;
    box-sizing: border-box;
    border-radius: 1.25rem;
    margin: 1.875rem 0;

    &--arrow-icon {
      cursor: pointer;
    }
  }

  &__data {
    padding: 0.813rem 1.25rem;
  }

  &__business-model {
    padding: 0.813rem 1.25rem;
  }

  &__large-dropinput {
    width: 100%;
    min-height: 11.5rem !important;
  }

  &__add-button {
    background: none !important;
    border: none !important;
    font-size: 1rem;
    color: #f36328;
    font-weight: bold;

    &:hover {
      color: #f36328;
    }
  }

  &__delete-button {
    background: none !important;
    border: none !important;
    font-size: 1rem;
    color: #959aa2 !important;
    font-weight: bold;
  }
}
