.blog-detail-page-wrapper {
  position: relative;

  .decor-left {
    position: absolute;
    left: 0;
    bottom: 3.5rem;
    z-index: -1;
  }

  .decor-right {
    position: absolute;
    right: 0;
    bottom: 1rem;
    z-index: -1;
  }

  .content {
    margin-top: -14.125rem;
    padding: 0 6.25rem 3.125rem 6.25rem;
    position: relative;

    .similar-articles {
      background-color: $color-sandy-beach !important;
      border: none !important;
      border-radius: 0.75rem !important;
      position: relative;
      padding: 2.5rem 3.25rem 3.75rem 3.25rem;

      .img-decor {
        position: absolute;
        top: 0;
        right: 0;
      }

      .card-title {
        font-family: 'Kazimir Text', sans-serif;
        color: rgba(40, 40, 40, 0.8);
        font-size: 2.1875rem;
        font-weight: bold;
        margin-bottom: 2rem;
      }

      .card-body {
        padding: 0;

        .blog-img {
          object-fit: cover;
        }

        .blog-title {
          color: #535353;
          font-family: 'Kazimir Text', sans-serif;
          font-weight: 900;
          font-size: 1.25rem;
        }

        .blog-date {
          color: rgba(40, 40, 40, 0.6);
          font-size: 0.875rem;
        }
      }
    }

    .footer-content {
      display: flex;
      justify-content: space-between;

      span {
        font-size: 1.125rem;
        font-weight: 700;
        color: $color-black;
      }
    }

    .content-text {
      margin: 0 6.25rem;

      p {
        font-weight: 400;
        font-size: 1rem;
        color: $color-mid-gray;
      }

      br {
        line-height: 30px;
        display: block;
        content: '';
      }

      .img-content-haft {
        width: 40%;
        float: left;
      }
    }

    .banner-content {
      margin: 2rem 6.25rem !important;

      .header {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        margin-top: 12rem;

        .favorite-btn {
          margin-bottom: 0.5rem;
        }
      }

      .body {
        font-size: 2.125rem;
        color: $color-mine-shaft;
        font-family: 'Kazimir Text', sans-serif;
        font-weight: 900;
        line-height: 120%;
      }

      .footer {
        display: flex;
        justify-content: space-between;

        .author {
          display: flex;
          justify-content: space-between;
          align-items: center;

          .author-name {
            font-size: 1rem;
            font-weight: bold;
            border-right: 1px solid $color-light-gray2;
            color: rgba(40, 40, 40, 0.6);
          }

          .date {
            font-weight: 400;
            font-size: 0.75rem;
            color: rgba(40, 40, 40, 0.6);
          }
        }

        .share {
          font-size: 1rem;
          color: $color-gray3;
        }
      }
    }
  }
}
