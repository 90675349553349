.downloadable-product-page-wrapper {
  .content {
    padding: 1.25rem 6.25rem 5rem 6.25rem;
    position: relative;

    .item-section {
      margin: 0 -2.5rem;
      margin-top: 4.375rem;

      .item-wrapper {
        padding: 0 2.5rem;
        display: flex;
        flex-direction: column;

        .img {
          object-fit: cover;
        }

        .title {
          color: rgba(40, 40, 40, 0.8);
          font-family: 'Kazimir Text', sans-serif;
          font-weight: 900;
          font-size: 2.5rem;
        }

        .desc {
          p,
          span,
          h1,
          h2,
          h3,
          h4,
          h5 {
            font-size: 1rem;
            color: #6d6d6f;
          }
        }

        .bottom {
          flex-grow: 1;
          display: flex;
          flex-direction: column;
          justify-content: flex-end;

          .price {
            color: $color-red;
            font-weight: bold;
            font-size: 2.1875rem;

            span {
              color: $color-gray2;
              font-weight: 300;
              text-decoration-line: line-through;
            }
          }

          .download-btn {
            background-color: $color-light-blue !important;
            font-size: 1.125rem;
            font-weight: bold;
            color: white !important;
            border-radius: 1.75rem;
            height: 3.5rem;
            padding: 0 2.5rem !important;
          }

          .share {
            font-size: 1rem;
            color: rgba(146, 146, 146, 0.6);
            font-weight: bold;
            text-align: center;
          }
        }
      }
    }

    .more-items-wrapper {
      background-color: rgba(255, 233, 195, 0.3);
      border: none !important;
      border-radius: 0.75rem;
      margin-top: 8.875rem;
      position: relative;

      .card-title {
        display: flex;
        justify-content: space-between;
        margin: 3.75rem;
        margin-top: 3.125rem;
        color: rgba(40, 40, 40, 0.8);
        font-family: 'Kazimir Text', sans-serif;
        font-weight: 900;
        font-size: 2.1875rem;
      }

      .card-body {
        padding: 0;
        margin-left: 3.75rem;
        margin-bottom: 5.625rem;

        .item-wrapper {
          .img {
            object-fit: cover;
          }

          .title {
            color: rgba(40, 40, 40, 0.8);
            font-family: 'Kazimir Text', sans-serif;
            font-weight: 900;
            font-size: 1.375rem;
          }
        }
      }

      .more-items-decor {
        position: absolute;
        top: 0;
        left: 60%;
        transform: translateX(-60%);
      }
    }

    .decor-left {
      position: absolute;
      left: 0;
      bottom: 0;
      margin-bottom: -3.4rem;
      z-index: -1;
    }

    .decor-right {
      position: absolute;
      right: 0;
      bottom: 0;
      margin-bottom: -3rem;
      z-index: -1;
    }
  }
}
