.billing-page-wrapper {
  padding: 3.875rem 2.5rem 2.5rem 2.5rem;

  .only-show-image-card {
    #field-wrapper {
      border: none !important;

      label,
      p {
        display: none !important;
      }

      img {
        width: 2.75rem;
        height: 2.75rem;
      }
    }
  }

  .title {
    font-weight: bold;
    font-size: 1.875rem;
  }

  .section-title {
    font-size: 1rem;
    font-weight: bold;
    letter-spacing: 2px;
    text-transform: uppercase;
    color: $color-gray2;
  }

  .plan-price {
    font-size: 1.125rem;
    font-weight: 400;

    span {
      font-weight: bold;
    }
  }

  .renewal-date {
    font-size: 1rem;
    color: $color-gray2;
  }

  .change-plan-btn,
  .change-plan-btn:active {
    width: 8.9375rem;
    height: 2.8125rem;
    border-radius: 9999px;
    border: none;
    background-color: rgba(70, 96, 138, 0.1) !important;
    color: $color-blue !important;
    font-weight: bold;
  }

  .cancel-plan-btn,
  .cancel-plan-btn:hover {
    color: #d02d2f !important;
    font-size: 0.875rem;
    font-weight: 600;
  }

  .last-digit {
    font-size: 1.125rem;
  }

  .expiration {
    font-size: 1.125rem;

    span {
      color: $color-gray2;
    }
  }

  table {
    margin: 0 !important;

    thead {
      background-color: #e2e2e2;
      border-radius: 2px;
      font-size: 1rem;
      font-weight: bold;
      color: $color-gray2;
      text-transform: none;
      letter-spacing: normal;

      th {
        border: none !important;
        padding: 0.5rem 0 !important;
        width: 40%;
      }

      th:first-child {
        padding-left: 1.25rem !important;
      }

      th:last-child {
        width: 11.5625rem;
      }
    }

    tbody {
      font-size: 1rem;
      color: $color-black;

      td {
        padding: 1.25rem 0 !important;
        vertical-align: middle !important;
      }

      td:first-child {
        padding-left: 1.25rem !important;
      }

      td:last-child {
        font-weight: bold;
        color: $color-blue;
      }

      .download {
        color: $color-blue;
      }

      tr:first-child {
        td {
          border-top: none !important;
        }
      }
    }
  }
}
