$color-blue: #46608a;
$color-orange: #f09b00;
$color-black: #282828;
$color-full-black: #000;
$color-dark-gray: #4f4f4f;
$color-light-gray: rgba(70, 96, 138, 0.05);
$color-gray: rgba(70, 96, 138, 0.08);
$color-light-blue: #46608a;
$color-red: #f36328;
$color-gray2: #959aa2;
$color-gray3: #929292;
$color-light-gray2: #edeff3;
$color-white: #fff;
$color-mine-shaft: #333;
$color-mid-gray: #6d6d6f;
$color-black-30percent: rgba(28, 28, 28, 0.3);
$color-black-80percent: rgba(28, 28, 28, 0.8);
$color-sandy-beach: #ffe9c3;
$color-mercury: #e7e7e7;
$color-star-dust: #9f9f9e;
$color-white-smoke: #f3f3f2;
$color-eunry: #d0a696;
$color-coral: #f47742;
$color-ghost-white: #f8f9fa;
$color-lavender: #e6eaef;
$color-tangerine: #f09b00;
$color-green2: #27ae60;
