.mini-window-wrapper {
  position: fixed;
  bottom: 1rem;
  right: 1rem;
  border-radius: 0.75rem;
  z-index: 9999;
  filter: drop-shadow(0 0.25rem 3.125rem rgba(0, 0, 0, 0.12));

  button.common-button {
    border: unset;
    background-color: unset;
  }

  .card {
    border: none;
    border-radius: 0.75rem;

    .card-body {
      padding: 1.25rem;
    }
  }

  .minimize-wrapper {
    background-color: #535353;
    width: 21.875rem;
    height: 3.75rem;
    border-radius: 0.75rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 1.375rem;
    padding-right: 1.375rem;

    .title {
      font-size: 1.125rem;
      font-family: 'Kazimir Text', sans-serif;
      color: #fff;
      font-weight: bold;
    }
  }

  .maximize-wrapper {
    width: 47rem;

    .card-header {
      background-color: unset;
    }

    .title {
      font-size: 1.563rem;
      font-family: 'Kazimir Text', sans-serif;
      color: $color-black;
      font-weight: bold;
    }
  }
}
