.smart-goal-item {
  &__delete-button {
    background: rgba(226, 226, 226, 0.3);
    border: none;
    border-radius: 50%;
    width: 3.125rem;
    height: 3.125rem;

    &:hover {
      background: rgba(226, 226, 226, 0.5);
    }

    svg {
      fill: #959aa2;
    }

    &:active {
      background-color: $color-red !important;

      svg {
        fill: #fff;
      }
    }
  }
}
