.thankyou-page-wrapper {
  position: relative;
  background-image: url("../image/thankyou_bg.png");
  background-size: cover;
  min-width: 100vw;
  min-height: 100vh;

  .content {
    position: absolute;
    left: 6.25rem;
    top: 2.5rem;

    .title {
      font-family: 'Kazimir Text', sans-serif;
      font-size: 4.0625rem;
      font-weight: bold;
      color: white;
      line-height: 115.3%;
    }

    .desc {
      font-size: 1.25rem;
      line-height: 1.5rem;
      color: white;
      opacity: 0.8;
    }

    .start-exploring-btn,
    .start-exploring-btn:active,
    .start-exploring-btn:hover {
      width: 16rem;
      height: 3.125rem;
      background-color: $color-orange;
      color: white;
      font-size: 1.125rem;
      font-weight: bold;
      border-radius: 1.5625rem;
      border: none;
    }
  }
}
