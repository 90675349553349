.business-plan-builder-wrapper {
  &__admin {
    padding: 0;
  }

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    &__title {
      font-size: 1.5rem;
      font-weight: 900;
      color: #333;
      font-family: 'Kazimir Text', sans-serif;
    }

    &__preview {
      display: flex;
      align-items: center;
      justify-content: center;

      .preview-button {
        background: none;
        font-weight: 700;
        color: #f36328;
        height: 2.813rem;
        width: 15rem;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 1rem;
        border: 1px solid #f36328;
        border-radius: 10rem;

        &:active {
          background-color: #f36328;
          border: none;
        }

        .beta-tag {
          width: 33px;
          height: 14px;
          margin-left: 8px;
          background: #f09b00;
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          padding: 3px 5px;
          border-radius: 30px;
          font-size: 8px;
          border: none;
          font-weight: 800;
        }
      }

      .view-sample {
        background-color: unset !important;
        border: none !important;
        color: #46608a !important;
        font-weight: 700;
        font-size: 1rem;
        margin-right: 2rem;
      }
    }
  }

  .financial-model {
    &__title-year {
      width: 19.5%;
      background-color: #fffbf3;
      color: #6d6d6f;
      font-weight: 700;
      font-size: 1rem;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 2.688rem;
    }

    &__title-year-financial {
      background-color: #fef3ee;
    }

    &__input {
      width: 19.5%;
      background-color: #fffbf3;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 4.875rem;
      padding: 1rem 0.5rem;

      input {
        padding: 0 0.75rem !important;
      }
    }

    &__input-financial {
      width: 19.5%;
      background-color: #fef3ee;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 4.875rem;
      padding: 1rem 0.5rem;

      input {
        padding: 0 0.75rem !important;
      }
    }

    &__result {
      font-size: 1rem;
      width: 19.5%;
      font-weight: 700;
      color: #46608a;
      background-color: #f0f2f6;
      height: 4.375rem;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &__clear_field {
      font-weight: 700;
      color: #959aa1;
      border: 1px solid #959aa1;
      background-color: #fff;
      height: 2.625rem;
      width: 8.25rem;
      border-radius: 6.25rem;

      &:active {
        color: #959aa1 !important;
        border: 1px solid #959aa1 !important;
        background-color: #fff !important;
      }
    }

    &__export {
      font-weight: 700;
      color: #677d9f;
      background-color: #edeff3;
      border-radius: 6.25rem;
      height: 2.625rem;
      width: 17.625rem;
      border: none;
      justify-content: center;

      &:active {
        color: #677d9f !important;
        border: none !important;
        background-color: #edeff3 !important;
      }
    }

    &__beta-tag {
      width: 33px;
      height: 14px;
      margin-left: 8px;
      background: #f09b00;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 3px 5px;
      border-radius: 30px;
      font-size: 8px;
      border: none;
      font-weight: 800;
      margin-top: 4px;
    }

    &__save {
      font-weight: 700;
      color: #677d9f;
      background-color: #edeff3;
      border-radius: 6.25rem;
      height: 2.625rem;
      width: 8.625rem;
      border: none;
      margin-right: -12rem;

      &:active {
        color: #677d9f !important;
        border: none !important;
        background-color: #edeff3 !important;
      }
    }

    &__company-type-button {
      height: 3.75rem;
      width: 30%;
      color: #333;
      border: 2px solid #edeff3;
      border-radius: 2.75rem;
      background-color: #fff;

      &:active {
        background-color: #f36328 !important;
        border: none !important;
      }

      &__active {
        background-color: #f36328;
        color: #fff;
        border: none;
      }

      &__title {
        font-size: 1rem;
        font-weight: 700;
      }

      &__description {
        font-size: 0.75rem;
        font-weight: 400;
      }
    }
  }

  .content {
    margin-top: 1rem;
    border-radius: 0.75rem;
    border: 1px solid rgba(196, 196, 196, 0.5);
    background-color: #fff;
    padding: 1.875rem 1.875rem;

    &__competitor-analysis {
      overflow: auto;
      min-width: 800px;
      height: 20.6rem;
      border: 2px solid #edeff3;
      box-sizing: border-box;
      border-radius: 1.25rem;

      &--name {
        // width: 14.3rem;
        width: 150px;
      }

      &--market-share {
        // width: 9.5rem;
        width: 150px;
      }

      &--price {
        // width: 9.75rem;
        width: 150px;
      }

      &--comparative-strength {
        // width: 15.25rem;
        width: 150px;
      }

      &--comparative-weakness {
        // width: 15.25rem;
        width: 150px;
      }

      .table-responsive {
        // max-width: 57.5rem;
        width: 100%;
        overflow: auto;

        table {
          thead {
            th {
              padding: 0.5rem !important;

              &:first-child {
                padding-left: 0.25rem !important;
              }
            }
          }

          tbody {
            td {
              padding: 0.25rem !important;

              &:first-child {
                padding-left: 0.25rem !important;
              }

              input {
                border: none !important;
                background: rgba(237, 239, 243, 0.5);
                border-radius: 0 !important;
                padding: 0 0.5rem !important;
              }
            }
          }
        }
      }
    }

    &__market-analysis-circle-summary {
      &--first-item {
        width: 22rem;
        height: 22rem;
        box-shadow: 0 -4px 80px rgb(0 0 0 / 12%);
        border-radius: 50%;
        position: relative;
      }

      &--first-item-border {
        width: 22rem;
        height: 22rem;
        border-radius: 50%;
        position: relative;
        border: 1px solid #ddd;
      }

      &--second-item {
        width: 16rem;
        height: 16rem;
        box-shadow: 0 -4px 80px rgba(0, 0, 0, 0.25);
        border-radius: 50%;
        position: absolute;
        bottom: 0;
        left: 3rem;
      }

      &--second-item-border {
        width: 16rem;
        height: 16rem;
        border-radius: 50%;
        position: absolute;
        bottom: 0;
        left: 3rem;
        border: 1px solid #ddd;
      }

      &--third-item {
        width: 11rem;
        height: 11rem;
        box-shadow: 0 -4px 80px rgba(0, 0, 0, 0.25);
        border-radius: 50%;
        position: absolute;
        bottom: 0;
        left: 2.5rem;
        padding-top: 1rem;
      }

      &--third-item-border {
        width: 11rem;
        height: 11rem;
        border-radius: 50%;
        position: absolute;
        bottom: 0;
        left: 2.5rem;
        padding-top: 1rem;
        border: 1px solid #ddd;
      }

      &__title {
        font-size: 1.125rem;
        color: #fff;
        text-transform: uppercase;
        font-weight: bold;
      }

      &__description {
        font-size: 0.75rem;
        color: #fff;
        font-weight: normal;
      }
    }

    &__startup-cost {
      &--first-input {
        width: 65%;
      }

      &--second-input {
        width: 20%;
      }

      &--delete-icon {
        width: 5%;
        width: 3rem;
        height: 3rem;
        border-radius: 50%;
        background-color: rgba(226, 226, 226, 0.3);
        border: none;
      }
    }

    &__add-button {
      background: none !important;
      border: none !important;
      font-size: 1rem;
      color: #f36328 !important;
      font-weight: bold;
    }

    .round-chart-item {
      position: relative;
    }

    .round-chart-label {
      font-size: 0.875rem;
      font-weight: bold;
      color: #535353;
    }

    &__title {
      color: #46608a;
      font-size: 1rem;
      font-weight: 700;
    }

    &__input-title {
      color: #535353;
      font-size: 1rem;
      font-weight: 700;
    }

    &__des-block {
      background: rgba(255, 233, 195, 0.2);
      font-size: 0.875rem;
      color: #6d6d6f;
      padding: 0.5rem;
      margin-top: 1rem;
    }

    &__input-medium-title {
      color: #282828;
      font-size: 1.125rem;
      font-weight: 600;
    }

    &__text {
      color: #6d6d6f;
      font-size: 0.875rem;
      font-weight: 400;
    }

    &__recommend {
      font-style: italic;
      color: #6d6d6f;
      font-size: 0.75rem;
    }

    &__button-submit {
      color: #fff;
      background-color: #f36328;
      font-size: 1rem;
      font-weight: 700;
      height: 2.813rem;
      padding-right: 2.375rem;
      padding-left: 2.375rem;
      border-radius: 2rem;
      border: none;

      &:active {
        background-color: #f36328 !important;
        border: none;
      }
    }

    &__button-save {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 0;
      gap: 7.5px;
      border: 1px dashed #959aa2;
      border-radius: 31px;
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 19px;
      text-align: center;
      color: #959aa2;
      width: 164px;
      height: 45px;
      background: white;
    }

    &__beta-tag {
      width: 33px;
      height: 14px;
      margin-left: 8px;
      background: #f09b00;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 3px 5px;
      border-radius: 30px;
      font-size: 8px;
      border: none;
      font-weight: 800;
      margin-top: 5px;
    }

    &__back-button {
      color: #959aa1;
      background-color: #fff;
      border: 1px solid #959aa1;
      font-size: 1rem;
      font-weight: 700;
      height: 2.813rem;
      padding-right: 2.375rem;
      padding-left: 2.375rem;
      border-radius: 2rem;

      &:active {
        background-color: #fff !important;
        color: #959aa1 !important;
        border: 1px solid #959aa1 !important;
      }
    }

    .form-group {
      .form-control {
        height: 2.875rem;
        border: 0.125rem solid #e2e2e2;
        box-sizing: border-box;
        border-radius: 1.5625rem;
        padding: 0 1.75rem;
        font-size: 1rem;
        color: $color-mine-shaft;
      }

      textarea.form-control {
        border: 2px solid #edeff3;
        border-radius: 1.25rem;
        font-size: 1rem;
        color: #333;
        font-weight: 400;
        padding: 1rem;
        height: unset;
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
      }
    }

    .drag-drop-input {
      border: 1px dashed #46608a;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 0.375rem;
      height: 5.625rem;
      min-height: 5.625rem;
      cursor: pointer;
      position: relative;

      &.squad {
        width: 10rem;
      }
    }

    .select-box {
      position: relative;
      cursor: pointer;

      .type-dropdown {
        appearance: none;
        -moz-appearance: none;
        -webkit-appearance: none;
        cursor: pointer;
      }

      img {
        position: absolute;
        right: 1rem;
        top: 1.25rem;
      }
    }

    .block-picker {
      margin-left: 2.5rem;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .color-btn {
        position: relative;
        width: 3.125rem;
        height: 2.625rem;
        border-radius: 0.25rem;
        border: none;
      }

      .color-picker-wrapper {
        position: absolute;
        right: -3.75rem;
        top: 3.5rem;
        z-index: 1;
      }

      .label {
        color: #6d6d6f;
        font-weight: 600;
        font-size: 0.75rem;
      }
    }

    .upload-image {
      width: 3.875rem;
      min-width: 3.875rem;
      height: 3.875rem;
      border-radius: 100%;
      position: relative;

      .btn-upload {
        background: none !important;
        border: none !important;
        position: absolute;
        right: -1.5rem;
        top: -0.5rem;
        z-index: 100;
      }

      .image-view {
        width: 100%;
        height: 100%;
        border-radius: 100%;
      }
    }

    .redirect-block {
      display: flex;
      align-items: center;
      height: 4.625rem;
      width: 21rem;
      border: 1px solid rgba(196, 196, 196, 0.5);
      border-radius: 0.75rem;
      padding: 1rem;
      justify-content: space-between;

      &__logo {
        width: 2.625rem;
        height: 2.625rem;
        border-radius: 100%;
        background: #46608a;
        opacity: 0.7;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      &__title {
        color: 333333;
        font-size: 1rem;
        font-weight: 600;
      }

      &__time {
        color: rgba(40, 40, 40, 0.5);
        font-size: 0.75rem;
        font-weight: 400;
      }
    }

    .add-item-button {
      color: #f36328;
      font-weight: 700;
      font-size: 1rem;
      background: unset;
      border: none;

      &:active {
        background: unset;
        color: #f36328;
      }
    }
  }

  .group-chart {
    &__title {
      float: right;
      width: 100%;
    }

    &__input {
      width: 3rem;
      max-height: 1.68rem !important;
      min-height: 1rem !important;
    }

    &__text {
      font-size: 0.75rem;
      font-weight: normal;
      color: #959aa2;
    }

    &__bar-item {
      width: 19.5%;
      position: relative;
    }

    .percent-label {
      position: absolute;
      left: 52%;
      color: #959aa2;
      font-size: 0.875rem;
      top: 0.25rem;
    }
  }

  .chartjs-render-monitor {
    width: 100% !important;
  }

  .financial-input {
    width: 12.5rem;
    margin-right: 1rem;
    margin-left: 2rem;
  }
}
