.reminder-item-wrapper {
  background: rgba(255, 255, 255, 0.7);
  border-radius: 0.75rem;
  display: flex;
  align-items: center;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
  cursor: pointer;

  .reminder-time {
    font-size: 0.75rem;
  }

  .reminder-title {
    font-family: 'Kazimir Text', sans-serif;
    font-size: 0.875rem;
    font-weight: 900;
  }

  .reminder-tag {
    font-size: 0.75rem;
  }

  .reminder-avatars {
    display: inline-flex;
    flex-direction: row-reverse;

    .reminder-avatar:not(:last-child) {
      margin-left: -0.4375rem;
    }

    .reminder-avatar-more {
      width: 1.375rem;
      height: 1.375rem;
      margin-left: -0.4375rem;
      border-radius: 0.65625rem;
      background-color: $color-red;
      font-size: 0.5625rem;
      font-weight: bold;
      color: white;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
