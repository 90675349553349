.breadcrumb-wrapper {
  display: flex;
  align-items: center;

  .normal-item {
    color: $color-gray2;
    font-weight: 700;
    font-size: 0.75rem;
  }

  .active-item {
    color: $color-red;
    font-weight: 700;
    font-size: 0.75rem;
  }
}

.breadcrumb-wrapper-light {
  display: flex;
  align-items: center;

  .normal-item {
    color: $color-light-gray2;
    font-weight: 700;
    font-size: 0.75rem;
  }

  .active-item {
    color: $color-sandy-beach;
    font-weight: 700;
    font-size: 0.75rem;
  }
}
