.impact-effort-wrapper {
  width: 100%;
  box-sizing: border-box;
  padding: 40px 60px 67px 60px;
  border-top: 1px solid $color-gray;

  .matrix {
    width: 100%;
    background: rgba(237, 239, 243, 0.5);
    border: 1px solid $color-gray;
    border-radius: 16px;
    min-height: 700px;
    box-sizing: border-box;
    position: relative;
    display: flex;
    flex-wrap: wrap;

    .cell {
      height: 10%;
      min-height: 70px;
      width: 10%;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;

      &__item {
        width: 24px;
        height: 24px;
        border-radius: 100%;
        color: #fff;
        font-weight: 700;
        font-size: 12px;
        margin-right: 5px;
        margin-bottom: 5px;
        justify-content: space-around;
        align-items: center;
        display: flex;
        border: none;

        &--now {
          background-color: $color-blue !important;
        }

        &--next {
          background-color: $color-tangerine !important;
        }

        &--later {
          background-color: $color-red !important;
        }

        &--done {
          background-color: $color-green2 !important;
        }
      }
    }

    .y-arrow {
      width: 0;
      height: 100%;
      border-right: 2px dashed $color-gray2;
      position: absolute;
      left: calc(50% - 1px);
      opacity: 0.75;
    }

    .x-arrow {
      width: 100%;
      height: 0;
      border-bottom: 2px dashed $color-gray2;
      position: absolute;
      opacity: 0.75;
      top: calc(50% - 1px);
    }

    .quick-wins {
      position: absolute;
      top: 12px;
      left: 16px;
      color: $color-gray2;
      font-size: 14px;
      font-weight: 600;
    }

    .time-sinks {
      position: absolute;
      bottom: 12px;
      right: 16px;
      color: $color-gray2;
      font-size: 14px;
      font-weight: 600;
    }

    .impact-box {
      position: absolute;
      top: 0;
      left: -46px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100%;
      padding-top: 25px;
      padding-bottom: 25px;

      .arrow {
        color: $color-gray2;
        font-size: 10px;
        font-weight: bold;
        transform: rotate(-90deg);
      }

      .center {
        color: $color-blue;
        padding-right: 12px;
        font-size: 14px;
        font-weight: bold;
        transform: rotate(-90deg);
      }
    }

    .effort-box {
      position: absolute;
      left: 0;
      bottom: -40px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      width: 100%;

      .arrow {
        color: $color-gray2;
        font-size: 10px;
        font-weight: bold;
      }

      .center {
        padding-top: 12px;
        color: $color-blue;
        font-size: 14px;
        font-weight: bold;
      }
    }
  }

  .tooltip-container {
    background-color: #fff;
    padding: 11px 16px;
    border-radius: 4px;
    display: flex;
    align-items: center;
    margin-right: calc(40px - 50%);
    margin-left: calc(50% - 40px);
    margin-top: -10px;

    span {
      color: #6d6d6f;
      font-size: 12px;
      font-weight: 600;
    }

    .dot {
      width: 8px;
      height: 8px;
      border-radius: 1px;
      margin-right: 6px;

      &--now {
        background-color: $color-blue;
      }

      &--next {
        background-color: $color-tangerine;
      }

      &--later {
        background-color: $color-red;
      }

      &--done {
        background-color: $color-green2;
      }
    }
  }
}
