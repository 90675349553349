@charset 'UTF-8';

// vendor
@import '~bootstrap/scss/bootstrap';
@import 'react-toastify/scss/main.scss';
@import 'react-datepicker/src/stylesheets/datepicker.scss';

// app
@import 'base';
@import 'app_footer';
@import 'app_header';
@import 'default_tag_input';
@import 'funding_statup';
@import 'startup_investment';
@import 'company_profile';

// common components
@import 'breadcrumb';
@import 'reminder_calendar';
@import 'mini_window';
@import 'tooltip';
@import 'modal_custom';

// onboarding
@import 'onboarding';
@import 'login';
@import 'reset_password';
@import 'signup';
@import 'create_account';
@import 'thankyou';
@import 'email_verify';

// dashboard
@import 'dashboard';
@import 'dashboard_founders_library_widget';
@import 'dashboard_founders_library_my_favorite';
@import 'dashboard_personal_crm_contact';
@import 'dashboard_personal_crm_reminder';
@import 'dashboard_personal_crm_task';
@import 'dashboard_personal_crm_widget';
@import 'dashboard_startup_starter_widget';
@import 'dashboard_startup_starter';

// founders library
@import 'founders_library';
@import 'founders_library_header';
@import 'founders_library_podcast_widget';
@import 'founders_library_blog_widget';
@import 'founders_library_downloadable_widget';
@import 'blogs';
@import 'blog_detail';
@import 'downloadables';
@import 'downloadable_product';
@import 'podcasts';
@import 'podcast_detail';
@import 'videos';
@import 'video_detail';
@import 'favorites';

// personal crm
@import 'personal_crm';
@import 'personal_crm_contact_widget';
@import 'personal_crm_reminder_widget';
@import 'reminder_item';
@import 'task_item';
@import 'personal_crm_note_widget';
@import 'personal_crm_task_widget';
@import 'personal_crm_create_contact';
@import 'personal_crm_create_note';
@import 'personal_crm_contact_detail';
@import 'personal_crm_contact_detail_widget';
@import 'personal_crm_activity_widget';
@import 'personal_crm_import_contacts';
@import 'personal_crm_connect_sources';
@import 'personal_crm_view_mini_popup';

// user settings
@import 'settings_layout';
@import 'settings_sidebar';
@import 'settings_my_account';
@import 'settings_billing';
@import 'settings_payment_plans';
@import 'settings_billing_method';
@import 'settings_notifications';
@import 'settings_users';
@import 'billing_method';
@import 'cropper_image';

// startup starter
@import 'startup_starter';
@import 'modal_startup_starter';
@import 'competitor';
@import 'startup_starter_item';
@import 'startup_starter_overview';
@import 'export_pdf';
@import 'startup_overview_founder';
@import 'startup_overview_metric';
@import 'startup_overview_info';
@import 'startup_starter_layout_wrapper';
@import 'startup_starter_business_model_canvas';
@import 'startup_starter_business_model_canvas_card';
@import 'elevator_pitch_wrapper';
@import 'elevator_pitch_update';
@import 'positioning_statement';
@import 'positioning_statement_update';
@import 'documents_wrapper';
@import 'upload_document';
@import 'revenue_estimate';
@import 'startup_starter_notes';
@import 'document_item';
@import 'business_plan_builder';
@import 'pitch_deck_builder';
@import 'pitch_deck_layout';
@import 'pitch_deck_preview';
@import 'business_plan_builder_marketing_plan';
@import 'orc_structure';
@import 'product_item';
@import 'business_plan_revenue';
@import 'target_market_item';
@import 'smart_goal_item';
@import 'marketing_channel';
@import 'marketing_strategy';
@import 'orgchart';
@import 'unlock_screen';
@import 'modal_pdf_viewer';

// admin
@import 'admin_dashboard_header';
@import 'admin_dashboard_sidebar';
@import 'admin_dashboard_layout';
@import 'admin_dashboard_common';
@import 'admin_dashboard_overview';
@import 'admin_dashboard_posts';
@import 'admin_dashboard_new_post';
@import 'admin_dashboard_categories';
@import 'admin_dashboard_new_category';
@import 'admin_dashboard_authors';
@import 'admin_dashboard_plans';
@import 'admin_dashboard_search_result';
@import 'admin_dashboard_users.scss';
@import 'empty_result';
@import 'product_roadmap';
@import 'sidebar_timeline';
@import 'modal_create_timeline';
@import 'kanban';
@import 'impact-effort';
@import 'admin_startup_header';
@import 'admin_startup';
@import 'modal_business_model';

html,
body {
  margin: 0;
  padding: 0;
  font-size: 16px;
  font-family: 'Proxima Nova', sans-serif;
  color: $color-black;
}

input {
  &::placeholder {
    color: #959aa1 !important;
  }
}

textarea {
  &::placeholder {
    color: #959aa1 !important;
  }
}

textarea,
input,
button,
label,
a {
  font-size: 1rem;
  font-family: 'Proxima Nova', sans-serif;
  color: $color-black;
}

button {
  cursor: pointer;
}

p {
  margin-bottom: 0;
}

.item-click {
  cursor: pointer;
}

textarea:hover,
input:hover,
textarea:active,
input:active,
textarea:focus,
input:focus,
button:focus,
button:active,
button:hover,
label:focus,
.btn:active,
.btn.active {
  outline: 0 !important;
  box-shadow: none !important;
}

.link-btn,
.link-btn:hover,
.link-btn:active {
  border: none !important;
  background: none !important;
  padding: 0 !important;
  color: $color-black !important;
}

.yes-btn,
.yes-btn:hover,
.yes-btn:active {
  border: none !important;
  background: $color-red !important;
  color: white !important;
}

.no-btn,
.no-btn:hover,
.no-btn:active {
  border: 1px solid $color-gray2 !important;
  background-color: $color-ghost-white !important;
  color: $color-gray2 !important;
}

.rounded {
  border-radius: 0.4375rem !important;
}

a:hover {
  color: $color-black;
  text-decoration: none;
}

.dropdown-item {
  padding: 0.75rem 1.5rem;
}

.dropdown-item.active,
.dropdown-item:active {
  background-color: rgba(70, 96, 138, 0.12);
  color: $color-blue;
}

.modal-content {
  border-radius: 0.75rem;

  .close {
    font-size: 2rem;
  }
}

.form-control {
  border-radius: 0.75rem !important;
  // padding: 0.5rem !important;
}

.form-control-item {
  margin-bottom: 1.25rem;
}

.form-control.is-invalid {
  background-position: right calc(0.375em + 1.1875rem) center;
}

.color-control-item {
  display: flex;
  flex-direction: column;
  margin-top: 4.1rem;
  text-align: center;
  align-items: center;
}

.dropdown-toggle {
  &:focus {
    background-color: #fff !important;
    border: none !important;
  }
}

.__react_component_tooltip {
  background-color: unset !important;
  opacity: 1 !important;

  &::before {
    display: none;
  }

  &::after {
    display: none;
  }
}

iframe {
  width: 100%;
}

.CircularProgressbar {
  width: 100%;
  vertical-align: middle;

  .CircularProgressbar-path {
    stroke: #3e98c7;
    stroke-linecap: round;
    -webkit-transition: stroke-dashoffset 0.5s ease 0s;
    transition: stroke-dashoffset 0.5s ease 0s;
  }

  .CircularProgressbar-trail {
    stroke: #d6d6d6;
    stroke-linecap: round;
  }

  .CircularProgressbar-text {
    fill: #3e98c7;
    font-size: 20px;
    dominant-baseline: middle;
    text-anchor: middle;
  }

  .CircularProgressbar-background {
    fill: #d6d6d6;
  }

  &.CircularProgressbar-inverted {
    .CircularProgressbar-background {
      fill: #3e98c7;
    }

    .CircularProgressbar-text {
      fill: #fff;
    }

    .CircularProgressbar-path {
      stroke: #fff;
    }

    .CircularProgressbar-trail {
      stroke: transparent;
    }
  }
}

#responsive-navbar-nav {
  .dropdown-menu {
    min-width: 250px;
    left: -85% !important;
  }

  .custom-control-input:checked ~ .custom-control-label::before {
    color: #fff;
    border-color: #45ae5a;
    background-color: #45ae5a;
  }

  .activity-funding-text {
    font-weight: 600;
    font-size: 0.875rem;
    color: #333;
  }

  .activity-funding-menu {
    padding: 0.5rem;
    border-bottom: 1px solid rgb(40, 40, 40, 0.2);
  }
}

.ck-editor__editable {
  max-height: 300px !important;
}

.ck-balloon-panel {
  z-index: 9999 !important;
}

.connection-text {
  font-size: 0.625rem;
  font-style: italic;
  color: #6d6d6f;
  text-transform: lowercase;
}

.lock-icon {
  position: absolute;
  right: 0;
}

.beta-tag {
  width: 33px;
  height: 14px;
  margin-left: 8px;
  background: #f09b00;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 3px 5px;
  border-radius: 30px;
  font-size: 8px;
  border: none;
  font-weight: 800;
}

.shadow-text {
  text-shadow: 5px 2px 8px #000, 0 0 4px #131315, 0 0 6px #131315;
}

.crm-tag {
  font-size: 0.875rem;
  color: $color-white;
  font-weight: bold;
  text-transform: uppercase;

  &:hover {
    color: $color-white;
  }

  &.light {
    color: #7a5c58;

    &:hover {
      color: #7a5c58;
    }
  }
}

.select-box {
  position: relative;
  cursor: pointer;

  .type-dropdown {
    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
    cursor: pointer;
  }

  img {
    position: absolute;
    right: 1rem;
    top: 1.25rem;
  }
}

.dropzone {
  width: 100%;
  min-height: 5.625rem;
  height: 100%;

  .dropzone-content {
    height: 100%;
    padding-left: 2.5rem;
    padding-right: 2.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    min-height: 5.625rem;
  }

  &:focus {
    border: none;
  }

  p {
    color: #6d6d6f;
    font-size: 0.75rem;
    font-weight: 400;

    span {
      color: #46608a;
      font-size: 0.75rem;
      font-weight: 700;
    }
  }

  .image {
    width: calc(100% + 2px);
    margin-left: -1px;
    height: 100%;
    border-radius: 0.375rem;
    object-fit: cover;
  }

  .text-content {
    color: #6d6d6f;
    font-size: 0.75rem;
    font-weight: 400;
  }
}

.hover-button-group {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: 100%;
  height: 90px;
  top: 0;

  .hover-button {
    width: 40px;
    height: 40px;
    background-color: #f09b00 !important;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none !important;
  }
}

.drag-drop-input {
  border: 1px dashed #46608a;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0.375rem;
  height: 5.625rem;
  min-height: 5.625rem;
  cursor: pointer;
  position: relative;

  &.squad {
    width: 10rem;
  }
}

.multi-selections-container {
  overflow-x: auto;
  border-radius: 0.75rem;
  min-height: 2.8125rem;
  height: 2.875rem;
  border: 0.125rem solid #e2e2e2;
  box-sizing: border-box;
  padding: 0.5rem 1.75rem;
  font-size: 1rem;
  color: #333;
  min-width: 100%;
  width: auto;
  display: flex;

  .multi-selections-input {
    outline: none;
    text-align: left;
    padding-left: 3px;
    height: 100%;
    display: flex;
    align-items: center;
    -webkit-user-modify: read-write;
    overflow-wrap: break-word;
    -webkit-line-break: after-white-space;

    &:focus {
      outline: none;
    }
  }

  .multi-selections-option {
    min-height: 100%;
    border-radius: 4px;
    border: 1px solid #222;
    display: flex;
    align-items: center;
    margin-right: 8px;
    padding: 3px 8px;
    color: #222;

    .text {
      white-space: nowrap;
    }

    .remove-btn {
      font-size: 10px;
      padding: 5px;
      padding-right: 0;
      line-height: 0;
      cursor: pointer;
    }
  }

  .placeholder {
    color: #959aa1;
  }
}

.auto-suggest-container {
  position: relative;

  .suggestion-list {
    position: absolute;
    top: calc(100% + 5px);
    left: 0;
    padding: 1.5rem;
    background-color: #fff;
    border: 1px solid #e2e2e2;
    border-radius: 0.5rem;
    z-index: 1;
    color: #222;
    width: 100%;
  }

  .suggestion-item {
    list-style-type: none;
    padding: 5px 0;
    cursor: pointer;
  }

  .suggestion-match {
    color: #f36328;
  }
}

.toast-custom {
  position: relative;
  display: flex;
  align-items: center;

  &::after {
    content: url("../image/toast_bg.svg");
    position: absolute;
    right: -43px;
    bottom: -6px;
  }

  &.toast-success {
    background-color: #10afa7;
  }

  &.toast-danger {
    background-color: #f36328;
  }

  .toast-body {
    padding: 0 0.75rem;
  }

  .toast-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background: rgb(255 255 255 / 35%);
    margin-right: 10px;
  }

  .toast-title {
    color: #fff;
    font-size: 18px;
    font-weight: 700;
    font-family: 'Proxima Nova', sans-serif;
  }

  .toast-message {
    color: #fff;
    font-family: 'Proxima Nova', sans-serif;
    font-size: 14px;
    line-height: 16.8px;
    font-weight: 400;
  }

  .Toastify__close-button {
    align-self: center;
    color: #fff;
    opacity: 1;
  }

  .Toastify__progress-bar {
    opacity: 0;
    visibility: hidden;
  }
}
