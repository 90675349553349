.dashboard-startup-starter-widget {
  background-color: #fef6e7 !important;
  border-radius: 0.75rem !important;
  border: none !important;
  position: relative;

  .card-description {
    color: #6d6d6f;
    font-size: 0.875rem;
    margin-bottom: 1rem;
  }

  .card-title {
    margin-bottom: 0.25rem;

    .title {
      a {
        display: inline-block;
        color: $color-black;
        margin-top: 1.875rem;
        margin-bottom: 0;
        font-family: 'Kazimir Text', sans-serif;
        font-weight: 900;
        font-size: 1.125rem;
      }
    }
  }

  .card-body {
    padding: 0;

    .description {
      padding-left: 1.875rem;
    }

    .startup-starters {
      margin: 0.875rem;
      top: 0;
      display: flex;
    }

    .create-new-wrapper {
      background-color: transparent !important;
      border-radius: 0.375rem !important;
      border: 2px dashed rgba(255, 255, 255, 0.7);
      cursor: pointer;

      .card-body {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }

      .create-new-text {
        color: white;
        font-size: 0.875rem;
        margin: 0 3.625rem;
      }
    }
  }

  .blog-wrapper {
    .blog-img {
      object-fit: cover;
    }

    .blog-title {
      color: #535353;
      font-family: 'Kazimir Text', sans-serif;
      font-weight: 900;
      font-size: 1.25rem;
    }

    .blog-date {
      color: rgba(40, 40, 40, 0.6);
      font-size: 0.875rem;
    }
  }

  .podcast-wrapper {
    padding: 1.875rem;

    .visit-podcasts {
      color: $color-blue;
      font-size: 0.875rem;
      font-weight: bold;
    }

    .podcast-img {
      object-fit: cover;
    }

    .podcast-title {
      span {
        color: rgba(40, 40, 40, 0.8);
        font-family: 'Kazimir Text', sans-serif;
        font-weight: 900;
        font-size: 1.125rem;
      }
    }

    .podcast-date {
      color: rgba(40, 40, 40, 0.6);
      font-size: 0.875rem;
    }
  }
}
