.new-title-group {
  display: flex;
  justify-content: flex-start;

  .title {
    font-size: 1.5625rem;
    font-weight: bold;
    letter-spacing: 0.125rem;
  }

  .select-box {
    position: relative;
    cursor: pointer;

    .type-dropdown {
      border-radius: 1.125rem;
      border: none;
      font-size: 0.875rem;
      font-weight: bold;
      height: 2.25rem;
      color: $color-light-blue;
      background-color: $color-lavender;
      width: unset;
      appearance: none;
      -moz-appearance: none;
      -webkit-appearance: none;
      padding-right: 2rem;
      cursor: pointer;
    }

    img {
      position: absolute;
      right: 1rem;
      top: 1rem;
    }
  }
}

.footer-group {
  margin: 0;

  button {
    border-radius: 1.1875rem;
    border: none;
    font-size: 1rem;
    height: 2.375rem;
    color: $color-gray2 !important;
    font-weight: bold;

    &.submit-button {
      background-color: #f36328 !important;
      color: $color-white !important;
      width: 10.5rem;
    }

    &.cancel-button {
      border: 1px solid $color-gray2 !important;
      background-color: $color-ghost-white !important;
      width: 7.0625rem;
    }
  }
}

.title-group {
  display: flex;
  justify-content: space-between;

  .sort-group {
    display: flex;
    align-items: center;

    .title {
      font-size: 1.5625rem;
      font-weight: bold;
      letter-spacing: 0.125rem;
    }

    span {
      font-size: 0.875rem;

      .sort-type {
        font-weight: bold;
        color: $color-light-blue;
        background: none;
        border: none;
        padding: 0;
      }
    }
  }

  .menu-group {
    display: flex;
    justify-content: space-between;

    button {
      font-size: 1rem;
      font-weight: bold;
      background-color: $color-light-gray2;
      color: $color-gray2;
      border-radius: 1.125rem;
      height: 2.375rem;
      padding: 0.4375rem 1.25rem;
      text-decoration: none;
      border: none;

      &.active {
        color: $color-white;
        background-color: $color-red;
      }
    }

    .add-button {
      font-size: 1rem;
      font-weight: bold;
      border-radius: 1.125rem;
      height: 2.375rem;
      padding: 0.4375rem 1.25rem;
      text-decoration: none;
      color: $color-white;
      background-color: $color-light-blue;
    }
  }
}

table {
  margin: 0 !important;

  thead {
    background-color: rgba(237, 239, 243, 0.5);
    font-weight: bold;
    font-size: 0.75rem;
    letter-spacing: 0.125rem;
    text-transform: uppercase;

    th {
      border: none !important;
      padding: 1rem 0 !important;
    }

    th:first-child {
      padding-left: 2.5rem !important;
    }

    th:last-child {
      padding-right: 2.5rem !important;
    }
  }

  tbody {
    font-size: 0.875rem;
    color: #333;

    td {
      padding: 1.875rem 0 !important;
      vertical-align: middle !important;
    }

    td:first-child {
      padding-left: 2.5rem !important;
    }

    tr:first-child {
      td {
        border-top: none !important;
      }
    }
  }
}

.pagination-section-footer {
  display: flex;
  justify-content: center;
  margin-top: 2.5rem;
  font-size: 1.125rem;

  .link-btn {
    // height: 20px;
  }
}

input.form-control,
textarea.form-control {
  border: 1px solid #e2e2e2;
  border-radius: 0.625rem;
  min-height: 2.8125rem;
  font-size: 1.125rem;
}

input.form-control-small,
textarea.form-control-small {
  border: 1px solid #e2e2e2;
  border-radius: 0.3125rem;
  min-height: 2.1875rem;
  font-size: 0.75rem;
}

.toggle-more {
  background-color: unset;
  border-color: unset;

  &:active,
  &:hover {
    background-color: unset !important;
    border-color: unset !important;
  }
}

.show {
  .toggle-more {
    background-color: unset !important;
    border-color: unset !important;
  }
}

.filters-btn {
  color: rgba(40, 40, 40, 0.6);
  font-size: 0.875rem;
  font-weight: normal;
  border: 1px solid rgba(40, 40, 40, 0.6);
  border-radius: 1.125rem;
  height: 2.375rem;
  padding: 0.4375rem 1.5rem;
  background: none;

  &:active {
    background-color: transparent !important;
    border: 1px solid rgba(40, 40, 40, 0.6) !important;
    color: rgba(40, 40, 40, 0.6) !important;
  }
}

.user-type-badge {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px 8px;
  border-radius: 30px;
  color: white;
  font-size: 14px;
  font-weight: 600;
  width: 88px;

  &.user-type-admin {
    color: #6d6d6f;
  }
}

.search-wrapper {
  background-color: white;
  border: 2px solid #e2e2e2;
  box-sizing: border-box;
  border-radius: 6.25rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 2.8125rem;
  width: 27rem;

  .search-input {
    font-size: 1rem;
    border: none;
    background: none;
    margin-left: 0.5rem;
  }

  .find-btn {
    background-color: $color-red !important;
    border-radius: 1.125rem;
    border: none;
    font-size: 0.875rem;
    font-weight: bold;
    min-width: 6.75rem;
    margin-right: 0.2rem;
    margin-bottom: 0.075rem;
  }

  &.search-wrapper-small {
    height: 2.375rem;
    width: 21rem;

    .find-btn {
      height: 1.8rem;
      line-height: 1;
    }
  }
}

.admin-dashboard-main-content {
  background-color: rgba(237, 239, 243, 0.4);
  padding: 3.3125rem;
  padding-bottom: 4.6875rem;
  min-height: calc(100vh - 80px);

  .posts-section {
    min-height: calc(100% - 154px);

    .card {
      border: 1px solid rgba(196, 196, 196, 0.5);
      border-radius: 0.75rem;
      overflow: hidden;

      .card-body {
        padding: 0;
      }

      &.card-grey {
        .card-body {
          padding: 1.875rem 2.1875rem;

          .form-field {
            font-size: 1.125rem;
          }

          .form-field-desc {
            color: $color-gray3;
            font-size: 0.875rem;
            font-style: italic;
          }
        }
      }
    }
  }
}

.tab-pane-inner {
  margin-top: 32px;

  .section-title {
    padding-bottom: 15px;
    border-bottom: 1px solid #edeff3;

    .title {
      font-weight: 700;
      text-transform: uppercase;
      color: #46608a;
      font-size: 16px;
    }
  }
}

.form-label {
  &.form-label-gray {
    color: #959aa1;
  }
}

.form-control {
  &.form-control-round {
    border-radius: 44px !important;
  }

  &.form-control-view-only {
    background-color: #fff !important;
  }

  &.form-control-disabled {
    background-color: rgba(237, 239, 243, 0.5) !important;
    color: #6d6d6f;
  }
}

.divider-gray {
  width: 100%;
  height: 1px;
  background: #e2e2e2;
}

.nav-tabs-line {
  border-bottom: 1px solid #edeff3;

  .nav-item {
    padding: 0.5rem 0;
    color: #959aa1;
    margin-right: 40px;
    font-weight: 700;

    &:hover {
      border: none;
    }

    &.active {
      border: none;
      border-bottom: 4px solid #f36328;
      color: #f36328;
    }
  }
}

.dropdown-toggle.toggle-action {
  border-color: #959aa1 !important;
  padding: 10px 20px;
  color: #6d6d6f !important;
  border-radius: 30px;
  background: transparent !important;

  &:hover {
    background: transparent !important;
  }

  &:focus {
    background: transparent !important;
    border: 1px solid#959aa1 !important;
    color: #6d6d6f !important;
  }

  &::after {
    display: none !important;
  }

  .dropdown-item {
    color: #6d6d6f !important;
  }
}
