.task-item-wrapper {
  display: flex;

  &:first-child {
    margin-top: 0 !important;
  }

  .task-name {
    color: rgba(40, 40, 40);

    &:hover {
      cursor: pointer;
    }
  }
}
