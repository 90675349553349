.startup-starter-layout-wrapper {
  .funding-wrapper {
    position: relative;
    height: 258px;
  }

  .funding-button {
    background: #46608a;
    border-radius: 6.25rem;
    color: #fff;
    font-size: 1.25rem;
    font-weight: 700;
    position: absolute;
    border: none;
    padding: 0.5rem !important;
    top: 6rem;
    width: 100%;
  }

  .unlock-button {
    background: #f36328;
    border-radius: 100px;
    color: #fff;
    font-weight: bold;
    font-size: 1.125rem;
    border: none;
    padding: 0.5rem 2rem;
  }

  .content {
    background: #f4f5f7;
    display: flex;
    position: relative;

    &.content__decor-bottom {
      background-color: #f4f5f7;
      padding-bottom: 10rem;
    }

    .decor-bottom {
      position: absolute;
      bottom: 0;
      right: 0;
    }

    .sidebar {
      flex: 0 0 20.1875rem;
      z-index: 1;
      left: 0;
      background: #fff;
      border: 1px solid #e2e2e2;
      box-sizing: border-box;
      padding: 1.5rem;
      padding-bottom: 0;
      width: 350px;
      position: fixed;
      overflow: scroll;
      height: calc(100vh - 60px);
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .background-item {
        position: absolute;
        left: -1.5rem;
        opacity: 0.2;
      }

      .menu-icon {
        margin-right: 1.5rem;
      }

      .side-navigation-panel {
        margin: 0 -1.5rem;
      }

      .side-navigation-panel-select-option-icon {
        float: right;
        position: absolute;
        right: 1.5rem;
        top: 1.6rem;
        color: #959aa2;
      }

      .side-navigation-panel-select-option-selected {
        .side-navigation-panel-select-option-icon {
          color: #46608a;
        }

        .side-navigation-panel-select-option-icon-chevron-down {
          top: 1.6rem;
        }

        .side-navigation-panel-select-option-icon-chevron-up {
          top: 1.4rem;
        }
      }

      .startup-logo {
        padding: 1rem 0;

        .logo-item {
          position: absolute;
          right: 0;
          top: 0;
        }

        img {
          object-fit: cover;
        }
      }

      .startup-name {
        color: #333;
        font-size: 18px;
        font-weight: 900;
        font-family: 'Kazimir Text', sans-serif;
      }

      .side-navigation-panel-select-option {
        padding: 0.8rem 0 !important;

        a {
          color: #6d6d6f;
          font-weight: bold;
          font-size: 0.875rem;
          line-height: 103.8%;
          padding: 0.5rem 1.5rem !important;
        }

        .active {
          padding-left: 1.187rem !important;
          color: #46608a !important;
          border-left: 0.313rem solid #46608a;
        }
      }

      .side-navigation-panel-select {
        list-style-type: none;
        padding-left: 0 !important;
        margin-bottom: 0 !important;

        .side-navigation-panel-select-wrap {
          border-top: 1px solid #edeff3;
          border-bottom: 1px solid #edeff3;
          color: #6d6d6f;
          font-weight: bold;
          font-size: 0.875rem;
          line-height: 103.8%;
          position: relative;
        }

        .side-navigation-panel-select-inner {
          padding-left: 0 !important;
          list-style-type: none;
        }

        .side-navigation-panel-select-inner-wrap {
          a {
            color: #959aa2;
            font-weight: 600;
            font-size: 0.875rem;
            line-height: 100%;
            padding: 0.8rem 0 0.8rem 4.313rem;
          }

          .active {
            padding-left: 4rem !important;
            color: #46608a !important;
            border-left: 0.313rem solid #46608a;
            font-weight: bold;
          }
        }
      }

      .extra-text {
        display: block;
        margin-left: 2.7rem;
        font-size: 0.75rem !important;
        color: #959aa2;
        font-weight: normal;
        font-style: normal;
        margin-top: 0.2rem;
      }

      .amount-item {
        position: absolute;
        right: 1.5rem;
        top: 1.5rem;
        color: #fff;
        padding: 0.25rem 0.375rem;
        border-radius: 6.25rem;
        background-color: #d0a696;
        text-align: center;
        font-weight: normal;
        font-size: 0.625rem;
        height: 1.125rem;
        min-width: 1.125rem;
        line-height: 100%;
      }

      .sidebar-icon {
        float: left;
        margin-top: 0;
      }
    }

    .main {
      flex: 1;
      padding: 40px 30px;
      width: calc(100% - 350px);
      margin-left: 350px;
      min-height: calc(100vh - 60px);
    }
  }
}
