.modal-pdf-viewer {
  .modal-lg {
    max-width: 1200px;
    width: 1200px;
  }

  .tem-modal {
    height: 2000px;
  }

  .content {
    &__market-analysis-circle-summary {
      &--first-item {
        width: 22rem;
        height: 22rem;
        box-shadow: 0 -4px 80px rgb(0 0 0 / 12%);
        border-radius: 50%;
        position: relative;
      }

      &--first-item-border {
        width: 22rem;
        height: 22rem;
        border-radius: 50%;
        position: relative;
        border: 1px solid #ddd;
      }

      &--second-item {
        width: 16rem;
        height: 16rem;
        box-shadow: 0 -4px 80px rgba(0, 0, 0, 0.25);
        border-radius: 50%;
        position: absolute;
        bottom: 0;
        left: 3rem;
      }

      &--second-item-border {
        width: 16rem;
        height: 16rem;
        border-radius: 50%;
        position: absolute;
        bottom: 0;
        left: 3rem;
        border: 1px solid #ddd;
      }

      &--third-item {
        width: 11rem;
        height: 11rem;
        box-shadow: 0 -4px 80px rgba(0, 0, 0, 0.25);
        border-radius: 50%;
        position: absolute;
        bottom: 0;
        left: 2.5rem;
        padding-top: 1rem;
      }

      &--third-item-border {
        width: 11rem;
        height: 11rem;
        border-radius: 50%;
        position: absolute;
        bottom: 0;
        left: 2.5rem;
        padding-top: 1rem;
        border: 1px solid #ddd;
      }

      &__title {
        font-size: 1.125rem;
        color: #fff;
        text-transform: uppercase;
        font-weight: bold;
      }

      &__description {
        font-size: 0.75rem;
        color: #fff;
        font-weight: normal;
      }
    }
  }
}
