.documents-wrapper {
  &__content {
    border-radius: 0.563rem;
    box-shadow: 4px 13px 50px rgba(0, 0, 0, 0.1);
    padding: 1.5rem !important;
  }

  &__title {
    font-size: 1.25rem;
    line-height: 1.44rem;
    color: #282828;
    margin-bottom: 0.5rem;
    font-weight: bold;
  }

  &__description {
    width: 70%;
    font-size: 0.875rem;
    line-height: 1.063rem;
    color: #282828;
    margin-bottom: 1.875rem;
    font-weight: normal;
    opacity: 0.8;
  }

  &__data {
    max-height: 33rem;
    overflow-y: scroll;
  }

  &__not-found {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    h2 {
      font-family: 'Kazimir Text', sans-serif;
      color: #000;
      font-size: 24px;
      line-height: 24px;
      font-weight: 900;
      margin-top: 20px;
      margin-bottom: 8px;
    }

    p {
      color: #535353;
      font-size: 14px;
      line-height: 14px;
    }
  }

  .hightlight-text {
    color: #46608a;
  }

  .menu-icon {
    position: absolute;
    right: 1rem;
    top: 1rem;

    .dropdown-menu {
      position: relative !important;
    }
  }

  .search-wrapper {
    background-color: white;
    border: 2px solid #e2e2e2;
    box-sizing: border-box;
    border-radius: 6.25rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 34rem;
    height: 2.813rem;
    margin-right: auto;

    .search-input {
      font-size: 1rem;
      border: none;
      background: none;
      margin-left: 0.5rem;
    }

    .find-btn {
      background-color: $color-red !important;
      border-radius: 1.125rem;
      border: none;
      font-size: 0.875rem;
      font-weight: bold;
      min-width: 6.75rem;
      height: 2.25rem;
      margin-right: 0.2rem;
      margin-bottom: 0.075rem;
    }

    .clear-btn {
      background-color: #edeff3 !important;
      border-radius: 100%;
      border: none;
      min-width: 2rem;
      height: 2rem;
      margin-right: 0.5rem;
      margin-bottom: 0.075rem;
      padding: 0;
    }
  }

  .startup-document {
    &__item {
      border-radius: 0.75rem;
      height: 14.438rem;
      margin-bottom: 2.5rem;
    }

    &__item-date {
      font-style: normal;
      font-weight: normal;
      font-size: 0.75rem;
      line-height: 0.94rem;
      color: rgba(40, 40, 40, 0.5);
    }

    &__item-title {
      font-style: normal;
      font-weight: 600;
      font-size: 1rem;
      line-height: 1.188rem;
      color: #282828;
    }

    &__item-des {
      font-style: normal;
      font-weight: normal;
      font-size: 0.75rem;
      line-height: 0.94rem;
      color: rgba(40, 40, 40, 0.5);
    }

    &__item-tag {
      padding: 0.1rem 0.3rem;
      border-radius: 0.25rem;
      font-size: 0.875rem;
    }

    .add-btn-dotted {
      width: 100%;
      padding: 0.5rem 3rem;
      border: dotted;
      color: #46608a;
      font-weight: bold;
      opacity: 0.5;
      background: none;
      border-radius: 0.5rem;
    }
  }

  .blue-bg {
    background: rgba(46, 161, 187, 0.2);
    color: #4470c2;
  }

  .green-bg {
    background: rgba(199, 104, 95, 0.2);
    color: #74ab4f;
  }

  .dropzone {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
