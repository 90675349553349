.admin-dashboard-authors {
  background-color: rgba(237, 239, 243, 0.4);
  padding: 3.3125rem;
  padding-bottom: 4.6875rem;

  .authors-section {
    .card {
      border: 1px solid rgba(196, 196, 196, 0.5);
      border-radius: 0.75rem;
      overflow: hidden;

      .card-body {
        padding: 0;
      }
    }
  }

  .read-full {
    color: $color-blue !important;
    font-size: 0.75rem;
    cursor: pointer;
    font-weight: 300;
  }
}
