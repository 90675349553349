.startup-starter-overview-wrapper {
  .business-model {
    &__item {
      min-height: 14.4rem;
      border-radius: 0.75rem;
    }

    &__icon {
      width: 3.6rem;
      height: 3.6rem;
      opacity: 0.7;

      .menu-icon {
        margin: auto;
      }
    }

    &__icon-canvas {
      background: #d0a696;
    }

    &__icon-elevator {
      background: #46608a;
    }

    &__icon-position {
      background: #f36328;
    }

    &__icon-revenue {
      background: #f09b00;
    }

    &__name {
      font-family: 'Proxima Nova', sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 1.25rem;
      line-height: 1.5rem;
      color: #282828;
      padding: 2rem 0.1rem;
      height: 8rem;
      width: 8.375rem;
    }

    &__begin-text {
      font-family: 'Proxima Nova', sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 0.875rem;
      line-height: 1;
      color: #f36328;
      display: flex;
      align-items: center;
    }

    &__date-text {
      font-family: 'Proxima Nova', sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 0.75rem;
      line-height: 0.875;
      color: rgba(40, 40, 40, 0.5);
      display: flex;
      align-items: center;
    }
  }

  .line {
    border: 1px solid #959aa2;
    opacity: 0.2;
    margin-bottom: 1rem;
  }

  .add-btn {
    padding: 0.5rem 1rem;
    background: #fff !important;
    border: none;
    color: #46608a !important;
    font-weight: bold;
    opacity: 0.5;
    border-radius: 6px;
    margin-top: 0.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 256px;
    height: 45px;

    &--orange {
      color: #d0a696 !important;
    }

    &--border {
      border: 2px solid rgba(70, 96, 138, 0.5) !important;
    }
  }

  .startup-note {
    border-radius: 0.75rem;
    height: 28.4rem;
    background: #e4e8ed;
    border: none;
    margin-bottom: 1.2rem;
    padding: 0.625rem;

    .menu-icon {
      margin-right: -1.25rem;

      button {
        border: none;
      }
    }

    .note-avatars {
      display: inline-flex;
      flex-direction: row-reverse;

      .note-avatar:not(:last-child) {
        margin-left: -0.75rem;
      }
    }

    &__empty {
      display: none !important;
    }

    &__title {
      font-family: 'Proxima Nova', sans-serif;
      font-style: normal;
      font-weight: bold;
      font-size: 1.125rem;
      line-height: 1.375rem;
      letter-spacing: 0.125rem;
      text-transform: uppercase;
      color: #46608a;
    }

    &__view-more {
      font-family: 'Proxima Nova', sans-serif;
      font-style: normal;
      font-weight: bold;
      font-size: 0.875rem;
      line-height: 1rem;
      text-align: right;
      color: #46608a;
      opacity: 0.8;
    }

    &__empty-text {
      font-family: 'Proxima Nova', sans-serif;
      font-style: normal;
      font-weight: bold;
      font-size: 1.125rem;
      line-height: 1.125rem;
      color: #959aa2;
    }

    &__empty-text-desc {
      font-family: 'Proxima Nova', sans-serif;
      font-style: normal;
      font-size: 0.875rem;
      line-height: 1rem;
      color: #959aa2;
      text-align: center;
    }

    &__item {
      border-radius: 0.75rem;
    }

    &__item-date {
      font-style: normal;
      font-weight: normal;
      font-size: 0.75rem;
      line-height: 0.94rem;
      color: rgba(40, 40, 40, 0.5);
    }

    &__item-title {
      font-style: normal;
      font-weight: bold;
      font-size: 1rem;
      line-height: 1.188rem;
      color: #282828;
    }

    &__item-des {
      font-style: normal;
      font-weight: normal;
      font-size: 0.75rem;
      line-height: 0.94rem;
      padding-top: 0.25rem;
      padding-bottom: 0.25rem;
      color: rgba(40, 40, 40, 0.5);
      max-height: 3.125rem;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
    }

    &__item-tag {
      padding: 0.5rem;
      border-radius: 0.25rem;
      height: 1.625rem;
      font-size: 0.857rem;
      display: flex;
      align-items: center;
    }

    &__none-empty {
      margin-top: 2rem;
    }

    .add-btn-dotted {
      width: 100%;
      padding: 0.5rem 3rem;
      border: dotted;
      color: #46608a;
      font-weight: bold;
      opacity: 0.5;
      background: none;
      border-radius: 0.5rem;
    }
  }

  .startup-document {
    border-radius: 0.75rem;
    height: 28.4rem;
    background: #ede5e4;
    border: none;
    padding: 0.625rem;

    &__title {
      font-family: 'Proxima Nova', sans-serif;
      font-style: normal;
      font-weight: bold;
      font-size: 1.125rem;
      line-height: 1.375rem;
      letter-spacing: 0.125rem;
      text-transform: uppercase;
      color: #46608a;
    }

    &__view-more {
      font-family: 'Proxima Nova', sans-serif;
      font-style: normal;
      font-weight: bold;
      font-size: 0.875rem;
      line-height: 1rem;
      text-align: right;
      color: #46608a;
      opacity: 0.8;
    }

    &__empty {
      height: calc(100% - 110px);
    }

    &__empty-text {
      font-family: 'Proxima Nova', sans-serif;
      font-style: normal;
      font-weight: bold;
      font-size: 1.125rem;
      line-height: 1.125rem;
      color: #959aa2;
    }

    &__empty-text-desc {
      font-family: 'Proxima Nova', sans-serif;
      font-style: normal;
      font-size: 0.875rem;
      line-height: 1rem;
      color: #959aa2;
      text-align: center;

      &--sm {
        max-width: 256px;
      }

      &--md {
        max-width: 400px;
      }
    }

    &__item {
      border-radius: 0.75rem;
    }

    &__item-date {
      font-style: normal;
      font-weight: normal;
      font-size: 0.75rem;
      line-height: 0.94rem;
      color: rgba(40, 40, 40, 0.5);
    }

    &__item-title {
      font-style: normal;
      font-weight: bold;
      font-size: 1rem;
      line-height: 1.188rem;
      color: #282828;
    }

    &__item-des {
      font-style: normal;
      font-weight: normal;
      font-size: 0.75rem;
      line-height: 0.94rem;
      color: rgba(40, 40, 40, 0.5);
    }

    &__item-tag {
      padding: 0.1rem 0.3rem;
      background: rgba(199, 104, 95, 0.2);
      border-radius: 0.25rem;
    }

    &__none-empty {
      margin-top: 2rem;
    }

    .add-btn-dotted {
      width: 100%;
      padding: 0.5rem 3rem;
      border: dotted;
      opacity: 0.8;
      background: none;
      color: #d0a696;
      border-radius: 0.5rem;
      font-weight: bold;
    }
  }

  .competitor {
    border-radius: 0.75rem;
    height: 24.25rem;

    &__title {
      margin-bottom: 1.875rem;
    }

    &__item {
      margin-bottom: 1.5rem;
    }

    &__name {
      font-size: 1.125rem;
      line-height: 1.375rem;
      color: #282828;
      opacity: 0.7;
    }

    &__value {
      font-weight: bold;
      font-size: 1.125rem;
      line-height: 1.375rem;
      text-align: right;
      letter-spacing: 0.125rem;
      text-transform: uppercase;
      color: #f36328;
    }

    .chartjs-render-monitor {
      height: 14.25rem !important;
      width: 14.25rem !important;
    }
  }

  .strategy {
    border-radius: 0.75rem;
    height: 41.5rem;

    &__title {
      margin-bottom: 1.875rem;
    }
  }

  .title {
    font-style: normal;
    font-weight: bold;
    font-size: 1.125rem;
    line-height: 1.375rem;
    letter-spacing: 0.125rem;
    text-transform: uppercase;
    color: #282828;
  }

  .chart-title {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    font-weight: 600;
    font-size: 0.875rem;
    line-height: 1.063rem;
    color: #959aa2;
  }

  .border-bottom {
    border-bottom: 1px solid #959aa2;
  }

  .action-button {
    border: 1px solid #f36328;
    background: none;
    padding: 0.3rem 4rem;
    border-radius: 1rem;
    color: #f36328;
    font-size: 1.125rem;
    font-weight: 600;
    align-items: center;
  }

  .beta-tag {
    width: 33px;
    height: 14px;
    margin-left: 8px;
    background: #f09b00;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 3px 5px;
    border-radius: 30px;
    font-size: 8px;
    border: none;
    font-weight: 800;
  }

  .add-button {
    border-radius: 50%;
    background-color: #f6f6f6;
    border: unset;
    width: 2.063rem;
    height: 2.063rem;
  }

  .market-share-input {
    border: 1px solid #e2e2e2;
    border-radius: 0.313rem;
    width: 12.5rem;
    height: 2.688rem;
    -webkit-appearance: none;
    color: #282828;
    font-size: 1rem;
    font-weight: 600;
    padding-left: 0.5rem;
  }

  .more-toggle {
    background: #fff;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.15);
    border-radius: 0.5rem;
    border: none;
    font-weight: bold;
    color: #282828;
    font-size: 1rem;
  }
}
