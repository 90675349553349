.dashboard-startup-starter {
  background-color: rgba(255, 255, 255, 0.5) !important;
  border-radius: 0.375rem !important;
  border: none !important;

  .card-body {
    position: relative;
    padding: 0;
    height: 11.25rem;

    .chevron-right {
      position: absolute;
      top: 1.25rem;
      right: 1.25rem;
    }

    .startup-content {
      position: absolute;
      top: 1.25rem;
      left: 1.25rem;
      width: 60%;

      .name {
        font-weight: 700;
        color: #333;
      }

      .date {
        font-weight: 400;
        font-size: 0.75rem;
        color: rgba(40, 40, 40, 0.5);
      }
    }

    .startup-starter-decor {
      position: absolute;
      bottom: 1rem;
      right: 0;
    }
  }

  .card-footer {
    display: flex;
    padding: 0;

    .section {
      padding: 0.4375rem 0.875rem;
      border-right: 0.5px solid #d0a696;
      text-align: center;

      .value {
        color: #d0a696;
        font-size: 0.875rem;
      }

      .title {
        color: rgba(40, 40, 40, 0.5);
        font-size: 0.75rem;
      }
    }

    .section:last-child {
      border-right: none;
    }
  }
}
