@mixin ellipsis-special($number : null) {
  overflow: hidden;

  @if type-of($number) != number {
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  @else {
    display: box;
    display: -moz-box;
    display: -webkit-box;
    display: -ms-box;
    box-orient: vertical;
    -moz-box-orient: vertical;
    -ms-line-clamp: $number;
    -moz-line-clamp: $number;
    -webkit-line-clamp: $number;
    word-wrap: break-word;
    white-space: normal;
    -webkit-box-orient: vertical;
  }
}
