.dashboard-founders-library-widget {
  background-color: #f6edea !important;
  border-radius: 0.75rem !important;
  border: none !important;
  min-height: 480px;
  height: 100%;
  position: relative;

  .card-title {
    margin-bottom: 0.25rem;

    a {
      font-family: 'Kazimir Text', sans-serif;
      font-weight: 900;
      font-size: 1.125rem;
      margin: 1.875rem;
      margin-bottom: 0;
      color: $color-black;
      display: inline-block;
    }
  }

  .add-btn {
    background-color: unset;
    font-size: 1rem;
    font-weight: bold;
    color: $color-blue;
    padding: 0.5rem 1.5rem;
    border: 1px solid $color-blue;
    border-radius: 2rem;
    position: absolute;
    top: -2rem;
    right: 2rem;
  }

  .card-body {
    padding: 0;
    position: relative;

    img.bg {
      position: absolute;
      bottom: 0;
      right: 0;
      width: 388px;
      height: 421;
      border-radius: 0.75rem;
    }

    .my-favorites-wrapper {
      position: absolute;
      top: 0;
      left: 1.875rem;
      width: 70%;
      display: flex;
      justify-content: center;
      flex-direction: column;

      .card-description {
        color: #6d6d6f;
        font-size: 0.875rem;
        margin-bottom: 1rem;
      }

      .create-startup-button {
        background-color: #e9e2e3;
        color: $color-blue;
        font-weight: bold;
        font-size: 1.125rem;
        border-radius: 0.75rem;
        padding: 2.25rem 6.25rem;
        border: none;
        margin: 3rem auto;
      }

      .more-text-startup {
        color: #46608a;
        font-size: 0.875rem;
        font-weight: bold;
      }
    }
  }
}
