.dashboard-page-wrapper {
  .library-investment {
    min-height: 480px !important;
  }

  .content {
    padding: 0 6.25rem 4rem 6.25rem;

    .verify-email-wrapper {
      display: flex;
      justify-content: space-between;
      background-color: rgba(243, 99, 40, 0.12);
      border-radius: 0.75rem;
      padding: 0.875rem 1.75rem;

      .verify-email-text {
        font-family: 'Kazimir Text', sans-serif;
        font-size: 0.875rem;
        font-weight: bold;

        span {
          color: $color-red;
        }
      }

      .resend-email-btn {
        width: 7.875rem;
        height: 1.875rem;
        color: $color-red;
        font-size: 0.875rem;
        font-weight: bold;
        background: none;
        border: 1px solid $color-red;
        border-radius: 1.25rem;
        line-height: 0.875rem;
      }
    }

    .banner {
      position: relative;
      margin-top: 1.875rem;
      height: 150px;
      border-radius: 12px;
      background-color: #46608a;

      &__guide {
        height: 224px;
        display: flex;
        justify-content: space-between;
        padding-right: 92px;

        .video {
          width: 283px;
          height: 160px;
          position: relative;
          margin-left: 32px;
          overflow: visible;
          background-color: $color-gray3;

          .close {
            width: 24px;
            height: 24px;
            border-radius: 100%;
            filter: drop-shadow(0 4px 4px rgba(0, 0, 0, 0.25));
            position: absolute;
            top: -12px;
            background-color: #fff;
            right: -12px;
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }
      }

      img.dashboard-img {
        height: 100%;
        margin-right: 0;
        float: right;
      }

      .banner-content {
        margin-left: 40px;
        margin-top: 40px;

        &__fix {
          position: absolute;
          left: 0;
          top: 0;

          &--investor {
            top: 16px;
          }
        }

        .banner-title {
          font-family: 'Kazimir Text', sans-serif;
          font-weight: 400;
          font-size: 2.5rem;
          line-height: 2.5rem;
          color: white;
          margin-bottom: 0;

          span {
            font-weight: 900;
          }
        }

        .banner-desc {
          font-family: 'Proxima Nova', sans-serif;
          font-weight: 400;
          font-size: 0.875rem;
          color: white;
        }
      }

      .banner-decor {
        position: absolute;
        left: -4.375rem;
        top: 0;
      }

      .banner-decor-right {
        position: absolute;
        right: -3.875rem;
        top: 0.625rem;
      }
    }
  }

  .link-btn {
    height: 26px;
  }
}

@media (min-width: 992px) {
  .dashboard-wrapper {
    .content {
      padding: 0 6.25rem 4rem 6.25rem;
    }
  }
}
