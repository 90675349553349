.revenue-estimate-wrapper {
  .header {
    &__title {
      color: #282828;
      font-size: 1.25rem;
      font-family: 'Kazimir Text', sans-serif;
      font-weight: bold;
    }

    &__description {
      color: #282828;
      opacity: 0.8;
      font-size: 0.875rem;
    }

    .export-button {
      color: #46608a;
      font-size: 1rem;
      font-weight: bold;
      padding: 0.5rem 1rem;
      background: rgba(70, 96, 138, 0.1);
      border: none;
      border-radius: 6.25rem;

      .image {
        margin-right: 0.5rem;
      }
    }
  }

  .content {
    background: #fff;
    box-shadow: 4px 13px 50px rgba(0, 0, 0, 0.1);
    border-radius: 0.5rem;
    padding: 30px;

    &__header {
      margin-bottom: 1.875rem;
    }

    &__title {
      color: #282828;
      opacity: 0.8;
      font-weight: 700;
      font-size: 1.125rem;
      text-transform: uppercase;
    }

    .revenue-form {
      padding-right: 2rem;

      .submit-button {
        background-color: $color-red;
        color: #fff;
        border-radius: 2rem;
        width: 8.5rem;
        height: 2.813rem;
        border: none;
        font-weight: bold;
      }

      &__item-name {
        font-family: 'Proxima Nova', sans-serif;
        font-style: normal;
        font-weight: bold;
        font-size: 0.875rem;
        line-height: 1.063rem;
        display: flex;
        align-items: center;
        letter-spacing: 0.125rem;
        text-transform: uppercase;
        color: #929292;
      }

      input {
        border-radius: 2.75rem;
        border: 2px solid #e2e2e2;

        &.range {
          width: 80%;
        }
      }

      .input-range {
        position: relative;
        padding-left: 1.5rem;
        width: 20%;

        input {
          border-radius: 0.563rem;
          height: 2.813rem;
          width: 100%;
          border: 1px solid #e2e2e2;
          color: #282828;
          font-size: 1rem;
          font-weight: 600;
        }

        span {
          position: absolute;
          right: 0.5rem;
          top: 0.625rem;
          color: #959aa1;
          font-size: 1rem;
        }
      }

      .select-box {
        position: relative;
        cursor: pointer;

        &__field {
          margin-bottom: 1.875rem;
        }

        .type-dropdown {
          appearance: none;
          -moz-appearance: none;
          -webkit-appearance: none;
          cursor: pointer;
          height: 2.813rem;
          border-radius: 2.75rem;
          border: 2px solid #e2e2e2;
        }

        img {
          position: absolute;
          right: 1rem;
          top: 1.25rem;

          &.updown {
            top: 0.875rem;
          }
        }
      }

      .add-revenue-type {
        color: #959aa1;
        font-size: 0.875rem;
        background-color: unset;
        border: unset;
        text-decoration: underline;
      }

      .add-button {
        border-radius: 50%;
        background-color: #f6f6f6;
        border: unset;
        width: 2.063rem;
        height: 2.063rem;
      }

      .market-share-input {
        border: 1px solid #e2e2e2;
        border-radius: 0.313rem;
        width: 4.125rem;
        height: 2.688rem;
        -webkit-appearance: none;
        color: #282828;
        font-size: 1rem;
        font-weight: 600;
        padding-left: 0.5rem;

        &.metric-input {
          width: 6.438rem;
        }
      }
    }

    .revenue {
      background-color: #f6f7f9;
      border-radius: 0.688rem;
      padding: 2.5rem;

      &__number {
        font-size: 2.188rem;
        font-weight: bold;
        color: #46608a;
        text-transform: uppercase;
        margin-bottom: 0.875rem;
        margin-top: 2.5rem;
      }

      &__number-text {
        color: #959aa2;
        font-size: 1.125rem;
        text-transform: uppercase;
      }

      &__detail {
        width: 100%;
        padding-bottom: 1.25rem;

        &__label {
          color: #929292;
          font-size: 0.75rem;
          text-transform: uppercase;
        }

        &__value {
          color: #46608a;
          font-size: 1rem;
          font-weight: bold;
        }
      }

      hr {
        margin-top: 2.5rem;
        margin-bottom: 2.5rem;
      }
    }
  }
}
