.canvas-card-wrapper {
  background-color: #fff;
  border-radius: 0.375rem;
  border: 1px solid #ebeef2;
  margin-bottom: 3rem;

  .card-canvas-header {
    display: flex;
    justify-content: space-between;

    &__title {
      font-weight: bold;
      color: #7e7e7e;
      text-transform: uppercase;
      font-size: 0.875rem;
    }

    &__more {
      font-size: 0.875rem;
    }

    &__dropdown-menu {
      padding: 1.25rem;
      background-color: #fff;
      box-shadow: 0 4px 30px rgba(0, 0, 0, 0.15);
      border-radius: 0.5rem;
      border: unset;
      color: #282828;
      font-size: 1rem;
      font-weight: bold;

      .dropdown-item {
        padding: 0;
        display: flex;
        justify-content: start;
        align-items: center;

        &:hover {
          text-decoration: none;
          background-color: unset;
        }
      }
    }
  }

  .card-item {
    min-height: 6.125rem;
    border-radius: 0.563rem;
    box-shadow: 4px 13px 50px rgba(0, 0, 0, 0.1);
    display: flex;

    .card-margin {
      width: 0.313rem;
      border-top-left-radius: 0.563rem;
      border-bottom-left-radius: 0.563rem;
    }

    .canvas-card-body {
      width: calc(100% - 0.313rem);
      padding: 0.75rem 1.25rem;
      position: relative;

      .accordion {
        button {
          border: none;
          padding: 0;
          color: #282828;
          opacity: 0.8;
          font-weight: 600;
          font-size: 1.125rem;
          position: relative;
          display: flex;
          width: 100%;
          justify-content: start;
          text-decoration: none !important;

          &:hover {
            text-decoration: none;
          }

          .title-arrow-down {
            position: absolute;
            right: 0;
            top: 0.625rem;
          }
        }
      }
    }

    .card-title {
      color: #282828;
      opacity: 0.8;
      font-size: 1.125rem;
      font-weight: 600;
    }

    .card-content {
      font-size: 0.875rem;
      color: #282828;
      opacity: 0.75;
    }

    .card-canvas-footer {
      display: flex;
      align-items: flex-end;
      justify-content: space-between;

      .group-left {
        display: flex;

        .edit-count {
          font-size: 0.875rem;
          color: #959aa2;
          font-weight: bold;
          padding: 0.25rem 0.5rem;
          background-color: #fff;
          opacity: 0.8;
          border-radius: 0.375rem;

          &:hover {
            cursor: pointer;
          }
        }

        .delete-button {
          padding: 0.25rem 1.25rem;
          color: #eb5757;
          background-color: #fcdad4;
          border-radius: 0.25rem;
          border: none;
        }
      }

      .color-picker {
        width: 0.563rem;
        height: 0.563rem;
        border-radius: 100%;
      }
    }
  }

  .color-picker-wrapper {
    .radio-color {
      width: 1rem;
      height: 1rem;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .add-new-card {
    margin-top: 1rem;
    color: #959aa1;
    background-color: #fff;
    font-size: 0.875rem;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px dashed #959aa2;
    border-radius: 0.5rem;
    width: 100%;
    height: 3.375rem;

    &:active {
      background-color: #fff !important;
    }

    &:hover {
      background-color: rgba(70, 96, 138, 0.12) !important;
    }
  }
}
